import React from "react";
import './Input.scss'
import FormControl from "@mui/material/FormControl";
import ReactInputMask from "react-input-mask";

interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string,
  dimension?: 'xs' | 'sm' | 'lg', 
  onChange?: (e: any) => void,
  onKeyDown?: (e: any) => void,
  onFocus?: () => void,
  onBlur?: (e: any) => void,
  autoFocus?: boolean,
  error?: boolean,
  helperText?: any,
  maxLength?: number,
  width?: string,
  min?: number,
}

const Input = ({
  id = "InputComponent",
  label,
  onChange,
  value,
  className,
  readOnly,
  disabled,
  onKeyDown,
  type,
  onFocus,
  onBlur,
  dimension,
  name,
  autoFocus = false,
  error,
  helperText,
  maxLength,
  min,
  placeholder,
  width,
  style
}: IInput) => {
  return (
    <FormControl className="container" sx={{ m: 1, width: width || "100%", justifyContent: 'flex-start' }}>
      {label && <label htmlFor={id} className={`label-input ${dimension}`}>{label}</label>}
      <input
        id={id}
        className={`InputComponent ${className} ${dimension} ${!!error ? "error" : ""}`}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
        disabled={disabled}
        onKeyDown={onKeyDown}
        type={type}
        onFocus={onFocus}
        onBlur={onBlur}
        name={name}
        autoFocus={autoFocus}
        maxLength={maxLength}
        placeholder={placeholder}
        style={style}
        min={min}
      />
      <span className="errorMessage">{helperText}</span>
    </FormControl>
  )
}

interface ITextArea extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label: string,
  onChange?: (e: any) => void,
  dimension?: 'sm' | 'lg',
  error?: boolean,
  helperText?: any,
  maxLength?: number,
}

export const TextArea = ({ label, onChange, value, className = '', readOnly, disabled, dimension, name, id, error, helperText, maxLength }: ITextArea) => {
  return (
    <FormControl className="container" sx={{ m: 1, width: "100%", justifyContent: 'flex-start' }}>
      {label && <label htmlFor={id} className={`textarea-label ${dimension}`}>{label}</label>}
      <textarea
        className={`TextAreaComponent ${className} ${dimension} ${!!error ? "error" : ""}`}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
        disabled={disabled}
        name={name}
        id={id}
        maxLength={maxLength}
      />
      <span className="errorMessage">{helperText}</span>
    </FormControl>
  )
}

interface IMaskedInput extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string,
  dimension?: 'sm' | 'lg',
  onChange?: (e: any) => void,
  onKeyDown?: (e: any) => void,
  onFocus?: () => void,
  onBlur?: () => void,
  autoFocus?: boolean,
  error?: boolean,
  helperText?: any,
  maxLength?: number,
  width?: string,
  mask: string,
  maskChar: string | null
}

export const MaskedInput = ({
  id = "InputComponent",
  label,
  onChange,
  value,
  className,
  readOnly,
  disabled,
  onKeyDown,
  type,
  onFocus,
  onBlur,
  dimension,
  name,
  autoFocus = false,
  error,
  helperText,
  maxLength,
  placeholder,
  width,
  mask,
  maskChar
}: IMaskedInput) => {
  return (
    <FormControl className="container" sx={{ m: 1, width: width || "100%", justifyContent: 'flex-start' }}>
      {label && <label htmlFor={id} className={`label-input ${dimension}`}>{label}</label>}
      <ReactInputMask
        id={id}
        className={`InputComponent ${className} ${dimension} ${!!error ? "error" : ""}`}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
        disabled={disabled}
        onKeyDown={onKeyDown}
        type={type}
        onFocus={onFocus}
        onBlur={onBlur}
        name={name}
        autoFocus={autoFocus}
        maxLength={maxLength}
        placeholder={placeholder}
        mask={mask}
        maskChar={maskChar}
      />
      <span className="errorMessage">{helperText}</span>
    </FormControl>
  )
}

export default Input