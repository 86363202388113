import { useEffect, useState } from "react";
import SectionCustom from "../../../components/section/section";
import Button from "../../../components/Button/styles";
import { obterListaDeParametrosMoto,obterListaDeParametrosEpi, inativarParametro } from "../services";
import { Box, TableRow, Table, AppBar, Tab, Tabs } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import * as Styles from "../../../components/table/styles";
import SwitchMUI from "../../../components/switch/switch";
import SideModalParametroItem from "./sideModalParametroItem";
import { StyledTableRow } from "./consultarParametroItem_Style";
import { EditIconStyled } from "../../../components/stickyTable/stickyTable_styles";
import Pagination from "../../../components/pagination/paginacao";
import _ from "lodash";
import FullscreenLoading from "../../../components/fullscreenLoading/fullscreenLoading";
import { ItemEdicaoInclusao } from "../types";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";

const ConsultarParametroItem = () => {
  const [tabValue, setTabValue] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dadosItemMoto, setDadosItemMoto] = useState<any[]>([]);
  const [dadosEpi, setDadosEpi] = useState<any[]>([]);
  const [modalMode, setModalMode] = useState<"create" | "edit">("create");
  const [selectedItem, setSelectedItem] = useState<
    ItemEdicaoInclusao | undefined
  >(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [currentPageEpi, setCurrentPageEpi] = useState(1);
  const [pageSizeEpi, setPageSizeEpi] = useState(10);

  const [totalCount, setTotalCount] = useState(0);
  const [totalCountEpi, setTotalCountEpi] = useState(0);
  const [loading, setLoading] = useState(false);
  const [textoBotao, setTextoBotao] = useState("item");
  const navigate = useNavigate();
  const epi = 1;
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    event.stopPropagation();
    newValue == epi ? setTextoBotao("EPI") : setTextoBotao("item");
    setTabValue(newValue);
  };

  useEffect(() => {
    tabValue == epi ? setTextoBotao("EPI") : setTextoBotao("item");
    getParametrosMoto();
    getParametrosEpi();
  }, [currentPage, pageSize,currentPageEpi,pageSizeEpi]);

  const getParametrosMoto = async () => {
    try {
      setLoading(true);
      var dados = await obterListaDeParametrosMoto(currentPage, pageSize);
      setDadosItemMoto(dados?.itensMoto);
      setTotalCount(dados.totalCount);
      setLoading(false);
    } catch (error) {
      setDadosItemMoto([]);
      setTotalCount(0);
      setLoading(false);
    }
  };
  const getParametrosEpi = async () => {
    try {
      setLoading(true);
      var dados = await obterListaDeParametrosEpi(currentPageEpi, pageSizeEpi);
      setDadosEpi(dados?.epi);
      setTotalCountEpi(dados.totalCount);
      setLoading(false);
    } catch (error) {
      console.log("error",error)
      setDadosEpi([]);
      setTotalCountEpi(0);
      setLoading(false);
    }
  };
  const handleOpenModal = (
    mode: "create" | "edit",
    item?: ItemEdicaoInclusao
  ) => {
    setModalMode(mode);
    setSelectedItem(item);
    setIsModalOpen(true);
  };
  const handleClickItemEdit = (row: any) => {
    handleOpenModal("edit", {
      id: row.parametroId,
      perguntaId: row.perguntaId,
      itemName: row.pergunta,
      severityLevel: row.nivelGravidadeId,
      resolutionDays: row.diasResolucao,
    });
  };

  const handleClickHistorico = (id: number) => {
    navigate("/ConsultarHistoricoParametroItem", {
      state: {
        parametroId: id,
        rota: {
          url: window.location.pathname,
          name: "Histórico de Parâmetro de Itens",
        },
      },
    });
  };

  const handleChangeSwitch = async (id: number) => {
    const response = (await inativarParametro(id)) as AxiosResponse;
    if (response.status === 200) {
      getParametrosMoto();
      getParametrosEpi();
    }
  };
  const atualizarTabela = () =>{
    getParametrosMoto();
    getParametrosEpi();
  }
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem(undefined);
  };
  return (
    <SectionCustom
      id={"ConsultaParemetroItens"}
      className="ParemetroItensChecklist"
      titleHeader="Parâmetros de itens e EPIs"
      titleBreadcrumbItem=""
    >
      <div className="consultarParametrosItems-Container">
        {loading && <FullscreenLoading />}
        <SideModalParametroItem
          open={isModalOpen}
          onClose={handleCloseModal}
          atualizarTabela={atualizarTabela}
          item={selectedItem}
          mode={modalMode}
          textoAba={textoBotao}
          grupoParametroItem={tabValue}
        />
        <Box
          sx={{
            position: "absolute",
            right: 50,
            marginTop: 2,
            marginBottom: 1,
            zIndex: 1,
          }}
        >
          <Button
            dimension="sm"
            variant="primary"
            onClick={() => handleOpenModal("create")}
          >
            Novo {textoBotao}
          </Button>
        </Box>
        <Box sx={{ padding: 2, marginTop: 8 }}>
          <Box sx={{ position: "relative", mb: 2 }}>
            <AppBar position="static" color="default" elevation={0}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#4CAF50",
                  },
                }}
              >
                <Tab label="Itens da moto" />
                <Tab label="EPI" />
              </Tabs>
            </AppBar>
          </Box>
          {tabValue === 0 ? (
            <>
              <TableContainer component={Styles.StyledPaper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      {[
                        "ID",
                        "Item da moto/EPI",
                        "Nível de gravidade",
                        "Dias para resolução",
                        "Status",
                        "Última edição",
                        "Editor",
                        "Editar",
                      ]?.map((title) => (
                        <Styles.StyledTableCell
                          className={`${title} center`}
                          key={title}
                        >
                          {title}
                        </Styles.StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dadosItemMoto.map((row, index) => (
                      <StyledTableRow
                        key={`${row.parametroId}`}
                        className={row.ativo ? "" : "inactive"}
                        onClick={(e) => {
                          handleClickHistorico(row.parametroId);
                        }}
                      >
                        <Styles.StyledTableCell
                          className="center"
                          style={{ textAlign: "center", width: "10%" }}
                        >
                          {row.parametroId}
                        </Styles.StyledTableCell>
                        <Styles.StyledTableCell component="th" scope="row">
                          {row.pergunta}
                        </Styles.StyledTableCell>
                        <Styles.StyledTableCell component="th" scope="row">
                          {row.nivelGravidade}
                        </Styles.StyledTableCell>
                        <Styles.StyledTableCell component="th" scope="row">
                          {row.diasResolucao}
                        </Styles.StyledTableCell>
                        <Styles.StyledTableCell
                          className="center"
                          onClick={(e) => e.stopPropagation()}
                          style={{ textAlign: "center", width: "10%" }}
                        >
                          <SwitchMUI
                            disabled={false}
                            checked={row.ativo}
                            setChecked={(
                              e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                              handleChangeSwitch(row.parametroId);
                            }}
                            type={"IOS"}
                          />
                        </Styles.StyledTableCell>
                        <Styles.StyledTableCell component="th" scope="row">
                          {row.ultimEdicao}
                        </Styles.StyledTableCell>
                        <Styles.StyledTableCell component="th" scope="row">
                          {row.nomeUsuarioEdicao}
                        </Styles.StyledTableCell>
                        <Styles.StyledTableCell
                          className="center"
                          style={{ textAlign: "center", width: "10%" }}
                        >
                          <EditIconStyled
                            style={
                              row.ativo
                                ? {}
                                : { cursor: "not-allowed", opacity: 0.5 }
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              if (row.ativo) {
                                handleClickItemEdit(row);
                              }
                            }}
                          />
                        </Styles.StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={pageSize}
                showLabel={false}
                showQtdPageSelectBox={false}
                setPageSize={(value: string) => setPageSize(Number(value))}
                onPageChange={(page: number) => setCurrentPage(Number(page))}
              />
            </>
          ) : (
            <>
            <TableContainer component={Styles.StyledPaper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {[
                      "ID",
                      "EPI",
                      "Nível de gravidade",
                      "Dias para resolução",
                      "Status",
                      "Última edição",
                      "Editor",
                      "Editar",
                    ]?.map((title) => (
                      <Styles.StyledTableCell
                        className={`${title} center`}
                        key={title}
                      >
                        {title}
                      </Styles.StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dadosEpi.map((row, index) => (
                    <StyledTableRow
                      key={`${row.parametroId}`}
                      className={row.ativo ? "" : "inactive"}
                      onClick={(e) => {
                        handleClickHistorico(row.parametroId);
                      }}
                    >
                      <Styles.StyledTableCell
                        className="center"
                        style={{ textAlign: "center", width: "10%" }}
                      >
                        {row.parametroId}
                      </Styles.StyledTableCell>
                      <Styles.StyledTableCell component="th" scope="row">
                        {row.pergunta}
                      </Styles.StyledTableCell>
                      <Styles.StyledTableCell component="th" scope="row">
                        {row.nivelGravidade}
                      </Styles.StyledTableCell>
                      <Styles.StyledTableCell component="th" scope="row">
                        {row.diasResolucao}
                      </Styles.StyledTableCell>
                      <Styles.StyledTableCell
                        className="center"
                        onClick={(e) => e.stopPropagation()}
                        style={{ textAlign: "center", width: "10%" }}
                      >
                        <SwitchMUI
                          disabled={false}
                          checked={row.ativo}
                          setChecked={(
                            e: React.MouseEvent<HTMLButtonElement>
                          ) => {
                            handleChangeSwitch(row.parametroId);
                          }}
                          type={"IOS"}
                        />
                      </Styles.StyledTableCell>
                      <Styles.StyledTableCell component="th" scope="row">
                        {row.ultimEdicao}
                      </Styles.StyledTableCell>
                      <Styles.StyledTableCell component="th" scope="row">
                        {row.nomeUsuarioEdicao}
                      </Styles.StyledTableCell>
                      <Styles.StyledTableCell
                        className="center"
                        style={{ textAlign: "center", width: "10%" }}
                      >
                        <EditIconStyled
                          style={
                            row.ativo
                              ? {}
                              : { cursor: "not-allowed", opacity: 0.5 }
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            if (row.ativo) {
                              handleClickItemEdit(row);
                            }
                          }}
                        />
                      </Styles.StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
                className="pagination-bar"
                currentPage={currentPageEpi}
                totalCount={totalCountEpi}
                pageSize={pageSizeEpi}
                showLabel={false}
                showQtdPageSelectBox={false}
                setPageSize={(value: string) => setPageSizeEpi(Number(value))}
                onPageChange={(page: number) => setCurrentPageEpi(Number(page))}
              />
            </>
          )}
        </Box>
      </div>
    </SectionCustom>
  );
};

export default ConsultarParametroItem;
