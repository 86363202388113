import { useContext, ReactNode, useMemo } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Login from "./pages/login/view/login";
import Verify2FA from "./pages/login/view/verify2FA";
import Home from "./pages/home/view/home";
import Dashboard from "./pages/dashboard/view/dashboard";
import FarolOS from "./pages/farol/view/farol";
import EncaminharOS from "./pages/encaminharOS/view/encaminharOS";
import OsPendente from './pages/osPendente/view/osPendente'
import EnviarTecnico from "./pages/enviarTecnico/view/EnviarTecnico";
import EstoqueTecnico from "./pages/estoqueTecnico/view/estoqueTecnico";
import ResumoEquipamentos from "./pages/resumoEquipamentos/view/resumoEquipamentos";
import TipoEquipamento from "./pages/tipoEquipamento/view/tipoEquipamento";
import ConsultarPerfil from "./pages/perfil/view/consultarPerfil";
import IncluirPerfil from "./pages/perfil/view/incluirEditarPerfil";
import IncluirEditarTipoEquipamento from "./pages/tipoEquipamento/view/incluirEditarTipoEquipamento";
import { AuthContext, AuthProvider } from "./contexts/auth";
import ConsultaFamilia from "./pages/familia/view/consultaFamilia";
import IncluirOuAlterarFamilia from "./pages/familia/view/incluirOuAlterarFamilia";
import ConsultaUsuario from "./pages/usuario/view/consultaUsuario";
import IncluirOuAlterarUsuario from "./pages/usuario/view/incluirOuAlterarUsuario";
import ConsultaModelo from "./pages/modelo/view/consultaModelo";
import IncluirEditarModelo from "./pages/modelo/view/incluirEditarModelo";
import ConsultaPrestador from "./pages/prestador/view/consultaPrestador";
import IncluirEditarPrestador from "./pages/prestador/view/incluirEditarPrestador";
import ResumoApp from "./pages/resumoApp/view/resumoApp";
import ConsultaTecnico from "./pages/tecnico/view/consultaTecnico";
import IncluirEditarTecnico from "./pages/tecnico/view/incluirEditarTecnico";
import PrestadorResponsavel from "./pages/prestadorResponsavel/view/prestadorResponsavel";
import IncluirEditarPrestadorResponsavel from "./pages/prestadorResponsavel/view/incluirEditarPrestadorResponsavel";
import ConsultaGrupoServico from "./pages/grupoServico/view/consultaGrupoServico";
import ConsultaOS from "./pages/consultaOS/view/consultarOS";
import DetalhesOS from "./pages/detalhesOS/view/detalhesOS";
import AcessoNegado from "./pages/acessoNegado/view/acessoNegado";
import IncluirEditarGrupoServico from "./pages/grupoServico/view/incluirEditarGrupoServico";
import ParametroInsucesso from "./pages/parametroInsucesso/view/parametroInsucesso";
import ConsultaServico from "./pages/servicos/view/consultaServico";
import IncluirEditarServico from "./pages/servicos/view/incluirEditarServico";
import ChangePassword from "./pages/changePassword/view/changePassword";
import ConsultaEstados from './pages/estados/view/consultaEstados'
import ConsultaCidades from "./pages/cidades/view/consultaCidades";
import IncluirEditarMetasProdutividade from "./pages/metasProdutividade/view/incluirEditarMetasProdutividade";
import { ConsultServiceOrderStatusPage } from "./pages/controleStatus/view/check-status";
import IncluirEditarStatusOrdemServico from "./pages/controleStatus/view/incluirEditarStatusOrdemServico";
import ConsultaRegiao from "./pages/regioes/view/consultaRegiao";
import IncluirEditarRegiao from "./pages/regioes/view/incluirEditarRegiao";
import IncluirEditarCidade from "./pages/cidades/view/incluirEditarCidade";
import IncluirEditarEstado from "./pages/estados/view/incluirEditarEstados";
import RedefinirSenha from "./pages/redefinirSenha/view/redefinirSenha";
import ResetSenha from "./pages/resetSenha/view/resetSenha";
import ValidarEmail from "./pages/validarEmail/view/validarEmail";
import ValidarResetSenha from "./pages/validarResetSenha/view/validarResetSenha";
import IncluirEditarParametro from "./pages/parametro/view/incluirEditarParametro";
import ConsultaParametro from "./pages/parametro/view/consultaParametro";
import ConsultarChecklist from "./pages/checklist/view/consultarChecklist";
import ConsultarTermoOs from "./pages/termoDeOs/view/consultarTermoOs";
import IncluirEditarTermoOs from "./pages/termoDeOs/view/incluirEditarTermoOs";
import ConsultaAgrupamentoModelo from "./pages/agrupamentoModelo/view/consultaAgrupamentoModelo";
import IncluirEditarAgrupamento from "./pages/agrupamentoModelo/view/incluirEditarAgrupamento";
import ConsultarParametroItem from "./pages/parametroItemChecklist/view/consultarParametroItem";
import ConsultarHistoricoParametroItem from "./pages/parametroItemChecklist/view/consultarHistoricoParametroItem";
import ParametrosRoterizador from "./pages/roteirizador/view/parametrosRoteirizador";
import RotasOtimizadas from "./pages/roteitizadorRotasOtimizadas/view/rotasOtimizadas";
// import { ConsultAllowanceJustificationPage } from "./pages/justificativaAbono/view/consult-allowance";
// import { RegisterAllowanceJustificationPage } from "./pages/justificativaAbono/view/register-allowance";
import ConfirmarDevolucao from "./pages/enviarTecnico/view/confirmarDevolucao";
import ConsultaAjusteMassivoOS from "./pages/ajusteMassivoOS/view/consultaAjusteMassivoOS";
import AjusteMassivoOS from "./pages/ajusteMassivoOS/view/ajusteMassivoOS";
import ConsultarPrestadorVeiculo from "./pages/prestadorVeiculo/view/consultarPrestadorVeiculo";
import CriarPrestadorVeiculo from "./pages/prestadorVeiculo/view/criarPrestadorVeiculo";
import EditarPrestadorVeiculo from "./pages/prestadorVeiculo/view/editarPrestadorVeiculo";

interface IChildren {
  children?: ReactNode
}

const AppRoutes = () => {
  const Private: any = ({ children }: IChildren) => {
    const LocalAuthContext = useContext(AuthContext);

    if (!LocalAuthContext) {
      return null
    }

    const { user, authenticated, loading, getTelaTemAcesso, getPaginaInicial } = LocalAuthContext;

    if (loading) {
      return <div className="loading">Carregando...</div>;
    }

    if (!authenticated) {
      return <Navigate to={"/Login"} />;
    }

    if (!!user?.primeiroAcesso && window.location.pathname !== "/ChangePassword") {
      return <Navigate to={"/ChangePassword"} />;
    }

    /* Pagina inicial */
    if (window.location.pathname === "/") {
      return <Navigate to={getPaginaInicial()} />;
    }

    if (!getTelaTemAcesso(window.location.pathname)) {
      return <Navigate to={"/AcessoNegado"} replace={true} />;
    }

    return children;
  };

  const AlreadyLogged: any = ({ children }: IChildren) => {
    const LocalAuthContext = useContext(AuthContext);

    if (!LocalAuthContext) {
      return null
    }

    const { authenticated, loading } = LocalAuthContext

    if (loading) {
      return <div className="loading">Carregando...</div>;
    }

    function isNotValidatingOrResetting(): boolean {
      return window.location.pathname !== "/ValidarEmail" && window.location.pathname !== "/ResetSenha";
    }

    if (!!authenticated && isNotValidatingOrResetting()) {
      return <Navigate to={"/"} />;
    }

    return children;
  };

  const pagesComponent: any = useMemo(() => ({
    Login: <Login />,
    Verify2FA: <Verify2FA />,
    ChangePassword: <ChangePassword />,
    Dashboard: <Dashboard />,
    FarolOS: <FarolOS />,
    EncaminharOS: <EncaminharOS />,
    OsPendente: <OsPendente />,
    EstoqueTecnico: <EstoqueTecnico />,
    EnviarEquipamento: <EnviarTecnico />,
    ResumoEquipamentos: <ResumoEquipamentos />,
    // CadastrarOM: <CadastrarOM />,
    // ConsultaOM: <ConsultaOM />,
    // EditarOM: <EditarOM />,
    ConsultaFamilia: <ConsultaFamilia />,
    IncluirFamilia: <IncluirOuAlterarFamilia />,
    ConsultaUsuario: <ConsultaUsuario />,
    IncluirUsuario: <IncluirOuAlterarUsuario />,
    TipoEquipamento: <TipoEquipamento />,
    ConsultarPerfil: <ConsultarPerfil />,
    IncluirPerfil: <IncluirPerfil />,
    EditarPerfil: <IncluirPerfil />,
    IncluirTipoEquipamento: <IncluirEditarTipoEquipamento />,
    EditarTipoEquipamento: <IncluirEditarTipoEquipamento />,
    ConsultaModelo: <ConsultaModelo />,
    IncluirModelo: <IncluirEditarModelo />,
    EditarModelo: <IncluirEditarModelo />,
    ConsultaPrestador: <ConsultaPrestador />,
    IncluiPrestador: <IncluirEditarPrestador />,
    EditaPrestador: <IncluirEditarPrestador />,
    ResumoApp: <ResumoApp />,
    ConsultaTecnico: <ConsultaTecnico />,
    IncluiTecnico: <IncluirEditarTecnico />,
    EditaTecnico: <IncluirEditarTecnico />,
    ConsultaGrupoServico: <ConsultaGrupoServico />,
    EditaGrupoServico: <IncluirEditarGrupoServico />,
    IncluiGrupoServico: <IncluirEditarGrupoServico />,
    NotFound: <AcessoNegado />,
    AcessoNegado: <AcessoNegado />,
    ParametroInsucesso: <ParametroInsucesso />,
    ConsultaOS: <ConsultaOS />,
    DetalhesOS: <DetalhesOS />,
    PrestadorResponsavel: <PrestadorResponsavel />,
    IncluiPrestadorResponsavel: <IncluirEditarPrestadorResponsavel />,
    EditarPrestadorResponsavel: <IncluirEditarPrestadorResponsavel />,
    // MetasProdutividade: <MetasProdutividade />,
    EditarMetasProdutividade: <IncluirEditarMetasProdutividade />,
    IncluirMetasProdutividade: <IncluirEditarMetasProdutividade />,
    Servicos: <ConsultaServico />,
    EditarServico: <IncluirEditarServico />,
    IncluirServico: <IncluirEditarServico />,
    ConsultarStatusOS: <ConsultServiceOrderStatusPage />,
    IncluirStatusOS: <IncluirEditarStatusOrdemServico />,
    EditarStatusOS: <IncluirEditarStatusOrdemServico />,
    ConsultarRegioes: <ConsultaRegiao />,
    IncluirRegiao: <IncluirEditarRegiao />,
    EditarRegiao: <IncluirEditarRegiao />,
    ConsultarCidades: <ConsultaCidades />,
    IncluirCidade: <IncluirEditarCidade />,
    EditarCidade: <IncluirEditarCidade />,
    ConsultarEstados: <ConsultaEstados />,
    IncluirEstado: <IncluirEditarEstado />,
    EditarEstado: <IncluirEditarEstado />,
    RedefinirSenha: <RedefinirSenha />,
    ResetSenha: <ResetSenha />,
    ValidarEmail: <ValidarEmail />,
    ValidarResetSenha: <ValidarResetSenha />,
    // ConsultarJustificativaAbono: <ConsultAllowanceJustificationPage />,
    // CadastrarJustificativaAbono: <RegisterAllowanceJustificationPage />,
    ConsultarParametros: <ConsultaParametro />,
    IncluirParametro: <IncluirEditarParametro />,
    EditarParametro: <IncluirEditarParametro />,
    ConsultarTermoOs: <ConsultarTermoOs />,
    IncluirTermoOs: <IncluirEditarTermoOs />,
    EditarTermoOs: <IncluirEditarTermoOs />,
    ConsultarAgrupamentoModelo: <ConsultaAgrupamentoModelo />,
    IncluirAgrupamentoModelos: <IncluirEditarAgrupamento />,
    EditarAgrupamentoModelos: <IncluirEditarAgrupamento />,
    ConsultarParametroItem: <ConsultarParametroItem/>,
    ConsultarHistoricoParametroItem: <ConsultarHistoricoParametroItem/>,
    ParametrosRoteirizador: <ParametrosRoterizador />,
    RotasOtimizadas: <RotasOtimizadas />,
    ConsultarChecklist: <ConsultarChecklist />,
    ConfirmarDevolucao: <ConfirmarDevolucao />,
    ConsultaAjusteMassivoOS: <ConsultaAjusteMassivoOS />,
    AjusteMassivoOS: <AjusteMassivoOS />,
    CriarPrestadorVeiculo: <CriarPrestadorVeiculo/>,
    ConsultarPrestadorVeiculo: <ConsultarPrestadorVeiculo/>,
    EditarPrestadorVeiculo: <EditarPrestadorVeiculo/>,
  }), []);

  const publicURLs = [
    { name: "Login", url: "/Login" },
    { name: "Verify2FA", url: "/Verify2FA" },
    { name: "RedefinirSenha", url: "/RedefinirSenha" },
    { name: "ResetSenha", url: "/ResetSenha" },
    { name: "ValidarResetSenha", url: "/ValidarResetSenha" },
    { name: "ValidarEmail", url: "/ValidarEmail" },
  ];

  const privateURLs = useMemo(() => ([
    { name: "ChangePassword", url: "/ChangePassword" },
    { name: "Dashboard", url: "/Dashboard" },
    { name: "FarolOS", url: "/FarolOS" },
    { name: "EncaminharOS", url: "/EncaminharOS" },
    { name: "OsPendente", url: "/OsPendente" },
    { name: "EstoqueTecnico", url: "/EstoqueTecnico" },
    { name: "EnviarEquipamento", url: "/EnviarEquipamento" },
    { name: "ResumoEquipamentos", url: "/ResumoEquipamentos" },
    { name: "CadastrarOM", url: "/CadastrarOM" },
    { name: "ConsultaOM", url: "/ConsultaOM" },
    { name: "EditarOM", url: "/EditarOM" },
    { name: "ConsultaFamilia", url: "/ConsultaFamilia" },
    { name: "IncluirFamilia", url: "/IncluirFamilia" },
    { name: "ConsultaUsuario", url: "/ConsultaUsuario" },
    { name: "IncluirUsuario", url: "/IncluirUsuario" },
    { name: "TipoEquipamento", url: "/Equipamento" },
    { name: "ConsultarPerfil", url: "/ConsultarPerfil" },
    { name: "IncluirPerfil", url: "/IncluirPerfil" },
    { name: "EditarPerfil", url: "/EditarPerfil" },
    { name: "IncluirTipoEquipamento", url: "/IncluirTipoEquipamento" },
    { name: "EditarTipoEquipamento", url: "/EditarTipoEquipamento" },
    { name: "ConsultaModelo", url: "/Modelo" },
    { name: "EditarModelo", url: "/EditarModelo" },
    { name: "IncluirModelo", url: "/IncluirModelo" },
    { name: "IncluiPrestador", url: "/IncluiPrestador" },
    { name: "EditaPrestador", url: "/EditaPrestador" },
    { name: "ConsultaPrestador", url: "/ConsultaPrestador" },
    { name: "ResumoApp", url: "/ResumoApp" },
    { name: "ConsultaTecnico", url: "/ConsultaTecnico" },
    { name: "EditaTecnico", url: "/EditarTecnico" },
    { name: "IncluiTecnico", url: "/IncluirTecnico" },
    { name: "ConsultaGrupoServico", url: "/ConsultaGrupoServico" },
    { name: "IncluiGrupoServico", url: "/IncluiGrupoServico" },
    { name: "EditaGrupoServico", url: "/EditaGrupoServico" },
    { name: "NotFound", url: "/NotFound" },
    { name: "AcessoNegado", url: "/AcessoNegado" },
    { name: "ParametroInsucesso", url: "/ParametroInsucesso" },
    { name: "ConsultaOS", url: "/ConsultaOS" },
    { name: "DetalhesOS", url: "/DetalhesOS" },
    { name: "PrestadorResponsavel", url: "/ConsultaPrestadorResponsavel" },
    { name: "IncluiPrestadorResponsavel", url: "/IncluiPrestadorResponsavel" },
    // { name: "MetasProdutividade", url: "/MetasProdutividade" },
    { name: "IncluirMetasProdutividade", url: "/IncluirMetasProdutividade" },
    { name: "EditarMetasProdutividade", url: "/EditarMetasProdutividade" },
    { name: "EditarPrestadorResponsavel", url: "/EditaPrestadorResponsavel" },
    { name: "Servicos", url: "/Servicos" },
    { name: "EditarServico", url: "/EditarServico" },
    { name: "IncluirServico", url: "/IncluirServico" },
    { name: "ConsultarStatusOS", url: "/ConsultarStatusOS" },
    { name: "IncluirStatusOS", url: "/IncluirStatusOS" },
    { name: "EditarStatusOS", url: "/EditarStatusOS" },
    { name: "ConsultarRegioes", url: "/ConsultarRegioes" },
    { name: "IncluirRegiao", url: "/IncluirRegiao" },
    { name: "EditarRegiao", url: "/EditarRegiao" },
    { name: "ConsultarCidades", url: "/ConsultarCidades" },
    { name: "IncluirCidade", url: "/IncluirCidade" },
    { name: "EditarCidade", url: "/EditarCidade" },
    { name: "ConsultarEstados", url: "/ConsultarEstados" },
    { name: "IncluirEstado", url: "/IncluirEstado" },
    { name: "EditarEstado", url: "/EditarEstado" },
    { name: "ConsultarJustificativaAbono", url: "/ConsultarJustificativaAbono" },
    { name: "CadastrarJustificativaAbono", url: "/CadastrarJustificativaAbono" },
    { name: "ConsultarParametros", url: "/ConsultarParametros" },
    { name: "IncluirParametro", url: "/IncluirParametro" },
    { name: "EditarParametro", url: "/EditarParametro" },
    { name: "ConsultarTermoOs", url: "/ConsultarTermoOs" },
    { name: "IncluirTermoOs", url: "IncluirTermoOs" },
    { name: "EditarTermoOs", url: "EditarTermoOs" },
    { name: "ConsultarAgrupamentoModelo", url: "/ConsultarAgrupamentoModelo" },
    { name: "IncluirAgrupamentoModelos", url:"/IncluirAgrupamentoModelos"},
    { name: "EditarAgrupamentoModelos", url:"/EditarAgrupamentoModelos"},
    {name:"ConsultarParametroItem",url:"/ConsultarParametroItem"},
    {name: "ConsultarHistoricoParametroItem",url:"/ConsultarHistoricoParametroItem"},
    { name: "ParametrosRoteirizador", url: "/ParametrosRoteirizador" },
    { name: "RotasOtimizadas", url: "/RotasOtimizadas" },
    { name: "IncluirAgrupamentoModelos", url: "/IncluirAgrupamentoModelos" },
    { name: "EditarAgrupamentoModelos", url: "/EditarAgrupamentoModelos" },
    { name: "ConsultarChecklist", url: "/ConsultarChecklist" },
    { name: "ConsultarParametroItem", url: "/ConsultarParametroItem" },
    { name: "ConsultarHistoricoParametroItem", url: "/ConsultarHistoricoParametroItem" },
    { name: "ConfirmarDevolucao", url: "/ConfirmarDevolucao" },
    { name: "ConsultaAjusteMassivoOS", url: "/ConsultaAjusteMassivoOS" },
    { name: "AjusteMassivoOS", url: "/AjusteMassivoOS" },
    { name: "ConsultarPrestadorVeiculo", url: "/ConsultarPrestadorVeiculo" },
    { name: "CriarPrestadorVeiculo", url: "/CriarPrestadorVeiculo" },
    { name: "EditarPrestadorVeiculo", url: "/EditarPrestadorVeiculo" },
  ]), []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {!!publicURLs?.length && publicURLs.map(({ name, url }) => {
            return (
              <Route
                key={url}
                path={url}
                element={
                  <AlreadyLogged>
                    {!!pagesComponent[name] ? pagesComponent[name] : <AcessoNegado />}
                  </AlreadyLogged>
                }
              />
            );
          })}

          <Route
            path={"/"}
            element={
              <Private>
                <Home />
              </Private>
            }
          >
            {!!privateURLs?.length && privateURLs.map(({ name, url }) => {
              return (
                <Route
                  key={url}
                  path={url}
                  element={
                    <Private>
                      {!!pagesComponent[name] ? pagesComponent[name] : <AcessoNegado />}
                    </Private>
                  }
                />
              );
            })}
          </Route>

          <Route path="*" element={<Navigate to={"/AcessoNegado"} replace={true} />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default AppRoutes;
