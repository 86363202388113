import * as React from "react";
import { 
  Modal, 
  Box, 
  Typography, 
  Button, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { RoteirizacaoTecnico, Sequencia } from "../types";


interface RoterizacaoModalProps {
    open: boolean;
    onClose: () => void;
    rotaOs?: RoteirizacaoTecnico;
  }

  interface SequenciaModalProps {
    open: boolean;
    onClose: () => void;
    sequencias: Sequencia[];
  }
  const SequenciaModal: React.FC<SequenciaModalProps> = ({ open, onClose, sequencias }) => {
    return (
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 1,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Detalhes da Sequência
          </Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>OS</TableCell>
                  <TableCell>Ordem</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sequencias.map((seq, index) => (
                  <TableRow key={index}>
                    <TableCell>{seq.os}</TableCell>
                    <TableCell>{seq.sequencia}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button onClick={onClose} variant="contained" sx={{ mt: 2 }}>
            Fechar
          </Button>
        </Box>
      </Modal>
    );
  };
  const RoterizacaoModal: React.FC<RoterizacaoModalProps> = ({ open, onClose, rotaOs }) => {
    const [sequenciaModalOpen, setSequenciaModalOpen] = React.useState(false);
  
    const handleOpenSequenciaModal = () => {
      setSequenciaModalOpen(true);
    };
  
    const handleCloseSequenciaModal = () => {
      setSequenciaModalOpen(false);
    };
  
    return (
      <>
        <Modal open={open} onClose={onClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 800,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 1,
            }}
          >
            <Typography variant="h6" component="h2" sx={{ mb: 3 }}>
              Dados da Roteirização
            </Typography>
            
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Prestador</TableCell>
                    <TableCell>Técnico</TableCell>
                    <TableCell>Quantidade OS</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Sequência</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{rotaOs?.prestador}</TableCell>
                    <TableCell>{rotaOs?.tecnico}</TableCell>
                    <TableCell>{rotaOs?.quantidadeOs}</TableCell>
                    <TableCell>{rotaOs?.status}</TableCell>
                    <TableCell>
                      <IconButton 
                        onClick={handleOpenSequenciaModal}
                        size="small"
                        color="primary"
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
  
            <Button onClick={onClose} variant="contained" sx={{ mt: 2 }}>
              Fechar
            </Button>
          </Box>
        </Modal>
  
        {rotaOs?.sequencias && (
          <SequenciaModal
            open={sequenciaModalOpen}
            onClose={handleCloseSequenciaModal}
            sequencias={rotaOs.sequencias}
          />
        )}
      </>
    );
  };
  
  export default RoterizacaoModal;