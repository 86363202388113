import React from 'react'
import { useFormik } from 'formik'
import CircularProgress from '@mui/material/CircularProgress'
import { AxiosResponse } from 'axios'
import * as Yup from 'yup';
import moment from 'moment';
import _ from 'lodash'

import AlertMessage from '../../../components/AlertMessage/alertMessage'
import AutocompleteMultiple from '../../../components/autocompleteMultiple/autocompleteMultiple'
import { IAutocompleteValueItem } from '../../../components/autocompleteMultiple/types'
import Header from "../../../components/header/header";
import { Section } from "../../resumoEquipamentos/view/styles";
import { Breadcrumb, BreadcrumbItem } from "../../../components/breadcrumb/breadcrumb";
import { HomeContext } from "../../../contexts/homeContext";

import { getPrestadores } from '../../enviarTecnico/services/services'
import { getListaTecnicosByLotePrestador } from '../../encaminharOS/services/services'
import { ITecnicos } from '../../../contexts/types/encaminharTypes'
import { IPrestador } from '../../../contexts/types/homeTypes'
import DatePicker from '../../../components/datePicker/datePicker'
import { exportarRotasOtimizadasCSV, getRotasOtimizadasFiltro, getRotasOtimizadasParametro, getRotasOtimizadasStatus, postRotasOtimizadasEnviarACampo, postRotasOtimizadasNovaRoteirizacao } from '../services/services'
import { formatDateTimeBr, stringToDateTimeBr } from '../../../helpers/util'

import "./rotasOtimizadas.scss";
import Button from '../../../components/Button/styles'
import { IGetRotasOtimizadas, IInitialValuesForm, IRouteasyFiltroRoteirizacaoDTO, IRouteasyRoteirizacaoDTO } from '../types/types';
import RotasOtimizadasTabela from './rotasOtimizadasTabela';
import Pagination from '../../../components/pagination/paginacao';
import { IPaginationList } from '../../../core/types';
import PrintIcon from '@mui/icons-material/Print';
import { IconButton } from '@mui/material';
import ButtonModalNovaRoteirizacao from './buttonModalNovaRoteirizacao';

function RotasOtimizadas() {

  const { setMenuLateral } = React.useContext(HomeContext);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [openAlertError, setOpenAlertError] = React.useState(false);
  const [msgAlertError, setMsgAlertError] = React.useState("Nenhum dado encontrado");
  const [alertType, setAlertType] = React.useState<"error" | "success" | "info" | "warning" | undefined>("error");

  const [prestadoresAPI, setPrestadoresAPI] = React.useState<IAutocompleteValueItem[]>([]);
  const [tecnicosAPI, setTecnicosAPI] = React.useState<IAutocompleteValueItem[]>([]);
  const [statusAPI, setStatusAPI] = React.useState<IAutocompleteValueItem[]>([]);

  const [roteirizacoesAPI, setRoteirizacoesAPI] = React.useState<IPaginationList<IRouteasyFiltroRoteirizacaoDTO[]>>();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [prestadorId, setPrestadorId] = React.useState(0);

  const [permiteRoteirizacaoAutomatica, setPermiteRoteirizacaoAutomatica] = React.useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    prestadoresFormik: Yup.array()
      .of(Yup.mixed())
      .min(1, 'Pelo menos um prestador deve ser selecionado'),
    tecnicosFormik: Yup.array().of(Yup.mixed()),
    statusFormik: Yup.array().of(Yup.mixed()),
    dataInicialFormik: Yup.date()
      .required('Data inicial não pode ser vazia')
      .nullable()
      .test('data-inicial-valida', 'Data inicial não pode ser maior que a data final', function (value) {
        const { dataFinalFormik } = this.parent;
        if (!value || !dataFinalFormik) return true;
        return moment(value).startOf('day').isSameOrBefore(moment(dataFinalFormik).startOf('day'));
      }),
    dataFinalFormik: Yup.date()
      .nullable()
      .test('data-final-valida', 'Data final não pode ser menor que a data inicial', function (value) {
        const { dataInicialFormik } = this.parent;
        if (!value || !dataInicialFormik) return true;
        return moment(value).startOf('day').isSameOrAfter(moment(dataInicialFormik).startOf('day'));
      })
  });

  const initialValues: IInitialValuesForm = {
    prestadoresFormik: [],
    tecnicosFormik: [],
    statusFormik: [],
    dataInicialFormik: formatDateTimeBr(moment()) ?? "",
    dataFinalFormik: ""
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      loadRoteirizacoes(values);
    },
  });

  const { setFieldValue, values } = formik;


  const loadPrestadores = async () => {
    const { data } = await getPrestadores() as AxiosResponse
    let list = [];
    if (!!data && !!data.length) {
      list = data.filter((p: any) => !!p.ativo)
        .map((m: IPrestador) => ({ name: m.nomePrestador, id: m.id.toString() } as IAutocompleteValueItem))
        .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }
    setPrestadoresAPI(list);
  }

  const loadTecnicosByPrestadores = async () => {
    const ids = values.prestadoresFormik.map((m: IAutocompleteValueItem) => Number(m.id));
    let { data } = await getListaTecnicosByLotePrestador(ids) as AxiosResponse;
    let list = [];
    if (!!data && !!data.length) {
      list = data
        .map((m: ITecnicos) => ({ name: m.nome, id: m.idTecnico.toString() } as IAutocompleteValueItem))
        .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }
    setTecnicosAPI(list);
  }

  const loadRotasOtimizadasStatus = async () => {
    const data = await getRotasOtimizadasStatus();
    let list: IAutocompleteValueItem[] = [];
    if (!!data && !!data.length) {
      list = data.sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }
    setStatusAPI(list);
  }

  const loadParametroPermiteRoteirizacaoAutomatica = async () => {
    try {
      const response = await getRotasOtimizadasParametro(prestadorId);
      setPermiteRoteirizacaoAutomatica(response);
    } catch {
    }
  }

  React.useEffect(() => {
    if (values.prestadoresFormik.length > 0) {
      loadTecnicosByPrestadores();
    }
  }, [values.prestadoresFormik]);

  React.useEffect(() => {
    loadParametroPermiteRoteirizacaoAutomatica();
    loadPrestadores();
    loadRotasOtimizadasStatus();
  }, [prestadorId]);

  const loadRoteirizacoes = async (values: IInitialValuesForm) => {
    setLoading(true);
    try {
      const params = {
        dataInicial: values.dataInicialFormik,
        dataFinal: values.dataFinalFormik,
        idPrestadores: values.prestadoresFormik.map(m => Number(m.id)),
        idTecnicos: values.tecnicosFormik.map(m => Number(m.id)),
        status: values.statusFormik.map(m => Number(m.id)),
        pageNumber: currentPage,
        pageSize: pageSize,
      } as IGetRotasOtimizadas;
      const response = await getRotasOtimizadasFiltro(params);
      setRoteirizacoesAPI(response);
    } catch (err) {
      setOpenAlertError(true);
      setAlertType("error");
      setMsgAlertError("Erro carregar roteirizações");
    }
    setLoading(false);
  }

  const handleExportarRoteirizacoes = async (values: IInitialValuesForm) => {
    setLoading(true);
    try {
      const params = {
        dataInicial: values.dataInicialFormik,
        dataFinal: values.dataFinalFormik != "" ? values.dataFinalFormik : undefined,
        idPrestadores: values.prestadoresFormik.map(m => Number(m.id)),
        idTecnicos: values.tecnicosFormik.map(m => Number(m.id)),
        status: values.statusFormik.map(m => Number(m.id)),
        pageNumber: currentPage,
        pageSize: pageSize,
      } as IGetRotasOtimizadas;

      const { data } = await exportarRotasOtimizadasCSV(params) as AxiosResponse;

      if (!data) throw "Erro";

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'RotasOtimizadas.csv');
      document.body.appendChild(link);
      link.click();
    } catch {
      setOpenAlertError(true);
      setAlertType("error");
      setMsgAlertError("Erro ao exportar");
    }
    setLoading(false);
  }

  const handleEnviarACampo = async (values: IInitialValuesForm) => {
    setLoading(true);
    try {
      const params = {
        dataInicial: values.dataInicialFormik,
        dataFinal: values.dataFinalFormik != "" ? values.dataFinalFormik : undefined,
        idPrestadores: values.prestadoresFormik.map(m => Number(m.id)),
        idTecnicos: values.tecnicosFormik.map(m => Number(m.id)),
        status: values.statusFormik.map(m => Number(m.id)),
        pageNumber: currentPage,
        pageSize: pageSize,
      } as IGetRotasOtimizadas;
      await postRotasOtimizadasEnviarACampo(params);
      setAlertType("success");
      setOpenAlertError(true);
      setMsgAlertError("Rotas encaminhadas com sucesso.");
    } catch(e:any) {
      setOpenAlertError(true);
      setAlertType("error");
      if(e?.response?.data){
        setMsgAlertError(e?.response?.data);
      }else{
      setMsgAlertError("Erro ao encaminhar rotas");
      }
    }
    setLoading(false);
  }

  const renderTable = () => {
    return !_.isEmpty(roteirizacoesAPI) && !_.isEmpty(roteirizacoesAPI.items) ? (
      <div style={{ marginTop: 16 }}>
        <RotasOtimizadasTabela
          rows={roteirizacoesAPI.items}
          setOpenAlertError={setOpenAlertError}
          setMsgAlertError={setMsgAlertError}
          setAlertType={setAlertType}
          reload={() => {
            loadRoteirizacoes(values);
          }}
          tecnicosAPI={tecnicosAPI}
        />
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={roteirizacoesAPI?.totalCount ?? 0}
          pageSize={pageSize}
          setPageSize={(value: string) => setPageSize(Number(value))}
          onPageChange={(page: number) => setCurrentPage(Number(page))}
          labelQtdeRight="Itens por página."
        />
      </div>
    ) : <></>
  }

  return (
    <Section id={"rotasOtimizadas"}>
      <Header setMenuLateral={() => setMenuLateral(true)} title="Rotas Otimizadas" />
      <Breadcrumb>
        <BreadcrumbItem>Rotas Otimizadas</BreadcrumbItem>
      </Breadcrumb>
      <div className='RotasOtimizadasContainer'>

        <div className='container-filtro'>
          <span className='FiltroSpan'>Filtros</span>
          <IconButton onClick={() => handleExportarRoteirizacoes(values)}>
            <PrintIcon fontSize='small' />
          </IconButton>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="grid1">
            <AutocompleteMultiple
              label={"Prestadores"}
              placeholder={"Prestadores"}
              noOptionsText={"Nenhum prestador encontrado"}
              id="prestadoresFormik"
              options={prestadoresAPI}
              value={values.prestadoresFormik}
              onChange={(e: any) => { 
                setPrestadorId(e[0].id);
                setFieldValue('prestadoresFormik', e);
               }}
              limitTags={1}
              fontSize={12}
              allSelected
              dimension='sm'
              multiple={false}
              disableCloseOnSelect={false}
              error={formik.touched.prestadoresFormik && Boolean(formik.errors.prestadoresFormik)}
              helperText={formik.touched.prestadoresFormik && formik.errors.prestadoresFormik}
            />

            <AutocompleteMultiple
              label={"Técnicos"}
              placeholder={"Técnicos"}
              noOptionsText={"Nenhum técnico encontrado"}
              id="tecnicosFormik"
              options={tecnicosAPI}
              value={values.tecnicosFormik}
              onChange={(e: any) => { setFieldValue('tecnicosFormik', e); }}
              limitTags={1}
              fontSize={12}
              allSelected
              dimension='sm'
            />

            <AutocompleteMultiple
              label={"Status"}
              placeholder={"Status"}
              noOptionsText={"Nenhum status encontrado"}
              id="statusFormik"
              options={statusAPI}
              value={values.statusFormik}
              onChange={(e: any) => { setFieldValue('statusFormik', e); }}
              limitTags={1}
              fontSize={12}
              allSelected
              dimension='sm'
            />
          </div>

          <div className="grid2">
            <DatePicker
              dimension="sm"
              id="dataInicialFormik"
              label='Data Inicial'
              value={stringToDateTimeBr(values.dataInicialFormik)}
              onChange={(e: any) => setFieldValue('dataInicialFormik', e.isValid() ? formatDateTimeBr(e) : "")}
              error={formik.touched.dataInicialFormik && Boolean(formik.errors.dataInicialFormik)}
              helperText={formik.touched.dataInicialFormik && formik.errors.dataInicialFormik}
            />
            <DatePicker
              dimension="sm"
              id="dataFinalFormik"
              label='Data Final'
              value={stringToDateTimeBr(values.dataFinalFormik)}
              onChange={(e: any) => setFieldValue('dataFinalFormik', e.isValid() ? formatDateTimeBr(e) : "")}
              error={formik.touched.dataFinalFormik && Boolean(formik.errors.dataFinalFormik)}
              helperText={formik.touched.dataFinalFormik && formik.errors.dataFinalFormik}
            />

            <Button
              variant="contained"
              dimension="sm"
              type='submit'
              className='bt'
            >
              Pesquisar
            </Button>

            <Button
              variant="contained"
              dimension="sm"
              className='bt'
              disabled={!permiteRoteirizacaoAutomatica}
              type='button'
              onClick={() => handleEnviarACampo(values)}
            >
              Enviar a Campo
            </Button>

            <ButtonModalNovaRoteirizacao
              values={values}
              currentPage={currentPage}
              pageSize={pageSize}
              setOpenAlertError={setOpenAlertError}
              setMsgAlertError={setMsgAlertError}
              setAlertType={setAlertType}
              setLoading={setLoading}
              reload={() => {
                loadRoteirizacoes(values);
              }}
            />

          </div>
        </form>

        <AlertMessage
          isOpenAlert={openAlertError}
          setOpenAlert={setOpenAlertError}
          alertType={alertType ?? "error"}
          msgAlert={msgAlertError}
        />

        {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}

        {renderTable()}
      </div>
    </Section>
  )
}

export default RotasOtimizadas