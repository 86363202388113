import { useLocation } from "react-router-dom";
import SectionCustom from "../../../components/section/section";
import { useEffect, useState } from "react";
import FullscreenLoading from "../../../components/fullscreenLoading/fullscreenLoading";
import { obterHistoricoParametro } from "../services";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import Pagination from "../../../components/pagination/paginacao";
import * as Styles from "../../../components/table/styles";
import { StyledTableRow } from "./consultarParametroItem_Style";

const ConsultarHistoricoParametroItem = () => {
  const location = useLocation();
  const parametroId: number = location.state?.parametroId || 0;
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [dadosHistorico, setDadosHistorico] = useState<any[]>([]);

  useEffect(() => {
    getParametros();
  }, [currentPage, pageSize]);

  const getParametros = async () => {
    setLoading(true);
    var dados = await obterHistoricoParametro(
      parametroId,
      currentPage,
      pageSize
    );

    setDadosHistorico(dados.data.items);
    setTotalCount(dados.data.totalCount);
    setLoading(false);
  };
  return (
    <SectionCustom
      id={"ConsultaHistoricoParemetroItens"}
      className="ParemetroItensHistoricoChecklist"
      titleHeader="Histórico de edição de itens e EPIs"
      titleBreadcrumbItem="Consulta de Histórico de edição de itens e EPIs"
      titleBreadcrumbItemBack="Parâmetros de itens e EPIs"
      rotaVoltar="/ConsultarParametroItem"
    >
      <div className="consultarParametrosItems-Container">
        {loading && <FullscreenLoading />}
        <Box
          sx={{
            position: "absolute",
            right: 50,
            marginTop: 2,
            marginBottom: 1,
            zIndex: 1,
          }}
        ></Box>
        <Box sx={{ padding: 2, marginTop: 8 }}>
          <>
            <TableContainer component={Styles.StyledPaper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {[
                      "Item da moto/EPI",
                      "Nível de gravidade",
                      "Dias para resolução",
                      "Status",
                      "Data de edição",
                      "Editor",
                    ]?.map((title) => (
                      <Styles.StyledTableCell
                        className={`${title} center`}
                        key={title}
                      >
                        {title}
                      </Styles.StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dadosHistorico.length ? (
                    dadosHistorico.map((row, index) => (
                      <StyledTableRow key={`${row.id}`}>
                        <Styles.StyledTableCell component="th" scope="row">
                          {row.item}
                        </Styles.StyledTableCell>
                        <Styles.StyledTableCell component="th" scope="row">
                          {row.nivelGravidade}
                        </Styles.StyledTableCell>
                        <Styles.StyledTableCell component="th" scope="row">
                          {row.diasResolucao}
                        </Styles.StyledTableCell>
                        <Styles.StyledTableCell component="th" scope="row">
                          {row.status}
                        </Styles.StyledTableCell>
                        <Styles.StyledTableCell component="th" scope="row">
                          {row.dataEdicao}
                        </Styles.StyledTableCell>
                        <Styles.StyledTableCell component="th" scope="row">
                          {row.editor}
                        </Styles.StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <>Sem Dados </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={pageSize}
              showLabel={false}
              showQtdPageSelectBox={false}
              setPageSize={(value: string) => setPageSize(Number(value))}
              onPageChange={(page: number) => setCurrentPage(Number(page))}
            />
          </>
        </Box>
      </div>
    </SectionCustom>
  );
};

export default ConsultarHistoricoParametroItem;
