import moment from "moment";

export const arrayToQueryString = (key: string, values: number[]): string => {
    const queryStringValues = values.map(value => encodeURIComponent(value.toString())).join(',');
    return `${key}=${queryStringValues}`;
}

export const arrayToQuery = (key: string, values: any[]): string => {
    return values.length > 0
        ? values.map((item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&')
        : '';
};

export interface IObjToQueryStringConfig {
    format?: string;
}

export const objToQueryString = (obj: Record<string, any>, config?: IObjToQueryStringConfig): string => {
    return Object.keys(obj)
        .map(key => {
            const value = obj[key];

            if (!value) return null;

            if (Array.isArray(value)) {
                const arrayQuery = arrayToQuery(key, value);
                return arrayQuery ? arrayQuery : null;
            } else if (value instanceof Date) {
                const format = config?.format ? config.format : "YYYY-MM-DDTHH:mm:ss";
                return `${encodeURIComponent(key)}=${encodeURIComponent(moment(value).format(format))}`;
            } else {
                return (value != null || value != '' || value != undefined)
                    ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
                    : null;
            }
        })
        .filter(part => part !== null)
        .join('&');
};

export function arrayRemoveProp(arr: any, prop: string) {
    return arr.map(({ [prop]: _, ...rest }) => rest);
}
export const stringToDateTimeBr = (
    value: string | moment.Moment | null,
    format?: string
): moment.Moment | null => {
    if (!value) return null;

    moment.locale('pt-br');

    if (moment.isMoment(value)) return value;

    const date = format ? moment(value, format, true) : moment(value);
    return date.isValid() ? date : null;
};

export const formatDateTimeBr = (
    value: string | moment.Moment | null,
    outputFormat = 'YYYY-MM-DDTHH:mm:ss'
): string | null => {
    const date = stringToDateTimeBr(value);
    return date ? date.format(outputFormat) : null;
};

export const timeToMoment = (time: string | null): moment.Moment | null => {
    if (!time) return null;

    const today = moment().format('YYYY-MM-DD');
    const dateTime = `${today}T${time}`;
    const date = moment(dateTime);

    return date.isValid() ? date : null;
};
