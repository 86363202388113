import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import './detalhesOS.scss';
import Button from '../../../components/Button/styles';
import Header from '../../../components/header/header';
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import { HomeContext } from '../../../contexts/homeContext';
import { Divider } from '@mui/material';
import Collapse from '../../../components/collapse/collapse';
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';
import { TextArea } from '../../../components/Input/Input';
import { ReactComponent as ExpandIcon } from "../../../assets/icons/ExpandIcon.svg";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/RefreshIcon.svg";
import { ReactComponent as ChecklistProdutoLupa } from "../../../assets/icons/ChecklistProdutoLupa.svg";
import { DetalhesOSMapa } from './detalhesOSMapa';
import Evidencias from './evidencias';
import StickyTable from '../../../components/stickyTable/stickyTable';
import { ReactComponent as Clipboard } from "../../../assets/icons/ClipboardIcon.svg";
import { ReactComponent as Printer } from "../../../assets/icons/PrinterIcon.svg";
import AnotacoesModal from './anotacoesModal';
import _ from 'lodash';
import moment from 'moment';
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import { Grupo_Servico, GrupoServico, IOrdemServico, IServico } from '../../../contexts/types/farolTypes';
import ModalEncaminharOS from '../../farol/view/farolModalEncaminharOS';
import { AuthContext } from "../../../contexts/auth";
import { useLocation } from "react-router-dom";
import { consultarOSs } from '../../consultaOS/services';
import { IEnderecoOS, IPostConsultarOSs, IPostConsultarOSsResponse, IPostConsultarOSsResponseOS, ITipoProdutoConsultaOS } from '../../consultaOS/types';
import { AxiosResponse } from 'axios';
import { getCidades } from '../../prestador/services/services';
import { atualizarDadosOrdemServico, consultarDadosChip } from '../services';
import { IGetResponseCidades } from '../../prestador/types';
import PrintIcon from '@mui/icons-material/Print';
import { pdf } from '@react-pdf/renderer';
import { ContainerButtons } from '../../consultaOS/view/consultarOS_styles';
import TemplateDefault from '../../../components/ImpressaoOS/templateDefault';
import TemplateGetNet from '../../../components/ImpressaoOS/templateGetNet';
import { removeSpecialCharSimple } from '../../../helpers/removeSpecialCharSimple';
import SwitchMUI from "../../../components/switch/switch";
import CircularProgress from "@mui/material/CircularProgress";
import { getGrouped_Services, updatevalidaPerimetro } from '../../farol/services';
import AlertMessage from '../../../components/AlertMessage/alertMessage';
import ModalChecklistProduto from './modalChecklistProduto';
import PageviewIcon from '@mui/icons-material/Pageview';
import { ResponseApi } from '../../../contexts/types/responsesHttp';
import { IGetResponseStatus } from '../../controleStatus/types';
import { getStatusOrdemServico } from '../../statusOrdemServico/services';
import ModalPendenciasOS from './modalPendenciasOS';
import { smoothScroll } from "../../../helpers/smoothScroll";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { OrdemServicoStatus } from '../../../helpers/ordemServico';
import { useFormik } from "formik";
import * as yup from "yup";
import Input, { MaskedInput } from '../../../components/Input/Input';
import { getListaCidadesComFiltro, getListaEstados } from '../../cidades/services';
import { Estado_Cidade } from '../../../contexts/types/cidadeTypes';
import { IAutocompleteValue } from '../../../components/autocompleteMultiple/types';
import { ReactComponent as Voltar } from "../../../assets/icons/Voltar.svg";
import YesOrNoModal from '../../../components/yesOrNoModal/yesOrNoModal';
import { Cliente, Endereco, IPutOrderService, OrdemServico } from '../types';
import { createErrorMsg } from '../../../services/api';

interface IInitialValues {
  grupoServico: any[],
  servico: any[],
  observacoes: string,

  nomeFantasia: string,
  razaoSocial: string,
  numeroDocumento: string,
  codigoCliente: string,
  contato: string,
  telefone: string,

  estado: any[],
  cidade: any[],
  bairro: string,
  tipo: string,
  logradouro: string,
  numero: string,
  complemento: string,
  pontoReferencia: string,
  horarioComercial: string,
  cep: string,
  pais: any[],
}

const DetalhesOS = () => {
  const location = useLocation();
  const chamado = location.state?.chamado ?? "";

  const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Detalhes OS", funcionalidade);

  const navigate = useNavigate();

  const { setMenuLateral } = useContext(HomeContext);

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const [selectedProduto, setSelectedProduto] = useState<any | null>(null);
  const [checklistProdutoModal, setChecklistProdutoModal] = useState(false);
  const [yesOrNoModalOpen, setYesOrNoModalOpen] = useState(false);

  const [listaGrupoServico, setListaGrupoServico] = useState<GrupoServico[]>([]);
  const [listaEstados, setListaEstados] = useState<IAutocompleteValue>([]);
  const [listaCidades, setListaCidades] = useState<IAutocompleteValue>([]);
  const [listaCidadeObject, setListaCidadeObject] = useState<any>({});
  const [observacoes, setObservacoes] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPerimetro, setLoadingPerimetro] = useState(false);
  const [openDadosCliente, setOpenDadosCliente] = useState(false);
  const [openDadosProduto, setOpenDadosProduto] = useState(false);
  const [openDadosChip, setOpenDadosChip] = useState(false);
  const [openDadosProdutoManutencao, setOpenDadosProdutoManutencao] = useState(false);
  const [openPeriodos, setOpenPeriodos] = useState(false);
  const [openDadosAtendimento, setOpenDadosAtendimento] = useState(false);
  const [openAtendimentosRealizados, setAtendimentosRealizados] = useState(false);
  const [openRastreamento, setOpenRastreamento] = useState(false);
  const [tableData, setTableData] = useState([{
    evento: '',
    descricao: '',
    status: '',
    falha: '',
    usuarioModificacao: '',
    dataCriacao: ''
  }]);

  const [ordemServico, setOrdemServico] = useState<IPostConsultarOSsResponseOS>({} as IPostConsultarOSsResponseOS);
  const [endereco, setEndereco] = useState<IEnderecoOS>({} as IEnderecoOS);
  const [status, setStatus] = useState<{ name: string, id: string }[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalPendenciasOpen, setIsModalPendenciasOpen] = useState(false);
  const [dadosPendencias, setDadosPendencias] = useState<any>({});
  const [camposComPendencias, setCamposComPendencias] = useState<string[]>([]);

  const [anotacoesModal, setAnotacoesModal] = useState(false);
  const [marcacoes, setMarcacoes] = useState([
    '10/10/2024 - 10:05:20 - Protocolo técnico: P13331410202 - Data: 10/10/2023 13:33:14 - Motivo: ENCE0015',
    '18/10/2023 - 12:25:20 - Alteração a pedido de Amabile 18/10/2023',
    '20/10/2023 - 13:50:20 - Teste nova anotação'
  ].sort((a, b) =>
    (moment(a.slice(0, 18), 'DD/MM/YYYY HHmm').toDate() > moment(b.slice(0, 18), 'DD/MM/YYYY HHmm').toDate()) ?
      1 :
      ((moment(b.slice(0, 18), 'DD/MM/YYYY HHmm').toDate()) > moment(a.slice(0, 18), 'DD/MM/YYYY HHmm').toDate()) ? -1 : 0));

  const [listaStatus, setListaStatus] = useState<IGetResponseStatus[]>([]);
  const [rotateRefreshIcon, setRotateRefreshIcon] = useState(false);

  const handleRotate = () => setRotateRefreshIcon(!rotateRefreshIcon);

  const rotate = rotateRefreshIcon ? "rotate(360deg)" : "rotate(0)";

  const objTraducaoHeader = {
    evento: 'Nome do evento',
    descricao: 'Descrição',
    status: 'Status',
    falha: 'Falha',
    usuarioModificacao: 'Modificado por',
    dataCriacao: 'Data de criação'
  } as any;

  const listaStatusPermiteEnviarACampo: string[] = [
    "encaminhada",
    "reencaminhado",
  ];

  const statusLista: { name: string, id: string }[] =
    (listaStatusPermiteEnviarACampo.includes(removeSpecialCharSimple(ordemServico?.status || ""))) ?
      [{ name: 'Enviar a Campo', id: 'enviarCampo' }] :
      removeSpecialCharSimple(ordemServico?.status || "") === 'emcampo' ?
        [{ name: 'Retirar de Campo', id: 'retirarCampo' }] :
        [];

  const getValueAutocomplete = (lista: any[], listaID: string, ID?: string) => {
    if (!ID) return [];

    const resultadoDados = [...lista];
    const value = resultadoDados.find((item) => item[listaID] === ID);

    return !!value ? [value] : [];
  };

  const initialValues = useMemo(() => ({
    grupoServico: getValueAutocomplete(listaGrupoServico?.map((item: GrupoServico) => ({ name: item.titulo, id: item.id.toString() })), 'id', String(ordemServico?.idGrupoServico)),
    servico: getValueAutocomplete(criaListaServicoAutocomplete(), 'id', String(ordemServico?.idServico)),
    observacoes: ordemServico?.observacao ?? "",

    nomeFantasia: ordemServico?.cliente?.nomeFantasia,
    razaoSocial: ordemServico?.cliente?.razaoSocial,
    numeroDocumento: ordemServico?.cliente?.numeroDocumento,
    codigoCliente: ordemServico?.cliente?.codigoCliente,
    contato: ordemServico?.cliente?.contato,
    telefone: ordemServico?.telefonesOS ? (ordemServico.telefonesOS.map((telefone) => (`${telefone.codigoArea}${telefone.numero}`)).toString()) : "",

    estado: (!!listaCidadeObject[endereco?.idCidade] ? [{ name: listaCidadeObject[endereco?.idCidade]?.estado?.nome ?? "", id: listaCidadeObject[endereco?.idCidade]?.estado?.id?.toString() ?? "" }] : []),
    cidade: (!!listaCidadeObject[endereco?.idCidade] ? [{ name: listaCidadeObject[endereco?.idCidade]?.nome ?? "", id: listaCidadeObject[endereco?.idCidade]?.id?.toString() ?? "" }] : []),
    bairro: endereco?.bairro ?? '',
    tipo: endereco?.tipo ?? '',
    logradouro: endereco?.logradouro ?? '',
    numero: endereco?.numero ?? '',
    complemento: endereco?.complemento ?? '',
    pontoReferencia: endereco?.pontoReferencia ?? '',
    horarioComercial: endereco?.horarioComercial ?? '',
    cep: endereco?.cep ?? '',
    pais: [{ name: 'Brasil', id: '1' }],
  }), [
    listaGrupoServico,
    ordemServico,
    endereco,
    listaCidadeObject
  ]);

  const validationSchema = yup.object({
    codigoCliente: yup.string().required("Campo obrigatório"),
    nomeFantasia: yup.string().required("Campo obrigatório"),
    razaoSocial: yup.string().required("Campo obrigatório"),

    grupoServico: yup.array().max(1).min(1, 'Campo obrigatório'),
    servico: yup.array().max(1).min(1, 'Campo obrigatório'),

    estado: yup.array().max(1).min(1, 'Campo obrigatório'),
    cidade: yup.array().max(1).min(1, 'Campo obrigatório'),

    bairro: yup.string().required("Campo obrigatório"),
    logradouro: yup.string().required("Campo obrigatório"),
    numero: yup.string().required("Campo obrigatório"),
    cep: yup.string().required("Campo obrigatório"),
    pais: yup.array().max(1).min(1, 'Campo obrigatório'),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (!!profileHasPermission("Editar Registro")) {
        handleUpdateDadosOS(values);
      }
    }
  })

  const createUrlToWhatsapp = (number: string) => {
    const newNumber = number.replace(/\D/g, "");
    const firtTwoDigitis = newNumber.slice(0, 2);

    if (firtTwoDigitis === '55') return `https://wa.me/${newNumber}`;

    return `https://wa.me/55${newNumber}`
  };

  const permissaoCampo = (func: string, label: string | number | null | undefined) => {
    return profileHasPermission(func) ? (label ?? "") : "Sem permissão para visualizar";
  };

  const permissaoCampoAutoComplete = (func: string, value: { name: string, id: string }[]) => {
    return profileHasPermission(func) ? value : [{ name: "Sem permissão para visualizar", id: "0" }];
  };

  const handleGetDadosOS = async () => {
    setLoading(true);
    setOpenAlert(false);

    const filtros: IPostConsultarOSs = {
      chamado: chamado ? String(chamado) : "",
      "pageNumber": 1,
      "pageSize": 10,
    };

    try {
      const { data }: { data: IPostConsultarOSsResponse } = await consultarOSs(filtros) as AxiosResponse;

      const orderService = data?.items[0] ?? {};
      const address = orderService.enderecos[0] ?? {};
      setOrdemServico(orderService);
      setEndereco(address);
      setLoading(false);

    } catch (error: any) {
      setLoading(false);
      setMsgAlert((!!error?.response?.data && typeof error.response.data === 'string') ? error.response.data : (!!error?.response?.status ? `Dados da OS não foram encontrados. Status: ${error?.response?.status}` : "Dados da OS não foram encontrados"));
      setTipoMsgAlert("error");
    }
  };

  const handleUpdateDadosOS = async (values: IInitialValues) => {
    setLoading(true);
    setOpenAlert(false);

    const orderService: OrdemServico = {
      ...(!!values.grupoServico[0] ? { idGrupoServico: Number(values.grupoServico[0]?.id) } : {}),
      ...(!!values.servico[0] ? { idServico: Number(values.servico[0]?.id) } : {}),
      observacao: values.observacoes,
    };
    const client: Cliente = {
      ...(!!values.nomeFantasia ? { nomeFantasia: values.nomeFantasia } : {}),
      ...(!!values.razaoSocial ? { razaoSocial: values.razaoSocial } : {}),
      numeroDocumento: values.numeroDocumento,
      contato: values.contato,
      ...(!!values.codigoCliente ? { codigoCliente: values.codigoCliente } : {}),
    };
    const address: Endereco = {
      ...(!!values.cidade[0] ? { idCidade: Number(values.cidade[0]?.id) } : {}),
      ...(!!values.bairro ? { bairro: values.bairro } : {}),
      ...(!!values.logradouro ? { logradouro: values.logradouro } : {}),
      ...(!!values.numero ? { numero: values.numero } : {}),
      complemento: values.complemento,
      pontoReferencia: values.pontoReferencia,
      ...(!!values.cep ? { cep: values.cep } : {}),
    };

    const params: IPutOrderService = {
      chamado,
      ...(!_.isEmpty(orderService) ? { ordemServico: { chamado, ...orderService } } : {}),
      ...(!_.isEmpty(client) ? { cliente: { idCliente: ordemServico.cliente?.id, ...client } } : {}),
      ...(!_.isEmpty(address) ? { enderecos: [{ idEndereco: endereco.id, ...address }] } : {}),
    };

    try {
      const { data }: { data: any } = await atualizarDadosOrdemServico(chamado, params) as AxiosResponse;

      setLoading(false);
      setOpenAlert(true);
      setMsgAlert("Sucesso ao atualizar dados da OS!");
      setTipoMsgAlert("success");

      setTimeout(() => {
        handleGetDadosOS();
      }, 3000);

    } catch (error: any) {
      setLoading(false);
      const msg = createErrorMsg(error);
      setOpenAlert(true);
      setMsgAlert(msg ? msg : "Erro ao atualizar dados da OS!");
      setTipoMsgAlert("error");
    }
  };

  const handlePrint = async () => {
    const isTemplateGetNet = ordemServico.contratante === "GETNET";
    const osData = {
      ...ordemServico,
      enderecos: !ordemServico.enderecos ? [] : ordemServico.enderecos.map(e => {
        return {
          ...e,
          cidade: (!!listaCidadeObject[e?.idCidade] ? (listaCidadeObject[e?.idCidade]?.nome ?? "") : ""),
          uf: (!!listaCidadeObject[e?.idCidade] ? (listaCidadeObject[e?.idCidade]?.estado?.uf ?? "") : ""),
          estado: (!!listaCidadeObject[e?.idCidade] ? (listaCidadeObject[e?.idCidade]?.estado?.nome ?? "") : "")
        }
      })
    };

    const template = isTemplateGetNet ? <TemplateGetNet ordemServicos={[osData]} /> : <TemplateDefault ordemServicos={[osData]} />;

    const blob = await pdf(template).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  };

  const handleGetCidadesDeCadaEndereco = async () => {
    if (!ordemServico?.enderecos || _.isEmpty(ordemServico?.enderecos)) {
      setListaCidadeObject({});
      return;
    }

    let listaTemp: any[] = [];

    for (let i = 0; i < ordemServico?.enderecos?.length; i++) {
      const idCidade = ordemServico?.enderecos[i]?.idCidade;
      if (!!idCidade) {
        try {
          const { data }: { data: IGetResponseCidades } = await getCidades({ Id: idCidade }) as AxiosResponse;
          const cidades = data?.cidades ?? [];
          listaTemp.push(...cidades);
        } catch (e) { };
      }
    };

    const cidadesObject = listaTemp.reduce((inicial, item) => {
      if (!inicial[item.id]) inicial[item.id] = { ...item };
      return inicial;
    }, {});

    setListaCidadeObject(cidadesObject);
  };

  const handleShowInfoEntrega = () => {
    //TODO: Implementação da US 6362 - Será tratado posteriormente
  }
  const handleShowInfoRetirada = () => { }
  const handleShowInfoManutencao = () => { }

  const handleShowPerimeter = () => {
    return removeSpecialCharSimple(ordemServico?.status || "") === "emcampo";
  };

  const handleSwitchValidaPerimetro = async (value: boolean) => {
    setLoadingPerimetro(true);
    try {
      const { data } = await updatevalidaPerimetro({
        idOS: ordemServico?.chamado,
        validaPerimetro: value
      }) as AxiosResponse;

      setLoadingPerimetro(false);
      handleSetValueOS(value);
      setOpenAlert(true);
      setTipoMsgAlert('success');

      if (!!data && typeof data === 'string') {
        setMsgAlert(data);
      } else {
        setMsgAlert('Sucesso ao atualizar validação de perímetro.');
      }

    } catch (e: any) {
      setLoadingPerimetro(false);
      setOpenAlert(true);
      setTipoMsgAlert('error');

      if (!!e?.response?.data && typeof e?.response?.data === 'string') {
        setMsgAlert(e?.response?.data);
      } else if (e?.response?.data?.errors) {
        setMsgAlert(Object.values(e.response.data.errors).join("<br>"));
      } else {
        setMsgAlert("Erro ao atualizar validação de perímetro" + (e?.response?.status ? ` status: ${e?.response?.status}` : ""));
      }
    }
  };

  const handleSetValueOS = (value: boolean) => {
    const updatedOS = {
      ...ordemServico,
      validaPerimetro: value
    };

    setOrdemServico(updatedOS);
  };

  const getListaStatus = async () => {
    return await getStatusOrdemServico().then((res: any) => {
      const respSuccess = res?.data as ResponseApi<IGetResponseStatus[]>;

      if (respSuccess?.data) {
        const listaStatus = respSuccess.data ?? [];
        setListaStatus(listaStatus);
      };
    }, err => {
      const respErr = err?.response?.data as ResponseApi<any>;
      if (respErr) {
        setListaStatus([]);
      }
    });
  };

  const handleSalvarStatus = () => {
    handleRotate();
    // try {
    /**TODO: Request para alterar status */
    // } catch (error: any) {
    let error = {
      response: {
        errors: [
          { 'nomeFantasia': 'Campo obrigatório' },
          { 'endereco': 'Campo obrigatório' },
          { 'bairro': 'Campo obrigatório' },
          { 'cep': 'Campo obrigatório' },
        ]

      }
    };
    setIsModalPendenciasOpen(true);
    setDadosPendencias(error.response.errors);
    // }
  };

  const destacarCampos = () => {
    camposComPendencias.map((cmpID: string) => {
      if (!!cmpID.length) {
        let componente: HTMLElement | null = document.getElementById(cmpID);
        componente?.classList.add("errorField");
        componente?.addEventListener('click', () => componente?.classList.remove("errorField"));
      }

      setOpenDadosCliente(true);
      smoothScroll(document.getElementById('setOpenDadosCliente'));
    })
  };

  const getServices = async () => {
    await getGrouped_Services().then((resp: any) => {
      const respSuccess = resp?.data as ResponseApi<Grupo_Servico[]>;

      const dataMaped = respSuccess.data.map((mp) => ({
        titulo: mp.nome,
        ativo: true,
        servicos: mp.servicos.map((ser) => ({
          ativo: true,
          descricao: '',
          fluxoTrabalho: '',
          id: ser.id.toString(),
          idGrupoServico: mp.id,
          titulo: ser.nome,
        })),
        codigoGrupoServico: '',
        codigoComportamento: null,
        id: mp.id
      })) as GrupoServico[];

      setListaGrupoServico(dataMaped);
    }, err => {
      const respErr = err?.response?.data as ResponseApi<any>;
    });
  };

  function criaListaServico() {
    let myServiceList: { name: string, id: string, grupo: string }[] = [];

    if (listaGrupoServico) {
      listaGrupoServico?.map(grupo => {
        if (formik.values.grupoServico.map((s: { name: string, id: string }) => s.id).includes(grupo.id.toString())) {
          grupo.servicos.map((item: IServico) => (
            myServiceList.push({
              name: item.titulo,
              id: item.id,
              grupo: grupo.id.toString(),
            })
          ))
        }
      })
    }

    return myServiceList;
  };

  function criaListaServicoAutocomplete() {
    let myServiceList: { name: string, id: string, grupo: string }[] = [];

    if (listaGrupoServico) {
      listaGrupoServico?.map(grupo => {
        if (grupo.id === ordemServico?.idGrupoServico) {
          grupo.servicos.map((item: IServico) => (
            myServiceList.push({
              name: item.titulo,
              id: item.id,
              grupo: grupo.id.toString(),
            })
          ))
        }
      })
    }

    return myServiceList;
  };

  const compare = (a: { name: string, id: string }, b: { name: string, id: string }) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const getEstados = async () => {
    try {
      const estados = await getListaEstados({}) as AxiosResponse;
      const listaUF = (estados?.data?.estados ?? []).map((item: Estado_Cidade) => ({ name: item.nome, id: item.id.toString() }));
      setListaEstados(listaUF);

    } catch (e) {
      setListaEstados([]);
    }
  };

  const handleGetCidades = async () => {
    if (_.isEmpty(formik.values.estado)) {
      setListaCidades([]);
      return;
    }

    try {
      const { data }: { data: IGetResponseCidades } = await getListaCidadesComFiltro({
        Ativo: true,
        IdEstado: Number(formik.values.estado[0].id),
      }) as AxiosResponse;
      const cidades = data?.cidades ?? [];

      setListaCidades(cidades.map(item => ({ name: item.nome, id: item.id.toString() })).sort(
        (a: { name: string, id: string }, b: { name: string, id: string }) =>
          (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
      ));

    } catch (e) {
      setListaCidades([]);
    }
  };

  const mascararNumeroSerie = (numeroSerie: string | null | undefined, statusOS: string): string => {
    if (!numeroSerie) {
      return ''
    } else if (profileHasPermission("Número Série")) {
      return numeroSerie;
    } else if (statusOS === OrdemServicoStatus.BAIXADA) {
      return numeroSerie;
    } else {
      const numeroSemEspeciais = numeroSerie?.replace(/[^a-zA-Z0-9]/g, '');
      const length = numeroSemEspeciais.length;


      if (length > 6) {
        const mascarado = '*'.repeat(length - 6) + numeroSemEspeciais.slice(-6);
        return mascarado;
      }

      return numeroSemEspeciais;
    }
  };

  const desabilitaEdicaoStatus = () => {
    return ordemServico.status === OrdemServicoStatus.BAIXADA || ordemServico.status === OrdemServicoStatus.CANCELADA;
  };

  const desabilitaEdicaoCampos = () => {
    return !profileHasPermission("Editar Registro") || desabilitaEdicaoStatus();
  };

  const habilitaBotaoSalvar = () => {
    let value = true;

    if (!profileHasPermission("Editar Registro")) value = false;
    if (!formik.dirty) value = false;
    if (desabilitaEdicaoStatus()) value = false;

    return value;
  };

  useEffect(() => {
    getServices();
    getEstados();
    handleGetDadosOS();
    getListaStatus();
  }, []);

  useEffect(() => {
    handleGetCidadesDeCadaEndereco();
  }, [ordemServico]);

  useEffect(() => {
    if (!!formik.values.estado.length) {
      handleGetCidades();
    }
  }, [formik.values.estado]);

  useEffect(() => {
    if (!!camposComPendencias.length) {
      destacarCampos();
    }
  }, [camposComPendencias]);

  return (
    <section className="DetalhesOSContainer">
      {loading && <FullscreenLoading />}

      <Header setMenuLateral={() => setMenuLateral(true)} title="Detalhes da OS" />

      {!!yesOrNoModalOpen && (
        <YesOrNoModal
          title={"Existem alterações não salvas. Você deseja prosseguir?"}
          isModalOpen={yesOrNoModalOpen}
          handleClose={() => setYesOrNoModalOpen(false)}
          handleClickYes={() => {
            setYesOrNoModalOpen(false);
            navigate(-1);
          }}
        />
      )}

      <div className="flexTopoAcoes">
        <Breadcrumb>
          {!!location?.state?.rota?.url ?
            <BreadcrumbItem onClick={() => navigate(location.state.rota.url)}>{location.state.rota.name}</BreadcrumbItem>
            :
            <BreadcrumbItem onClick={() => navigate('/ConsultaOS')}>Consulta de OS</BreadcrumbItem>
          }
          <BreadcrumbItem>OS {chamado ?? ordemServico?.chamado}</BreadcrumbItem>

        </Breadcrumb>

        <Voltar onClick={() => {
          if (formik.dirty) {
            setYesOrNoModalOpen(true);
          } else {
            navigate(-1);
          }
        }} className='botaoVoltar' />
      </div>

      {!ordemServico?.chamado ?
        <React.Fragment>
          {msgAlert}
        </React.Fragment>
        :
        <form className="DetalhesOSContainer" onSubmit={formik.handleSubmit}>
          <React.Fragment>

            <AnotacoesModal
              text={marcacoes}
              setText={setMarcacoes}
              isModalOpen={anotacoesModal}
              handleCloseModal={() => setAnotacoesModal(false)}
            />
            <ModalEncaminharOS
              handleClose={() => setIsModalOpen(false)}
              isModalOpen={isModalOpen}
            />
            {isModalPendenciasOpen &&
              <ModalPendenciasOS
                isModalOpen={isModalPendenciasOpen}
                handleCloseModal={() => {
                  setIsModalPendenciasOpen(false);
                  setCamposComPendencias(dadosPendencias.map((p: any) => `${Object.keys(p)[0]}`));
                }}
                pendencias={dadosPendencias} />
            }

            <ModalChecklistProduto
              isModalOpen={checklistProdutoModal}
              handleCloseModal={() => setChecklistProdutoModal(false)}
              produto={selectedProduto}
            />

            <div className='SubHeaderContainer'>
              <div className='IconsContainer'>
                {/* <div className={`IconContainer ${!_.isEmpty(marcacoes) ? 'IconEnabled' : ''}`}>
                <Clipboard onClick={() => setAnotacoesModal(true)} />
              </div> */}
                <Button variant="secondary" onClick={handlePrint} dimension='sm' style={{ margin: 0 }}>
                  <PrintIcon fontSize='medium' />
                </Button>
              </div>
              {/* <div className='MudarStatusContainer'>
                <AutocompleteMultiple
                  dimension="sm"
                  label={""}
                  placeholder={""}
                  noOptionsText="Nenhum status encontrado"
                  options={listaStatus.map((p: any) => ({ name: p?.nome ?? "", id: p?.id?.toString() ?? "" }))}
                  value={status}
                  onChange={setStatus}
                  id="multiple-checkbox-Status"
                  multiple={false}
                />

                <Button
                  id='atualizarStatus'
                  onClick={!!status.length ? handleSalvarStatus : () => { }}
                  disabled={!status.length}
                  variant={!status.length ? 'grey' : 'primary'}
                  dimension='sm'>
                  <RefreshIcon className='refreshIcon' style={{ transform: rotate, transition: "all 0.2s linear" }} />
                </Button>
              </div> */}
            </div>


            <div className='DetalheOS'>
              <div className='CardsContainer'>
                <div className='Card'>
                  <span className='font14'>N° Chamado</span>
                  <p className='font32'>{ordemServico?.chamado}</p>
                </div>
                <div className='Card'>
                  <span className='font14'>N° da Referência</span>
                  <p className='font32'>{ordemServico?.referencia}</p>
                </div>
                <div className='Card'>
                  <span className='font14'>Status</span>
                  <p className='font32'>{ordemServico?.status}</p>
                </div>
              </div>
              <div className='Row'>
                <div className='RowItem'>
                  <span className='font12'>Contratante</span>
                  <p className='font22'>{ordemServico?.contratante}</p>
                </div>
                <div className='RowItem'>
                  <AutocompleteMultiple
                    label={"Grupo de Serviço"}
                    placeholder={""}
                    noOptionsText={"Nenhum Grupo de Serviço encontrado"}
                    id="grupoServico"
                    name="grupoServico"
                    options={listaGrupoServico?.map((item: GrupoServico) => ({ name: item.titulo, id: item.id.toString() }))!}
                    value={formik.values.grupoServico}
                    onChange={(value: IAutocompleteValue) => {
                      formik.setFieldValue('grupoServico', value);
                      formik.setFieldValue('servico', []);
                    }}
                    multiple={false}
                    dimension='xs'
                    error={formik.touched.grupoServico && Boolean(formik.errors.grupoServico)}
                    helperText={formik.touched.grupoServico && formik.errors.grupoServico}
                    readOnly={desabilitaEdicaoCampos()}
                  />
                </div>
                <div className='RowItem'>
                  <AutocompleteMultiple
                    label={"Serviço"}
                    placeholder={""}
                    noOptionsText={"Nenhum Serviço encontrado"}
                    id="servico"
                    name="servico"
                    options={criaListaServico().sort(compare)}
                    value={formik.values.servico}
                    onChange={(value: IAutocompleteValue) => formik.setFieldValue('servico', value)}
                    multiple={false}
                    dimension='xs'
                    error={formik.touched.servico && Boolean(formik.errors.servico)}
                    helperText={formik.touched.servico && formik.errors.servico}
                    readOnly={desabilitaEdicaoCampos()}
                  />
                </div>
                <div className='RowItem'>
                  <span className='font12'>Tipo de atendimento</span>
                  <p className='font22'>{ordemServico?.tipoOS}</p>
                </div>
                <div className='RowItem'>
                  <span className='font12'>Visitas realizadas</span>
                  <p className='font22'>{ordemServico?.numeroVisita}</p>
                </div>
              </div>

              <TextArea
                className='ObservacaoField'
                label='Observação'
                dimension='sm'
                id='observacoes'
                name='observacoes'
                value={formik.values.observacoes}
                onChange={formik.handleChange}
                readOnly={desabilitaEdicaoCampos()}
              />

              <div className='ButtonContainer'>
                {handleShowPerimeter() ?
                  <div className='PerimeterDiv' title={!profileHasPermission('Liberar atendimento fora do perímetro') ? "Sem permissão" : ""}>
                    Permite atender fora do perímetro

                    <SwitchMUI
                      //@ts-ignore
                      checked={!!ordemServico?.validaPerimetro}
                      setChecked={(value: boolean) => handleSwitchValidaPerimetro(value)}
                      type={"IOS"}
                      sx={(
                        !profileHasPermission('Liberar atendimento fora do perímetro') ?
                          { "&.Mui-disabled": { cursor: 'not-allowed', backgroundColor: 'transparent' } }
                          : {}
                      )}
                      disabled={loadingPerimetro || !profileHasPermission('Liberar atendimento fora do perímetro')}
                    />

                    {!!loadingPerimetro && <CircularProgress size={25} />}
                  </div>
                  :
                  <div />
                }
              </div>
            </div>

            <AlertMessage
              isOpenAlert={openAlert}
              setOpenAlert={setOpenAlert}
              alertType={tipomsgAlert}
              msgAlert={msgAlert}
            />

            <div className='AccordionContainer'>
              <div className='AccordionHeader' id={'setOpenDadosCliente'} onClick={() => setOpenDadosCliente(!openDadosCliente)}>
                <p className='font14'>Dados do Cliente</p>
                <ExpandIcon
                  id={"IncluirTecnicoPrestadoresAccordion"}
                  className={
                    "CardOS-ExpandIcon " + (!!openDadosCliente ? "cardOpen" : "cardClosed")
                  }
                  onClick={() => {
                    setOpenDadosCliente(!openDadosCliente);
                  }}
                />
              </div>
              <Collapse in={openDadosCliente} onClose={setOpenDadosCliente} autoClose={false}>
                <div className={`DadosCliente ${openDadosCliente ? 'open' : 'close'}`}>
                  <div className='grid1'>
                    <div className='gridItem'>
                      <Input
                        label='Nome Fantasia'
                        id='nomeFantasia'
                        value={permissaoCampo("Nome Fantasia", formik.values.nomeFantasia)}
                        onChange={formik.handleChange}
                        dimension='xs'
                        name="nomeFantasia"
                        error={formik.touched.nomeFantasia && Boolean(formik.errors.nomeFantasia)}
                        helperText={formik.touched.nomeFantasia && formik.errors.nomeFantasia}
                        readOnly={desabilitaEdicaoCampos()}
                      />
                    </div>
                    <div />
                    <div className='gridItem'>
                      <Input
                        label='Razão Social'
                        id='razaoSocial'
                        value={permissaoCampo("Razão Social", formik.values.razaoSocial)}
                        onChange={formik.handleChange}
                        dimension='xs'
                        name="razaoSocial"
                        error={formik.touched.razaoSocial && Boolean(formik.errors.razaoSocial)}
                        helperText={formik.touched.razaoSocial && formik.errors.razaoSocial}
                        readOnly={desabilitaEdicaoCampos()}
                      />
                    </div>
                    <div />
                    <div className='gridItem'>
                      <Input
                        label='Documento'
                        id='numeroDocumento'
                        value={permissaoCampo("Documento", formik.values.numeroDocumento)}
                        onChange={formik.handleChange}
                        dimension='xs'
                        name="numeroDocumento"
                        error={formik.touched.numeroDocumento && Boolean(formik.errors.numeroDocumento)}
                        helperText={formik.touched.numeroDocumento && formik.errors.numeroDocumento}
                        readOnly={desabilitaEdicaoCampos()}
                      />
                    </div>
                  </div>
                  <div className='grid1'>
                    <div className='gridItem'>
                      <Input
                        label='Contato'
                        id='contato'
                        value={permissaoCampo("Contato", formik.values.contato)}
                        onChange={formik.handleChange}
                        dimension='xs'
                        name="contato"
                        error={formik.touched.contato && Boolean(formik.errors.contato)}
                        helperText={formik.touched.contato && formik.errors.contato}
                        readOnly={desabilitaEdicaoCampos()}
                      />
                    </div>
                    <div />
                    <div className='gridItem'>
                      <Input
                        label='Código'
                        id='codigoCliente'
                        value={permissaoCampo("Código", formik.values.codigoCliente)}
                        onChange={formik.handleChange}
                        dimension='xs'
                        name="codigoCliente"
                        error={formik.touched.codigoCliente && Boolean(formik.errors.codigoCliente)}
                        helperText={formik.touched.codigoCliente && formik.errors.codigoCliente}
                        readOnly={desabilitaEdicaoCampos()}
                      />
                    </div>
                    <div />
                    <div className='gridItem'>
                      <Input
                        label='Telefone(s)'
                        id='telefone'
                        value={permissaoCampo("Telefone", formik.values.telefone)}
                        onChange={formik.handleChange}
                        dimension='xs'
                        name="telefone"
                        error={formik.touched.telefone && Boolean(formik.errors.telefone)}
                        helperText={formik.touched.telefone && formik.errors.telefone}
                        // readOnly={desabilitaEdicaoCampos()}
                        readOnly={true}
                      />
                      {/* <span className='font12'>Telefone(s)</span> */}
                      {/* <span className='font12 thin' id={'telefonesOS'} onClick={(e) => e.stopPropagation()}>
                        {profileHasPermission("Telefone") ?
                          <>
                            {
                              ordemServico?.telefonesOS ? (
                                ordemServico.telefonesOS.map((telefone, index, array) => (
                                  <React.Fragment key={`${telefone.numero}_${index}`}>
                                    <a
                                      id={telefone.numero}
                                      href={createUrlToWhatsapp(`${telefone.codigoArea}${telefone.numero}`)}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {telefone.codigoArea}
                                      {telefone.numero}
                                    </a>
                                    {index < array.length - 1 && " / "}
                                  </React.Fragment>
                                ))
                              ) : (
                                ""
                              )
                            }
                          </>
                          : "Sem permissão para visualizar"
                        }
                      </span> */}
                    </div>
                  </div>

                  <div className='DetalheOS' >
                    <span className='title'>Endereço</span>
                    <div className='gridEnderecov2'>
                      <div className='gridItem'>
                        <AutocompleteMultiple
                          label={"País"}
                          placeholder={""}
                          noOptionsText={"Nenhum pais encontrado"}
                          id="pais"
                          name="pais"
                          options={[{ name: 'Brasil', id: '1' }]}
                          value={formik.values.pais}
                          onChange={(value: IAutocompleteValue) => formik.setFieldValue('pais', value)}
                          multiple={false}
                          dimension='xs'
                          error={formik.touched.pais && Boolean(formik.errors.pais)}
                          helperText={formik.touched.pais && formik.errors.pais}
                          readOnly
                        />
                      </div>
                      <div className='gridItem'>
                        <AutocompleteMultiple
                          label={"Estado"}
                          placeholder={""}
                          noOptionsText={"Nenhum estado encontrado"}
                          id="estado"
                          name="estado"
                          options={listaEstados}
                          value={permissaoCampoAutoComplete("Estado", formik.values.estado)}
                          onChange={(value: IAutocompleteValue) => {
                            formik.setFieldValue('estado', value);
                            formik.setFieldValue('cidade', []);
                          }}
                          multiple={false}
                          dimension='xs'
                          error={formik.touched.estado && Boolean(formik.errors.estado)}
                          helperText={formik.touched.estado && formik.errors.estado}
                          readOnly={desabilitaEdicaoCampos()}
                        />
                      </div>
                      <div className='gridItem'>
                        <AutocompleteMultiple
                          label={"Cidade"}
                          placeholder={""}
                          noOptionsText={"Nenhum cidade encontrado"}
                          id="cidade"
                          name="cidade"
                          options={listaCidades}
                          value={permissaoCampoAutoComplete("cidade", formik.values.cidade)}
                          onChange={(value: IAutocompleteValue) => formik.setFieldValue('cidade', value)}
                          multiple={false}
                          dimension='xs'
                          error={formik.touched.cidade && Boolean(formik.errors.cidade)}
                          helperText={formik.touched.cidade && formik.errors.cidade}
                          readOnly={desabilitaEdicaoCampos()}
                        />

                      </div>
                      <div className='gridItem'>
                        <Input
                          label='Bairro'
                          id='bairro'
                          value={permissaoCampo("Bairro", formik.values.bairro)}
                          onChange={formik.handleChange}
                          dimension='xs'
                          name="bairro"
                          error={formik.touched.bairro && Boolean(formik.errors.bairro)}
                          helperText={formik.touched.bairro && formik.errors.bairro}
                          readOnly={desabilitaEdicaoCampos()}
                        />
                      </div>
                      <div className='gridItem'>
                        <Input
                          label='Tipo'
                          id='tipo'
                          value={permissaoCampo("Tipo de Endereço", formik.values.tipo)}
                          onChange={formik.handleChange}
                          dimension='xs'
                          name="tipo"
                          error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                          helperText={formik.touched.tipo && formik.errors.tipo}
                          // readOnly={desabilitaEdicaoCampos()}
                          readOnly={true}
                        />
                      </div>
                      <div className='gridItem'>
                        <Input
                          label='Endereço'
                          id='logradouro'
                          value={permissaoCampo("Endereço", formik.values.logradouro)}
                          onChange={formik.handleChange}
                          dimension='xs'
                          name="logradouro"
                          error={formik.touched.logradouro && Boolean(formik.errors.logradouro)}
                          helperText={formik.touched.logradouro && formik.errors.logradouro}
                          readOnly={desabilitaEdicaoCampos()}
                        />
                      </div>
                    </div>
                    <div className='gridEnderecov2'>
                      <div className='gridItem'>
                        <Input
                          label='Nº'
                          id='numero'
                          value={permissaoCampo("Número", formik.values.numero)}
                          onChange={formik.handleChange}
                          dimension='xs'
                          name="numero"
                          error={formik.touched.numero && Boolean(formik.errors.numero)}
                          helperText={formik.touched.numero && formik.errors.numero}
                          readOnly={desabilitaEdicaoCampos()}
                        />
                      </div>
                      <div className='gridItem'>
                        <Input
                          label='Complemento'
                          id='complemento'
                          value={permissaoCampo("Complemento", formik.values.complemento)}
                          onChange={formik.handleChange}
                          dimension='xs'
                          name="complemento"
                          error={formik.touched.complemento && Boolean(formik.errors.complemento)}
                          helperText={formik.touched.complemento && formik.errors.complemento}
                          readOnly={desabilitaEdicaoCampos()}
                        />
                      </div>
                      <div className='gridItem'>
                        <Input
                          label='Ponto de Referência'
                          id='pontoReferencia'
                          value={permissaoCampo("Ponto de Referência", formik.values.pontoReferencia)}
                          onChange={formik.handleChange}
                          dimension='xs'
                          name="pontoReferencia"
                          error={formik.touched.pontoReferencia && Boolean(formik.errors.pontoReferencia)}
                          helperText={formik.touched.pontoReferencia && formik.errors.pontoReferencia}
                          readOnly={desabilitaEdicaoCampos()}
                        />
                      </div>
                      <div className='gridItem'>
                        <Input
                          label='Horário Comercial'
                          id='horarioComercial'
                          value={permissaoCampo("Horário Comercial", formik.values.horarioComercial)}
                          onChange={formik.handleChange}
                          dimension='xs'
                          name="horarioComercial"
                          error={formik.touched.horarioComercial && Boolean(formik.errors.horarioComercial)}
                          helperText={formik.touched.horarioComercial && formik.errors.horarioComercial}
                          // readOnly={desabilitaEdicaoCampos()}
                          readOnly={true}
                        />
                      </div>
                      <div className='gridItem'>
                        <Input
                          label='CEP'
                          id='cep'
                          value={permissaoCampo("Cep", formik.values.cep)}
                          onChange={formik.handleChange}
                          dimension='xs'
                          name="cep"
                          error={formik.touched.cep && Boolean(formik.errors.cep)}
                          helperText={formik.touched.cep && formik.errors.cep}
                          readOnly={desabilitaEdicaoCampos()}
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </Collapse>
            </div>

            <div className='AccordionContainer'>
              <div className='AccordionHeader' id={'setOpenDadosProduto'} onClick={() => setOpenDadosProduto(!openDadosProduto)}>
                <p className='font14'>Dados do Produto</p>
                <ExpandIcon
                  id={"IncluirTecnicoPrestadoresAccordion"}
                  className={
                    "CardOS-ExpandIcon " + (!!openDadosProduto ? "cardOpen" : "cardClosed")
                  }
                  onClick={() => {
                    setOpenDadosProdutoManutencao(!openDadosProduto);
                  }}
                />
              </div>
              <Collapse in={openDadosProduto} onClose={setOpenDadosProduto} autoClose={false}>
                <div className='DadosProduto'>
                  {!!ordemServico?.produtos?.filter((fl) => fl.idTipo == ITipoProdutoConsultaOS.RETIRADA)?.length && ordemServico.produtos.filter((fl) => fl.idTipo == ITipoProdutoConsultaOS.RETIRADA).map(produto => {
                    return (
                      <div className='DetalheOS' key={produto?.id}>
                        <h4>Retirada</h4>
                        {/* <span className='title'>Instalação</span> */}
                        {/* <span className='title'>{produto?.idTipo}</span> */}
                        <div className='gridEndereco'>
                          <div className="gridItem">
                            <PageviewIcon
                              className='smallIcon'
                              onClick={() => {
                                setSelectedProduto(produto)
                                setChecklistProdutoModal(true)
                              }}
                            />
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Tipo de Equipamento</span>
                            <span className='font12 thin'>{produto?.tipoEquipamento}</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Modelo</span>
                            <span className='font12 thin'>{produto?.modelo}</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Número de série</span>
                            <span className='font12 thin'>
                              {mascararNumeroSerie(produto?.numeroSerie, ordemServico?.status)}
                            </span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>ID Terminal</span>
                            <span className='font12 thin'>{produto?.numeroLogico}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="DadosProduto">
                  {!!ordemServico?.produtos?.filter((fl) => fl.idTipo == ITipoProdutoConsultaOS.ENTREGA)?.length && ordemServico.produtos.filter((fl) => fl.idTipo == ITipoProdutoConsultaOS.ENTREGA).map(produto => {
                    return (
                      <div className="DetalheOS" key={produto?.id}>
                        <h4>Entrega</h4>
                        {/* <span className='title'>Instalação</span> */}
                        {/* <span className='title'>{produto?.idTipo}</span> */}
                        <div className="gridEndereco">
                          <div className="gridItem">
                            <PageviewIcon
                              className='smallIcon'
                              onClick={() => {
                                setSelectedProduto(produto)
                                setChecklistProdutoModal(true)
                              }}
                            />
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Tipo de Equipamento</span>
                            <span className='font12 thin'>{produto?.tipoEquipamento}</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Modelo</span>
                            <span className='font12 thin'>{produto?.modelo}</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Número de série</span>
                            <span className='font12 thin'>
                              {mascararNumeroSerie(produto?.numeroSerie, ordemServico?.status)}
                            </span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>ID Terminal</span>
                            <span className='font12 thin'>{produto?.numeroLogico}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="DadosProduto">
                  {!!ordemServico?.produtos?.filter((fl) => fl.idTipo == ITipoProdutoConsultaOS.MANUTENCAO)?.length && ordemServico.produtos.filter((fl) => fl.idTipo == ITipoProdutoConsultaOS.MANUTENCAO).map(produto => {
                    return (
                      <div className="DetalheOS" key={produto?.id}>
                        <h4>Manutenção</h4>
                        {/* <span className='title'>Instalação</span> */}
                        {/* <span className='title'>{produto?.idTipo}</span> */}
                        <div className="gridEndereco">
                          <div className="gridItem">
                            <PageviewIcon
                              className='smallIcon'
                              onClick={() => {
                                setSelectedProduto(produto)
                                setChecklistProdutoModal(true)
                              }}
                            />
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Tipo de Equipamento</span>
                            <span className='font12 thin'>{produto?.tipoEquipamento}</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Modelo</span>
                            <span className='font12 thin'>{produto?.modelo}</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Número de série</span>
                            <span className='font12 thin'>
                              {mascararNumeroSerie(produto?.numeroSerie, ordemServico?.status)}
                            </span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>ID Terminal</span>
                            <span className='font12 thin'>{produto?.numeroLogico}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Collapse>
            </div>

            <div className='AccordionContainer'>
              <div className='AccordionHeader' id={'setOpenDadosChip'} onClick={() => setOpenDadosChip(!openDadosChip)}>
                <p className='font14'>Dados do Chip</p>
                <ExpandIcon
                  id={"IncluirTecnicoPrestadoresAccordion"}
                  className={
                    "CardOS-ExpandIcon " + (!!openDadosChip ? "cardOpen" : "cardClosed")
                  }
                  onClick={() => {
                    setOpenDadosChip(!openDadosChip);
                  }}
                />
              </div>
              <Collapse in={openDadosChip} onClose={setOpenDadosChip} autoClose={false}>
                <div className="DadosProduto">
                  {!!ordemServico?.atendimentos?.length && !!ordemServico?.atendimentos[0]?.chips.length && ordemServico?.atendimentos[0]?.chips?.map((chip, i) => {
                    return (
                      <div className='DetalheOS' key={i}>
                        <h4>Retirada</h4>
                        <div className='gridChips'>
                          <div className='gridItem'>
                            <span className='font12'>Modelo do Chip Instalado</span>
                            <span className='font12 thin'>{chip.idModeloInstalado}</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Modelo do Chip Retirado</span>
                            <span className='font12 thin'>{chip.idModeloRemovido}</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Número do serial do chip instalado</span>
                            <span className='font12 thin'>{chip?.serialNumberInstalado}</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Número do serial do chip retirado</span>
                            <span className='font12 thin'>{chip?.serialNumberRemovido}</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Coletou Chip?</span>
                            <span className='font12 thin'>{chip?.coletouChip ? 'SIM' : chip?.coletouChip === false ? 'NÃO' : ''}</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Chip legível?</span>
                            <span className='font12 thin'>{chip?.chipLegivel ? 'SIM' : chip?.chipLegivel === false ? 'NÃO' : ''}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Collapse>

            </div>

            <div className='AccordionContainer'>
              <div className='AccordionHeader' id={'setOpenPeriodos'} onClick={() => setOpenPeriodos(!openPeriodos)}>
                <p className='font14'>Períodos</p>
                <ExpandIcon
                  id={"IncluirTecnicoPrestadoresAccordion"}
                  className={
                    "CardOS-ExpandIcon " + (!!openPeriodos ? "cardOpen" : "cardClosed")
                  }
                  onClick={() => {
                    setOpenPeriodos(!openPeriodos);
                  }}
                />
              </div>
              <Collapse in={openPeriodos} onClose={setOpenPeriodos} autoClose={false}>
                <div className={`Periodos ${openPeriodos ? 'open' : 'close'}`}>
                  <div className='grid1'>
                    <div className='gridItem'>
                      <span className='font12'>Data de Abertura</span>
                      <span className='font12 thin'>
                        {!!ordemServico?.dataAbertura ? moment(ordemServico?.dataAbertura).format("DD/MM/YYYY HH:mm") : ""}
                      </span>
                    </div>
                    <div className='gridItem'>
                      <span className='font12'>Data Limite Prestador</span>
                      <span className='font12 thin'>
                        {!!ordemServico?.previsaoAtendimento ? moment(ordemServico?.previsaoAtendimento).format("DD/MM/YYYY HH:mm") : ""}
                      </span>
                    </div>
                    <div className='gridItem'>
                      <span className='font12'>Data Limite Cliente</span>
                      <span className='font12 thin'>
                        {!!ordemServico?.dataLimite ? moment(ordemServico?.dataLimite).format("DD/MM/YYYY HH:mm") : ""}
                      </span>
                    </div>
                    <div className='gridItem'>
                      <span className='font12'>Data Atendimento</span>
                      <span className='font12 thin'>
                        {!!ordemServico?.dataAtendimento ? moment(ordemServico?.dataAtendimento).format("DD/MM/YYYY HH:mm") : ""}
                      </span>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>

            <div className='AccordionContainer'>
              <div className='AccordionHeader' id={'setOpenDadosAtendimento'} onClick={() => setOpenDadosAtendimento(!openDadosAtendimento)}>
                <p className='font14'>Dados do Atendimento</p>
                <ExpandIcon
                  id={"IncluirTecnicoPrestadoresAccordion"}
                  className={
                    "CardOS-ExpandIcon " + (!!openDadosAtendimento ? "cardOpen" : "cardClosed")
                  }
                  onClick={() => {
                    setOpenDadosAtendimento(!openDadosAtendimento);
                  }}
                />
              </div>
              <Collapse in={openDadosAtendimento} onClose={setOpenDadosAtendimento} autoClose={false}>
                <div className={`DadosAtendimento ${openDadosAtendimento ? 'open' : 'close'}`}>
                  <div className='grid1'>
                    <div className='gridItem'>
                      <span className='font12'>Solução Apresentada</span>
                      <span className='font12 thin'>{ordemServico?.servico}</span>
                    </div>
                    <div className='gridItem'>
                      <span className='font12'>Centro de Trabalho</span>
                      <span className='font12 thin'>{ordemServico?.codigoCT}</span>
                    </div>
                    <div className='gridItem'>
                      <span className='font12'>Prestador</span>
                      <span className='font12 thin'>{ordemServico?.prestador}</span>
                    </div>
                    <div className='gridItem'>
                      <span className='font12'>Técnico</span>
                      <span className='font12 thin'>{ordemServico?.tecnico}</span>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>

            {/**Bloco Histórico de Atendimentos comentado, dados virão de outra US 4811 */}
            {/* {process.env.REACT_APP_AMBIENTE !== 'PROD' &&
            <div className='AccordionContainer'>
              <div className='AccordionHeader' onClick={() => setAtendimentosRealizados(!openAtendimentosRealizados)}>
                <p className='font14'>Histórico de Atendimentos</p>
                <ExpandIcon
                  id={"IncluirTecnicoPrestadoresAccordion"}
                  className={
                    "CardOS-ExpandIcon " + (!!openAtendimentosRealizados ? "cardOpen" : "cardClosed")
                  }
                  onClick={() => {
                    setAtendimentosRealizados(!openAtendimentosRealizados);
                  }}
                />
              </div>
              <Collapse in={openAtendimentosRealizados} onClose={setAtendimentosRealizados} autoClose={false}>
                <div className='DadosProduto'>
                  <div className='DetalheOS'>
                    <span className='title'>Nº Visita: 1</span>
                    <div className='gridAtendimentosRealizados'>
                      <div className='linha'>
                        <div className='gridItem'>
                          <div className='gridItemLeft'>
                            <div className='column'>
                              <span className='font12'>Check-in</span>
                              <span className='font12 thin'>20/10/2023  10:45</span>
                            </div>
                            <div className='column'>
                              <span className='font12'>Pré-baixa</span>
                              <span className='font12 thin'>20/10/2023  10:45</span>
                            </div>
                            <div className='column'>
                              <span className='font12'>Finalizada app</span>
                              <span className='font12 thin'>Finalizada app</span>
                            </div>
                            <div className='column'>
                              <span className='font12'>Centro de trabalho</span>
                              <span className='font12 thin'>CT00032</span>
                            </div>
                            <div className='column'>
                              <span className='font12'>Prestador</span>
                              <span className='font12 thin'>MG - Filial BH</span>
                            </div>
                            <div className='column'>
                              <span className='font12'>Solução apresentada</span>
                              <span className='font12 thin'>Troca de chip</span>
                            </div>
                            <div className='column'>
                              <span className='font12'>Técnico</span>
                              <span className='font12 thin'>Luís Carlos</span>
                            </div>
                            <div className='column'>
                              <span className='font12'>Incidente</span>
                              <span className='font12 thin'>Incidente</span>
                            </div>
                          </div>

                          <div className='column' style={{ marginTop: 30 }}>
                            <span className='font12'>Obs</span>
                            <span className='font12 thin'>Cliente não estava no local, quem recebeu foi a esposa.</span>
                          </div>
                        </div>

                        <div className='gridItem'>
                          <div className='gridItem-inner'>
                            <span className='font12'>Mapa</span>
                            <DetalhesOSMapa
                              latitude={-46.82699757791212}
                              longitude={-23.498805516080434}
                            />
                          </div>
                          <div className='gridItem-inner'>
                            <span className='font12'>Evidências</span>
                            <Evidencias />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="AtendimentoRealizadoInsucesso">
                      <span className='title'>Insucesso - Protocolado</span>
                      <div className='Insucesso'>
                        <div className='firstGrid'>
                          <div className='gridItem'>
                            <span className='font12'>Nº protocolo técnico</span>
                            <span className='font12 thin'>P16363306202</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Dt Ger. do Prot. Técnico</span>
                            <span className='font12 thin'>20/10/2023  10:45</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Macro motivo protocolo</span>
                            <span className='font12 thin'>Tratativa de Reversão SEM SUCESSO</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Motivo retenção</span>
                            <span className='font12 thin'>(2ª tentativa) Fechado no horário de funcionamento</span>
                          </div>
                        </div>
                        <div className='secondGrid'>
                          <div className='gridItem'>
                            <span className='font12'>Descrição motivo</span>
                            <span className='font12 thin'>P16363306202</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Motivo sem sucesso</span>
                            <span className='font12 thin'>Fechado no horário de funcionamento</span>
                          </div>
                          <div className='gridItem'>
                            <span className='font12'>Data da ocorrência</span>
                            <span className='font12 thin'>20/10/2023  10:45</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          } */}

            {/* <div className='AccordionContainer'>
        <div className='AccordionHeader' onClick={() => setOpenRastreamento(!openRastreamento)}>
          <p className='font14'>Rastreamento</p>
          <ExpandIcon
            id={"IncluirTecnicoPrestadoresAccordion"}
            className={
              "CardOS-ExpandIcon " + (!!openRastreamento ? "cardOpen" : "cardClosed")
            }
            onClick={() => {
              setOpenRastreamento(!openRastreamento);
            }}
          />
        </div>
        <Collapse in={openRastreamento} onClose={setOpenRastreamento} autoClose={false}>
          <div className='DetalheOS'>
            <div className='gridRastreamento'>
              <div className='gridItem'>
                <span className='font12'>Pedido de venda</span>
                <span className='font12 thin'>12345678</span>
              </div>
              <div className='gridItem'>
                <span className='font12'>Número rastreio</span>
                <span className='font12 thin'>12345678</span>
              </div>
              <div className='gridItem'>
                <span className='font12'>Nota fiscal</span>
                <span className='font12 thin'>12345678</span>
              </div>
              <div className='gridItem'>
                <span className='font12'>Data de transferência</span>
                <span className='font12 thin'>00/00/0000</span>
              </div>
              <div className='gridItem'>
                <span className='font12'>Data do recebimento</span>
                <span className='font12 thin'>00/00/0000</span>
              </div>
              <div className='gridItem'>
                <Button>Etiqueta</Button>
              </div>
            </div>

            <StickyTable
            id={"TabelaResultadoConsultaOS"}
            data={tableData}
            objTraducaoHeader={objTraducaoHeader}
            />
          </div>
        </Collapse>
      </div> */}

            <div className="acoesBotoesFlutuantes">
              <Button
                type="reset"
                variant='grey'
                dimension='sm'
                width='146px'
                onClick={() => formik.resetForm()}
                style={!formik.dirty ? { opacity: 0.5 } : {}}
                disabled={!formik.dirty}
              >
                Cancelar
              </Button>

              {!!habilitaBotaoSalvar() ?
                <Button
                  type="submit"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                >
                  Salvar
                </Button>
                :
                <Button
                  type="button"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                  style={!profileHasPermission("Editar Registro") ? { cursor: 'not-allowed', opacity: 0.5 } : { opacity: 0.5 }}
                  title={!profileHasPermission("Editar Registro") ? "Sem permissão" : ""}
                  disabled={true}>
                  Salvar
                </Button>
              }
            </div>
          </React.Fragment>
        </form>
      }
    </section>
  );
};

export default DetalhesOS;
