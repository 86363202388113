import React, { Fragment, useState, useEffect } from "react";
import { Autocomplete, TextField, CircularProgress, FormControl, Chip } from "@mui/material";
import { IAutocompleteMultiple } from "./types";
import _ from "lodash";
import "./autocompleteMultiple.scss";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutocompleteMultiple = ({
  id = "Autocomplete-multiple",
  disableClearable = false,
  clearText = "Limpar",
  limitTags = 5,
  options,
  label,
  name,
  fontSize,
  loading,
  value,
  defaultValue,
  onChange,
  noOptionsText = "Nenhuma opção encontrada",
  placeholder,
  dimension,
  error,
  helperText,
  disabled,
  showCheckbox = false,
  multiple = true,
  readOnly,
  keepOptionInTheList = false,
  allSelected,
  borderRadius,
  styleInput
}: IAutocompleteMultiple & { styleInput?: React.CSSProperties }) => {
  const [localValue, setLocalValue] = useState<any[]>(value!);

  const styleError = !!error ? { border: "1px solid #ff2323" } : {};

  if (allSelected && options[0]?.id !== "all" && multiple && options?.filter((item) => item.id !== "all")?.length !== 0) {
    options.unshift({ name: "Todos", id: "all" });
  }

  const getOptions = () => {
    if (showCheckbox || !!keepOptionInTheList) {
      return options;
    } else {
      return options?.filter((el) => {
        return !localValue?.some((f) => {
          return f.id === el.id;
        });
      });
    }
  };

  const getValue = () => {
    if (multiple) {
      return localValue;
    } else {
      return localValue?.length ? localValue[0] : null;
    }
  };

  const handleChangeValue = (event: any, newValue: any) => {
    let hasAllSelected = false;
    if (multiple) {
      hasAllSelected = newValue.some((item: any) => item.id === "all");
      if (hasAllSelected) {
        if (options.filter((item) => item.id !== "all")?.length === newValue.length) {
          setLocalValue([]);
        } else {
          setLocalValue(options.filter((item) => item.id !== "all"));
        }
      } else {
        setLocalValue(newValue);
      }
      if (!!onChange) {
        if (hasAllSelected) {
          onChange(options.filter((item) => item.id !== "all"));
        } else {
          onChange(newValue);
        }
      }
    } else {
      let value = !!newValue ? [newValue] : [];
      setLocalValue(value);
      if (!!onChange) onChange(value);
    }
  };

  useEffect(() => {
    setLocalValue(value!);
  }, [value]);

  return (
    <FormControl sx={{ m: 1, width: "100%", margin: 0 }}>
      <Autocomplete
        sx={!!readOnly ? {} : {
          "& .MuiInputBase-root": {
            backgroundColor: '#fff'
          }
        }}
        disableClearable={disableClearable}
        clearText={clearText}
        limitTags={limitTags}
        noOptionsText={noOptionsText}
        className={`Autocomplete ${dimension}`}
        classes={{ noOptions: "noOptionsWrapperClass" }}
        disableCloseOnSelect
        multiple={multiple}
        placeholder={placeholder}
        id={id}
        options={getOptions()}
        getOptionLabel={(option) => option.name}
        value={getValue()}
        defaultValue={defaultValue}
        onChange={handleChangeValue}
        disabled={disabled || readOnly}
        renderInput={(params) => (
          <>
            {label && <span className={`label ${dimension}`}>{label}</span>}
            <TextField
              {...params}
              placeholder={_.isEmpty(localValue) ? placeholder : ""}
              name={name}
              className="Input-service"
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius,
                  "& .MuiAutocomplete-input": {
                    ...(dimension === "xs" ? { padding: "0px 10px" } : {})
                  },
                },
                ...styleInput
              }}
              InputProps={{
                ...params.InputProps,
                style:
                  !!dimension && dimension === "sm"
                    ? { ...styleError, fontSize: fontSize, minHeight: "44px", borderRadius: "15px", padding: "3px" }
                    : !!dimension && dimension === "lg"
                      ? { ...styleError, fontSize: fontSize, minHeight: "56px", borderRadius: "15px" }
                      : !!dimension && dimension === "xs"
                        ? {
                          ...styleError,
                          fontSize: fontSize,
                          minHeight: "32px",
                          height: "32px",
                          borderRadius: "15px",
                          padding: "0px 10px",
                        }
                        : { ...styleError },
                endAdornment: (
                  <Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          </>
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            {!!showCheckbox && <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />}
            {option.name}
          </li>
        )}
        renderTags={(value, getTagProps) => {
          const numTags = value.length;

          return (
            <>
              {value.slice(0, limitTags).map((option, index) => (
                <Chip
                  size="small"
                  style={{ maxWidth: "10rem" }}
                  label={option.name}
                  {...getTagProps({ index })}
                  key={index}
                />
              ))}

              {numTags > limitTags && ` +${numTags - limitTags}`}
            </>
          );
        }}
      />
      {!!helperText && <span className="errorMessage">{helperText}</span>}
    </FormControl>
  );
};

export default AutocompleteMultiple;
