import React, { useContext, useState } from 'react'
import _ from 'lodash'
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId'
import './incluirEditarPrestador.scss'
import { HomeContext } from '../../../contexts/homeContext'
import { AuthContext } from "../../../contexts/auth";
import { useNavigate } from 'react-router-dom'
import Header from '../../../components/header/header'
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import Input from '../../../components/Input/Input'
import GeralTab from './geralTab'
import { CircularProgress, Tab, Tabs } from '@mui/material';
import ConfiguracaoTab from './configuracaoTab'
import LaboratorioTab from './laboratorioTab'
import Button from '../../../components/Button/styles'
import RestricoesTab from './restricoesTab';
import { IIncluirEditarPrestadorInitialValues, IPostFormPrestador, IPrestadorEndereco, IPrestadorRestricao } from '../types';
import moment from 'moment';
import AlertMessage from '../../../components/AlertMessage/alertMessage';
import { AxiosResponse } from 'axios';
import { putPrestador } from '../services/services';
import { formatDateTimeBr, timeToMoment } from '../../../helpers/util';

const IncluirEditarPrestador = () => {

  const {
    setMenuLateral,
    prestadorSelected,
    setPrestadorSelected,
  } = useContext(HomeContext);
  const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao(
    isIncluding ? "Incluir Prestador" : "Editar Prestador",
    funcionalidade);

  const localStatus = !_.isEmpty(prestadorSelected)
    ? [Number(prestadorSelected?.ativo)?.toString()]
    : ['1']
  const isIncluding = window.location.pathname.includes('IncluiPrestador')

  const [status, setStatus] = useState<string[]>(localStatus)
  const [razaoSocial, setRazaoSocial] = useState(isIncluding ? '' : prestadorSelected?.nomePrestador)
  const [nomeFantasia, setNomeFantasia] = useState(isIncluding ? '' : prestadorSelected?.nomePrestador)
  const [tabValue, setTabValue] = useState(0)

  const navigate = useNavigate()

  const statusLista = [
    {
      name: '--',
      id: ''
    },
    {
      name: 'Ativo',
      id: '1'
    },
    {
      name: 'Inativo',
      id: '0'
    }
  ]

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  //#region Form

  const [msgAlertError, setMsgAlert] = useState("Região encaminhada com sucesso!");
  const [openAlertError, setOpenAlertError] = useState(false);
  const [tipomsgAlert, setTipoMsgAlert] = useState<
    "success" | "info" | "warning" | "error"
  >("success");
  const [loading, setLoading] = useState(false);

  const initialValues: IIncluirEditarPrestadorInitialValues = {
    prestadorInicioOperacaoFormik: timeToMoment(prestadorSelected?.restricoes?.inicioOperacao) || null,
    prestadorTerminoOperacaoFormik: timeToMoment(prestadorSelected?.restricoes?.fimOperacao) || null,
    prestadorLatFormik: prestadorSelected?.endereco?.latitude || "",
    prestadorLngFormik: prestadorSelected?.endereco?.longitude || "",
  }

  const validationSchema = Yup.object().shape({
    prestadorInicioOperacaoFormik: Yup.mixed()
      .nullable()
      .required('Horário de início de operação é obrigatório'),

    prestadorTerminoOperacaoFormik: Yup.mixed()
      .nullable()
      .required('Horário de término de operação é obrigatório')
      .test(
        'is-after-inicio',
        'Horário de término deve ser posterior ao horário de início',
        function (value) {
          const { prestadorInicioOperacaoFormik } = this.parent;
          if (!value || !prestadorInicioOperacaoFormik) return true;
          return value.isAfter(prestadorInicioOperacaoFormik);
        }
      ),

    prestadorLatFormik: Yup.string()
      .required('Latitude é obrigatória')
      .matches(/^-?\d*\.?\d*$/, 'Latitude deve ser um número válido')
      .test(
        'valid-latitude',
        'Latitude deve estar entre -90 e 90',
        value => {
          const num = Number(value);
          return !isNaN(num) && num >= -90 && num <= 90;
        }
      ),

    prestadorLngFormik: Yup.string()
      .required('Longitude é obrigatória')
      .matches(/^-?\d*\.?\d*$/, 'Longitude deve ser um número válido')
      .test(
        'valid-longitude',
        'Longitude deve estar entre -180 e 180',
        value => {
          const num = Number(value);
          return !isNaN(num) && num >= -180 && num <= 180;
        }
      )
  });

  const requestSalvarAPI = async (values: IIncluirEditarPrestadorInitialValues) => {
    setLoading(true);
    try {
      const pTmp = { ...prestadorSelected };

      if (!pTmp.endereco) {
        pTmp.endereco = {} as IPrestadorEndereco;
      }

      if (!pTmp.restricoes) {
        pTmp.restricoes = {} as IPrestadorRestricao;
      }

      pTmp.restricoes.inicioOperacao = formatDateTimeBr(values.prestadorInicioOperacaoFormik, 'HH:mm:ss') ?? "";
      pTmp.restricoes.fimOperacao = formatDateTimeBr(values.prestadorTerminoOperacaoFormik, 'HH:mm:ss') ?? "";

      pTmp.endereco.latitude = values.prestadorLatFormik;
      pTmp.endereco.longitude = values.prestadorLngFormik;

      setPrestadorSelected(pTmp);

      const params = {
        idPrestador: prestadorSelected.id,
        restricoes: {
          inicioOperacao: formatDateTimeBr(values.prestadorInicioOperacaoFormik),
          fimOperacao: formatDateTimeBr(values.prestadorTerminoOperacaoFormik),
        },
        endereco: {
          latitude: values.prestadorLatFormik,
          longitude: values.prestadorLngFormik,
        }
      } as IPostFormPrestador;

      const { data } = (await putPrestador(prestadorSelected.id, params)) as AxiosResponse;

      if (data) {
        setMsgAlert(
          !!data && typeof data === "string" ? data : "Dados enviados com sucesso"
        );
        setTipoMsgAlert("success");
        setOpenAlertError(true);
      }

    } catch (e: any) {
      setMsgAlert("Houve algum erro ao enviar os dados");
      setTipoMsgAlert("error");
      setOpenAlertError(true);
    }
    setLoading(false);
  };


  //#endregion

  return (
    <section className='IncluirEditarPrestador'>
      <Header setMenuLateral={() => setMenuLateral(true)} title={isIncluding ? 'Incluir Prestador' : 'Editar Prestador'} />
      <Breadcrumb>
        <BreadcrumbItem onClick={() => navigate("/ConsultaPrestador")}>Prestador</BreadcrumbItem>
        <BreadcrumbItem>{isIncluding ? 'Incluir Prestador' : 'Editar Prestador'}</BreadcrumbItem>
      </Breadcrumb>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnMount
        onSubmit={(values, actions) => {
          requestSalvarAPI(values);
        }}
      >
        {({ values, resetForm, setFieldValue, handleChange, isValid, errors, touched, handleBlur }) => (
          <Form>
            <div className='Container'>
              <div className='Status'>
                <MultipleSelectCheckmarksId
                  nodata={"Nenhum status encontrado"}
                  options={statusLista}
                  value={status}
                  onChange={(e: string[]) => setStatus(e)}
                  id="multiple-checkbox-status"
                  idOption="multiple-checkbox-option-status-"
                  labelId="multiple-checkbox-label-status"
                  multiple={false}
                  className="ConsultarEquipamentoSelectStatus"
                  dimension='sm'
                  label="Status"
                  selectAll
                />
              </div>
              <div className='InputPrestador'>
                <Input
                  value={razaoSocial}
                  onChange={(e) => setRazaoSocial(e.target.value)}
                  label='Razão Social'
                  dimension='sm'
                />
                <Input
                  value={nomeFantasia}
                  onChange={(e) => setNomeFantasia(e.target.value)}
                  label='Nome Fantasia'
                  dimension='sm'
                />
              </div>
              <Tabs variant="fullWidth" className='TabsIncluirEditarPrestador' value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                <Tab key="tab1" label="Geral" value={0} />
                <Tab key="tab4" label="Restrições" value={3} />
                <Tab key="tab2" label="Configurações" value={1} />
                <Tab key="tab3" label="Laboratórios" value={2} />

                {window.innerWidth > 1000 &&
                  [
                    <Tab key="tab4" className='FillingButton' disabled />,
                    <Tab key="tab5" className='FillingButton' disabled />,
                    <Tab key="tab6" className='FillingButton' disabled />
                  ]
                }
              </Tabs>
              <div style={{ display: tabValue === 0 ? 'block' : 'none' }}>
                <GeralTab
                  isIncluding={true}
                  formik={{ values, resetForm, setFieldValue, handleChange, isValid, errors, touched, handleBlur, }}
                />
              </div>
              <div style={{ display: tabValue === 1 ? 'block' : 'none' }}>
                <ConfiguracaoTab isIncluding={isIncluding} />
              </div>
              <div style={{ display: tabValue === 2 ? 'block' : 'none' }}>
                <LaboratorioTab />
              </div>
              <div style={{ display: tabValue === 3 ? 'block' : 'none' }}>
                <RestricoesTab
                  formik={{ values, resetForm, setFieldValue, handleChange, isValid, errors, touched, handleBlur, }}
                />
              </div>
              <div className='ButtonContainer'>
                <Button
                  id={"botao-salvar"}
                  variant="contained"
                  type="submit"
                  disabled={!isValid}
                >
                  Salvar
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}

      <AlertMessage
        isOpenAlert={openAlertError}
        setOpenAlert={setOpenAlertError}
        alertType={tipomsgAlert}
        msgAlert={msgAlertError}
      />
    </section>
  )
}

export default IncluirEditarPrestador