import React, { useContext, useEffect, useState } from "react";
import Paper from '@mui/material/Paper';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import { useFormik } from "formik";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import "./parametrosRoteirizador.scss";
import { Breadcrumb, BreadcrumbItem } from "../../../components/breadcrumb/breadcrumb";
import Header from "../../../components/header/header";
import { HomeContext } from "../../../contexts/homeContext";
import { Section } from "../../resumoEquipamentos/view/styles";
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import { getListagemParametros, getListagemPrioridades, getListagemSequenciamentos, getParametrosRoterizador, getPerfilPrestadorParametrosRoterizador, postParametrosRoterizador } from "../services/services";
import { LISTA_SELECIONAR_TIPO_PESQUISA } from "../types/constants";
import { IGetParametrosRoterizador, IGetParametrosRoterizadorResponse, IListagemParametros, IListagemPrioridade, IListagemSequenciamentos, IParametroRoterizador, IParametroRoterizadorObjetoPrioridade, IParametroRoterizadorObjetoSequenciamento } from "../types/types";
import { AxiosResponse } from "axios";
import { IMultipleSelectCheckmarksOptions } from "../../../components/seletorMultiploId/types";
import FullscreenLoading from "../../../components/fullscreenLoading/fullscreenLoading";
import TableCell from "@mui/material/TableCell";
import { StyledTableCell } from "../../../components/table/styles";
import Button from "../../../components/Button/styles";

import { getPrestadores } from "../../enviarTecnico/services/services";
import Collapse from "@mui/material/Collapse";
import { Add } from "@mui/icons-material";
import { Box, Chip, CircularProgress } from "@mui/material";
import SwitchMUI from "../../../components/switch/switch";
import AlertMessage from "../../../components/AlertMessage/alertMessage";

const ParametrosRoterizador = () => {
  const { setMenuLateral } = useContext(HomeContext);
  const [grupoSelecionado, setGrupoSelecionado] = useState<string>('');
  const [subGrupos, setSubGrupos] = useState<IMultipleSelectCheckmarksOptions[]>([]);
  const [subGrupoSelecionado, setSubGrupoSelecionado] = useState<string>('');
  const [parametros, setParametros] = useState<IParametroRoterizador[]>([]);
  const [loading, setLoading] = useState(false);
  const [openRowId, setOpenRowId] = useState<number | null>(null);

  const [parametrosAPI, setParametrosAPI] = useState<IGetParametrosRoterizadorResponse | undefined>(undefined);

  const [apiSequenciamento, setApiSequenciamento] = useState<IMultipleSelectCheckmarksOptions[]>([]);
  const [apiPrioridade, setApiPrioridade] = useState<IMultipleSelectCheckmarksOptions[]>([]);
  const [apiParametros, setApiParametros] = useState<IParametroRoterizador[]>([]);

  const [tipomsgAlert, setTipoMsgAlert] = useState<"success" | "info" | "warning" | "error">("success");
  const [msgAlertError, setMsgAlert] = useState("");
  const [openAlertError, setOpenAlertError] = useState(false);

  const handleRowClick = (rowId: number, hasObjects: boolean) => {
    if (!hasObjects) return;

    setOpenRowId(prevId => prevId === rowId ? null : rowId);
  };

  const formik = useFormik({
    initialValues: {
      parametros: [] as IParametroRoterizador[],
    },
    onSubmit: async (values) => {
      handlePostAPI(values.parametros);
      // console.log('formik', values)
    },
  });

  const handlePostAPI = async (parametrosValues: IParametroRoterizador[]) => {
    setLoading(true);
    try {
      const params = { ...parametrosAPI, parametros: parametrosValues };
      const { data } = await postParametrosRoterizador(params) as AxiosResponse;

      setMsgAlert("Salvo com sucesso");
      setTipoMsgAlert("success");
      setOpenAlertError(true);
    } catch (e) {
      setMsgAlert("Houve algum erro ao salvar");
      setTipoMsgAlert("error");
      setOpenAlertError(true);
      setLoading(false);
    }
    buscarParametrosSubGrupo();
  }

  const { setFieldValue, values } = formik;

  const definirListaSubGrupos = async () => {
    if (!grupoSelecionado) return;
    setLoading(true);

    try {
      let lista = [] as IMultipleSelectCheckmarksOptions[];
      setParametros([]);
      setFieldValue('parametros', []);
      setSubGrupos([]);

      switch (grupoSelecionado) {
        case '0': // Perfis
          const { data: perfisData } = await getPerfilPrestadorParametrosRoterizador() as AxiosResponse;
          lista = perfisData.map((m: any) => ({ name: m.nome, id: m.id.toString() } as IMultipleSelectCheckmarksOptions));
          break;
        case '1': // Prestador
          const { data } = await getPrestadores() as AxiosResponse
          let prestadorData = [];
          if (!!data && !!data.length) {
            prestadorData = data.filter((p: any) => !!p.ativo)
              .map((m: any) => ({ name: m.nomePrestador, id: m.id.toString() } as IMultipleSelectCheckmarksOptions))
              .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          }
          lista = prestadorData;
          break;
        default:
          lista = [];
          break;
      }

      setSubGrupos(lista);
    } catch (e) {
      setMsgAlert("Houve algum erro ao buscar subgrupo");
      setTipoMsgAlert("error");
      setOpenAlertError(true);
    }
    setLoading(false);
  };

  const buscarParametrosSubGrupo = async () => {
    if (!grupoSelecionado) return;
    setLoading(true);
    setParametros([]);
    setFieldValue('parametros', []);

    try {
      let param = {} as IGetParametrosRoterizador;
      switch (grupoSelecionado) {
        case '0': // Perfis
          param = { idPerfil: Number(subGrupoSelecionado) };
          break;
        case '1': // Prestador
          param = { idPrestador: Number(subGrupoSelecionado) };
          break;
        default:
          break;
      }
      const { data } = await getParametrosRoterizador(param) as AxiosResponse<IGetParametrosRoterizadorResponse>;
      setParametrosAPI(data);

      let tmp: IParametroRoterizador[] = [];

      if (apiParametros.length > 0) {
        if (data.parametros) {
          const parametrosIds = data.parametros.map(m => m.idParametro);
          const listaSemLocal = apiParametros.filter(f => !parametrosIds.includes(f.idParametro));
          tmp = [...listaSemLocal, ...data.parametros];
        } else {
          tmp = apiParametros;
        }
      }
      tmp = tmp.sort((a, b) => Number(a.idParametro) - Number(b.idParametro));
      setParametros(tmp);
      setFieldValue('parametros', tmp);

    } catch (e) {
      setMsgAlert("Não foi possível listar os parâmetros");
      setTipoMsgAlert("error");
      setOpenAlertError(true);
    }

    setLoading(false);
  }


  useEffect(() => {
    definirListaSubGrupos();
  }, [grupoSelecionado]);

  useEffect(() => {
    buscarParametrosSubGrupo();
  }, [subGrupoSelecionado]);

  useEffect(() => {
    const getAPI = async () => {
      const { data: dataListagemParametros } = await getListagemParametros() as AxiosResponse;
      if (dataListagemParametros) {
        const tmpListagemParametros: IParametroRoterizador[] = dataListagemParametros
          .map((m: IListagemParametros) => ({
            idParametro: m.id,
            campo: m.campo,
            descricao: m.descricao,
            obrigatorio: false,
            habilitar: false,
            prioridade: [],
            sequenciamento: [],
            tipo: "",
          } as IParametroRoterizador));
        setApiParametros(tmpListagemParametros);
      }

      const { data: dataListagemSequenciamentos } = await getListagemSequenciamentos() as AxiosResponse;
      const tmpListagemSequenciamentos = dataListagemSequenciamentos.map((m: IListagemSequenciamentos) => ({ name: m.servico, id: m.id.toString() } as IMultipleSelectCheckmarksOptions));
      setApiSequenciamento(tmpListagemSequenciamentos);

      const { data: dataListagemPrioridade } = await getListagemPrioridades() as AxiosResponse;
      const tmpListagemPrioridade = dataListagemPrioridade.map((m: IListagemPrioridade) => ({ name: m.descricao, id: m.id.toString() } as IMultipleSelectCheckmarksOptions));
      setApiPrioridade(tmpListagemPrioridade);
    }
    getAPI();
  }, []);

  function RenderRow(props: { row: IParametroRoterizador, idx: number }) {
    const { row, idx } = props;
    const isOpen = openRowId === row.idParametro;
    const hasObjects =
      (row.sequenciamento && row.sequenciamento.length > 0 || row.campo == "delivery_sequence")
      || (row.prioridade && row.prioridade.length > 0 || row.campo == "delivery_priority")
      || false;

    const [objSelecionadoTmpSequenciamento, setObjSelecionadoTmpSequenciamento] = useState<string[]>([]);
    const [objetosASelecionarSequenciamento, setObjetosASelecionarSequenciamento] = useState<IMultipleSelectCheckmarksOptions[]>(apiSequenciamento);

    const handleObjectSelectionSequenciamento = (event: React.MouseEvent) => {
      event.stopPropagation();
      if (!row.sequenciamento) {
        row.sequenciamento = [];
      }
      const selectedId = objSelecionadoTmpSequenciamento[0];
      const obj = apiSequenciamento.find(f => f.id === selectedId);
      if (obj) {
        const updatedObjetosASelecionar = objetosASelecionarSequenciamento.filter(f => f.id != selectedId);
        setObjetosASelecionarSequenciamento(updatedObjetosASelecionar);
        const contaOrdem = row.sequenciamento.filter(f => f.ordem && f.ordem !== 0).length;
        const nObj = { nome: obj.name, idSequenciamento: Number(obj.id), ordem: contaOrdem + 1 } as IParametroRoterizadorObjetoSequenciamento;
        const updatedRowObjects = row.sequenciamento.filter(f => f.idSequenciamento !== Number(selectedId)).concat(nObj);
        setFieldValue(`parametros.${idx}.sequenciamento`, updatedRowObjects);
        setObjSelecionadoTmpSequenciamento([]);
      }
    };

    const handleDeleteSequenciamento = (objectId: number) => {
      if (!row.sequenciamento) return;
      const objToRemove = row.sequenciamento.find(f => f.idSequenciamento === objectId);
      if (objToRemove) {
        objToRemove.ordem = 0;
        setObjetosASelecionarSequenciamento(prev => [...prev, {
          id: objToRemove.idSequenciamento.toString(),
          name: objToRemove.nome
        }]);
        const updatedRowObjects = row.sequenciamento.map(obj =>
          obj.idSequenciamento === objectId ? { ...obj, ordem: 0 } : obj
        );
        const orderedObjects = updatedRowObjects.map(obj => {
          if (obj.ordem
            && objToRemove.ordem
            && obj.ordem > objToRemove.ordem
            && obj.ordem !== 0) {
            return { ...obj, ordem: obj.ordem - 1 };
          }
          return obj;
        });
        setFieldValue(`parametros.${idx}.sequenciamento`, orderedObjects);
      }
    };


    const [objSelecionadoTmpPrioridade, setObjSelecionadoTmpPrioridade] = useState<string[]>([]);
    const [objetosASelecionarPrioridade, setObjetosASelecionarPrioridade] = useState<IMultipleSelectCheckmarksOptions[]>(apiPrioridade);

    const handleObjectSelectionPrioridade = (event: React.MouseEvent) => {
      event.stopPropagation();
      if (!row.prioridade) {
        row.prioridade = [];
      };
      const selectedId = objSelecionadoTmpPrioridade[0];
      const obj = apiPrioridade.find(f => f.id === selectedId);
      if (obj) {
        const updatedObjetosASelecionar = objetosASelecionarPrioridade.filter(f => f.id != selectedId);
        setObjetosASelecionarPrioridade(updatedObjetosASelecionar);
        const contaOrdem = row.prioridade.filter(f => f.ordem && f.ordem !== 0).length;
        const nObj = { nome: obj.name, idPrioridade: Number(obj.id), ordem: contaOrdem + 1 } as IParametroRoterizadorObjetoPrioridade;
        const updatedRowObjects = row.prioridade.filter(f => f.idPrioridade !== Number(selectedId)).concat(nObj);
        setFieldValue(`parametros.${idx}.prioridade`, updatedRowObjects);
        setObjSelecionadoTmpPrioridade([]);
      }
    };

    const handleDeletePrioridade = (objectId: number) => {
      if (!row.prioridade) return;
      const objToRemove = row.prioridade.find(f => f.idPrioridade === objectId);
      if (objToRemove) {
        objToRemove.ordem = 0;
        setObjetosASelecionarPrioridade(prev => [...prev, {
          id: objToRemove.idPrioridade.toString(),
          name: objToRemove.nome
        }]);
        const updatedRowObjects = row.prioridade.map(obj =>
          obj.idPrioridade === objectId ? { ...obj, ordem: 0 } : obj
        );
        const orderedObjects = updatedRowObjects.map(obj => {
          if (obj.ordem
            && objToRemove.ordem
            && obj.ordem > objToRemove.ordem
            && obj.ordem !== 0) {
            return { ...obj, ordem: obj.ordem - 1 };
          }
          return obj;
        });
        setFieldValue(`parametros.${idx}.prioridade`, orderedObjects);
      }
    };

    return (
      <>
        <TableRow
          key={row.idParametro}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          onClick={() => handleRowClick(row.idParametro, hasObjects)}
          style={{ cursor: hasObjects ? 'pointer' : 'default' }}
        >
          <TableCell>{row.idParametro + 1}</TableCell>
          <TableCell>
            {row.descricao}
            {hasObjects && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRowClick(row.idParametro, hasObjects);
                }}
              >
                {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TableCell>
          <TableCell onClick={(e) => e.stopPropagation()}>
            <SwitchMUI
              disabled={false}
              type={"IOS"}
              checked={row.habilitar}
              setChecked={(event: any) => setFieldValue(`parametros.${idx}.habilitar`, !values.parametros[idx].habilitar)}
            />
          </TableCell>
        </TableRow>

        {hasObjects && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>

              {row.campo == "delivery_sequence" && (
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  <div className="container-collapse">
                    <div className="NormalGrid">
                      <div className="seletor-botao">
                        <MultipleSelectCheckmarksid
                          dimension="sm"
                          nodata="A lista está vazia."
                          options={objetosASelecionarSequenciamento}
                          value={objSelecionadoTmpSequenciamento}
                          onChange={setObjSelecionadoTmpSequenciamento}
                          placeholder=""
                          id="multiple-checkbox-Objeto"
                          idOption="multiple-checkbox-option-Objeto-"
                          labelId="multiple-checkbox-label-Objeto"
                          classes={{
                            select: "Seletor error",
                            primary: "Seletor-Option",
                            group: "Seletor-OptionGroup",
                          }}
                          multiple={false}
                        />
                        <IconButton
                          className="icon"
                          onClick={handleObjectSelectionSequenciamento}>
                          <Add />
                        </IconButton>
                      </div>
                    </div>

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                      {row.sequenciamento &&
                        row.sequenciamento
                          .filter(f => f.ordem && f.ordem !== 0)
                          .sort((a, b) => (a.ordem ? a.ordem : 0) - (b.ordem ? b.ordem : 0))
                          .map((obj) => (
                            <Chip
                              key={obj['idSequenciamento'] || obj.nome}
                              label={obj.nome}
                              onDelete={() => handleDeleteSequenciamento(obj['idSequenciamento'] as number)}
                              color="primary"
                              variant="outlined"
                              sx={{ margin: '4px' }}
                            />
                          ))}
                    </Box>
                  </div>
                </Collapse>
              )}

              {row.campo == "delivery_priority" && (
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  <div className="container-collapse">
                    <div className="NormalGrid">
                      <div className="seletor-botao">
                        <MultipleSelectCheckmarksid
                          dimension="sm"
                          nodata="A lista está vazia."
                          options={objetosASelecionarPrioridade}
                          value={objSelecionadoTmpPrioridade}
                          onChange={setObjSelecionadoTmpPrioridade}
                          placeholder=""
                          id="multiple-checkbox-Objeto"
                          idOption="multiple-checkbox-option-Objeto-"
                          labelId="multiple-checkbox-label-Objeto"
                          classes={{
                            select: "Seletor error",
                            primary: "Seletor-Option",
                            group: "Seletor-OptionGroup",
                          }}
                          multiple={false}
                        />
                        <IconButton
                          className="icon"
                          onClick={handleObjectSelectionPrioridade}>
                          <Add />
                        </IconButton>
                      </div>
                    </div>

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                      {row.prioridade &&
                        row.prioridade
                          .filter(f => f.ordem && f.ordem !== 0)
                          .sort((a, b) => (a.ordem ? a.ordem : 0) - (b.ordem ? b.ordem : 0))
                          .map((obj) => (
                            <Chip
                              key={obj['idPrioridade'] || obj.nome}
                              label={obj.nome}
                              onDelete={() => handleDeletePrioridade(obj['idPrioridade'] as number)}
                              color="primary"
                              variant="outlined"
                              sx={{ margin: '4px' }}
                            />
                          ))}
                    </Box>
                  </div>
                </Collapse>
              )}



            </TableCell>
          </TableRow>
        )}

      </>
    );
  }

  return (
    <Section id={"ParametrosRoterizador"}>
      <Header setMenuLateral={() => setMenuLateral(true)} title="Parâmetros Roteirizador" />
      <Breadcrumb>
        <BreadcrumbItem>Parâmetros Roteirizador</BreadcrumbItem>
      </Breadcrumb>

      <div className="containerRoterizador">
        <div className="NormalGrid">
          <MultipleSelectCheckmarksid
            dimension="sm"
            label={"Grupo"}
            nodata={"Nenhum grupo encontrado"}
            options={LISTA_SELECIONAR_TIPO_PESQUISA}
            value={[grupoSelecionado]}
            onChange={(value) => setGrupoSelecionado(value[0])}
            placeholder={""}
            id="multiple-checkbox-Grupo"
            idOption="multiple-checkbox-option-Grupo-"
            labelId="multiple-checkbox-label-Grupo"
            classes={{
              select: "ParametrosRoterizador-Seletor error",
              primary: "Seletor-Option",
              group: "Seletor-OptionGroup",
            }}
            multiple={false}
          />

          <MultipleSelectCheckmarksid
            dimension="sm"
            label={"SubGrupo"}
            nodata={"Nenhum sub grupo encontrado"}
            options={subGrupos}
            value={[subGrupoSelecionado]}
            onChange={(value) => {
              setSubGrupoSelecionado(value[0])
            }}
            placeholder={""}
            id="multiple-checkbox-Sub Grupo"
            idOption="multiple-checkbox-option-Sub Grupo-"
            labelId="multiple-checkbox-label-Sub Grupo"
            classes={{
              select: "ParametrosRoterizador-Seletor error",
              primary: "Seletor-Option",
              group: "Seletor-OptionGroup",
            }}
            multiple={false}
          />
        </div>

        <form onSubmit={formik.handleSubmit}>
          {!!values.parametros.length && (
            <>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>ID Regra</StyledTableCell>
                      <StyledTableCell>Regra</StyledTableCell>
                      <StyledTableCell>Habilitar</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.parametros.map((row, index) => (
                      <RenderRow key={index} idx={index} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="container-botao">
                <Button
                  id={"Botao"}
                  variant="contained"
                  type="submit"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  Salvar
                </Button>
              </div>
            </>
          )}

        </form>
      </div>

      {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}

      <AlertMessage
        isOpenAlert={openAlertError}
        setOpenAlert={setOpenAlertError}
        alertType={tipomsgAlert}
        msgAlert={msgAlertError}
      />

    </Section>
  );
};

export default ParametrosRoterizador;