import React, { useEffect, useState } from 'react';
import { Table, TableCell, TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import _ from "lodash";

import * as Styles from "../../../components/table/styles";
import { IItemTabelaChecklist, IPergunta } from '../types';
import { IColumTableBasic, Ordem } from '../../../core/types';
import { EPergunta, EPerguntasNaoVisiveisNaTabela, ETipoOpcao } from '../types/constants';
import ChecklistModal from './checklistModal';
import { IAutocompleteValueItem } from '../../../components/autocompleteMultiple/types';


interface IProps {
  perguntas: IPergunta[];
  itensTable: IItemTabelaChecklist[];
  ordem: Ordem;
  ordenacaoPor: IAutocompleteValueItem;
}

const ConsultarChecklistTabela = ({ perguntas, itensTable, ordem, ordenacaoPor }: IProps) => {

  const [rows, setRows] = useState<IItemTabelaChecklist[]>(itensTable);

  const cols: IColumTableBasic[] = [
    ...perguntas
      .filter(f => !EPerguntasNaoVisiveisNaTabela.includes(f.identificador.toUpperCase()))
      .map((r) => ({ key: r.identificador, value: r.nome } as IColumTableBasic))
  ];

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [itemModal, setItemModal] = React.useState<IItemTabelaChecklist | null>(null);

  const handleOpenModal = (i: IItemTabelaChecklist) => {
    setItemModal(i);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setItemModal(null);
    setIsModalOpen(false);
  };

  //#region Ordenar
  function obterQuilometragem(item: IItemTabelaChecklist): number {
    const respostaQuilometragem = item.respostas.find(
      (r) => r.identificadorPergunta.toUpperCase() === EPergunta.QUILOMETRAGEM
    );
    return respostaQuilometragem ? parseInt(respostaQuilometragem.dados) : 0;
  }

  function ordenarPorPropriedade(
    lista: IItemTabelaChecklist[],
    propriedade: keyof IItemTabelaChecklist | string,
    ordem: Ordem
  ): IItemTabelaChecklist[] {
    return [...lista].sort((a, b) => {
      let valorA, valorB: any;

      if (typeof propriedade === 'string' && propriedade === EPergunta.QUILOMETRAGEM) {
        valorA = obterQuilometragem(a);
        valorB = obterQuilometragem(b);
      } else {
        valorA = a[propriedade as keyof IItemTabelaChecklist];
        valorB = b[propriedade as keyof IItemTabelaChecklist];
      }

      if (valorA && valorB) {
        switch (typeof valorA) {
          case 'object':
            if (valorA instanceof Date && valorB instanceof Date) {
              return ordem === 'asc' ? valorA.getTime() - valorB.getTime() : valorB.getTime() - valorA.getTime();
            }
            break;
          case 'number':
            return ordem === 'asc' ? valorA - valorB : valorB - valorA;
          case 'string':
          default:
            return ordem === 'asc'
              ? valorA.toString().localeCompare(valorB.toString())
              : valorB.toString().localeCompare(valorA.toString());
        }
      }
      return -1;
    });
  }

  useEffect(() => {
    if (!_.isEmpty(ordenacaoPor)) {
      setRows(ordenarPorPropriedade(itensTable, ordenacaoPor.id, ordem));
    } else {
      setRows(itensTable);
    }
  }, [ordem, ordenacaoPor])

  //#endregion

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <Styles.StyledTableCell className={`center`} >Data/Horário do Envio</Styles.StyledTableCell>
              <Styles.StyledTableCell className={`center`} style={{ minWidth: 150 }}>Técnico</Styles.StyledTableCell>
              <Styles.StyledTableCell className={`center`}>Placa da Moto</Styles.StyledTableCell>
              <Styles.StyledTableCell className={`center`}>Tipo de Moto</Styles.StyledTableCell>
              {cols?.map((col) =>
                <Styles.StyledTableCell
                  className={`center`}
                  style={{ minWidth: 150 }}
                  key={col.key}>{col.value}
                </Styles.StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.idTecnico + row.dataEnvio}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => handleOpenModal(row)}
              >
                <TableCell component="th" scope="row" style={{ minWidth: 150 }}>
                  {moment(row?.dataEnvio)?.format('DD/MM/YYYY')}
                </TableCell>

                <TableCell component="th" scope="row" style={{ minWidth: 150 }}>
                  {row.nomeTecnico}
                </TableCell>
                <TableCell component="th" scope="row" style={{ minWidth: 150, textAlign: 'center' }}>
                  {row.placaMotoTecnico}
                </TableCell>
                <TableCell component="th" scope="row" style={{ minWidth: 150, textAlign: 'center' }}>
                  {row.tipoMotoTecnico}
                </TableCell>

                {cols?.map((col) => {
                  let resposta = "-";

                  let find = row.respostas.find(f => f.identificadorPergunta == col.key);

                  if (find) {
                    switch (find.tipoOpcaoResposta) {
                      case ETipoOpcao.TEXT:
                        resposta = find.dados;
                        break;
                      case ETipoOpcao.BOOLEAN:
                        resposta = find.nomeResposta;
                        break;
                      case ETipoOpcao.URL:
                        resposta = "Sim";
                        break;
                      default:
                        resposta = find.nomeResposta;
                    }
                  }

                  return (
                    <TableCell
                      key={col.key}
                      component="th" scope="row" style={{ minWidth: 150, textAlign: 'center' }}>
                      {resposta}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!!isModalOpen &&
        <ChecklistModal
          handleClose={handleCloseModal}
          isModalOpen={isModalOpen}
          item={itemModal}
        />
      }
    </>
  )
}

export default ConsultarChecklistTabela