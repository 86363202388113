import React, { useEffect, useState } from "react";
import {
  Drawer,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  Box,
  IconButton,
  Alert, SelectChangeEvent,FormHelperText,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { obterListaNivelGravidade, incluirParametro, atualizarParametro } from "../services";
import { NivelGravidade ,ItemEdicaoInclusao, GrupoParametroItem} from "../types";
import _ from "lodash";
import { AxiosResponse } from "axios";

interface SideModalProps {
  open: boolean;
  onClose: () => void;
  atualizarTabela: () => void;
  item?: ItemEdicaoInclusao;
  mode: 'create' | 'edit';
  textoAba: string;
  grupoParametroItem : GrupoParametroItem
}
interface FormErrors {
  itemName: boolean;
  severityLevel: boolean;
  resolutionDays: boolean;
}

const SideModalParametroItem: React.FC<SideModalProps> = ({
  open,
  onClose,
  atualizarTabela,
  item, mode ,textoAba,grupoParametroItem
}) => {

  const [exibirAlerta, setExibirAlerta] = useState(false);
  const [nivelGravidade, setNivelGravidade] = useState<NivelGravidade[]>([]);
  const [formData, setFormData] = useState<ItemEdicaoInclusao>({
    id: '',
    perguntaId: '',
    itemName: '',
    severityLevel: '',
    resolutionDays: '',
  });
  const [errors, setErrors] = useState<FormErrors>({
    itemName: false,
    severityLevel: false,
    resolutionDays: false,
  });
  useEffect(() => {
    if (item && mode === 'edit') {
      setFormData(item);
    } else {
      setFormData({
        id: '',
        perguntaId: '',
        itemName: '',
        severityLevel: '',
        resolutionDays: '',
      });
    }
    setErrors({
      itemName: false,
      severityLevel: false,
      resolutionDays: false,
    });
  }, [item, mode]);

  const limparCamposFechar = () => {
    setFormData({
      itemName: '',
      severityLevel: '',
      resolutionDays: '',
    });
    setErrors({
      itemName: false,
      severityLevel: false,
      resolutionDays: false,
    });
    setExibirAlerta(false);
    onClose();
  }
  const validateField = (name: keyof ItemEdicaoInclusao, value: string) => {
    setErrors(prev => ({ ...prev, [name]: value === '' }));
  };
  const handleSubmit = async (event: React.FormEvent) => {
    try {
      setExibirAlerta(false);
      event.preventDefault();
      const newErrors = {
        itemName: formData.itemName.trim() === '',
        severityLevel: formData.severityLevel === '',
        resolutionDays: formData.resolutionDays === '',
      };
      setErrors(newErrors);
      if (!Object.values(newErrors).some(error => error)) {
      let itemIncluirAtualizar = undefined;
      
      if(formData.id){
        itemIncluirAtualizar = {
          id: parseInt(formData.id),
          perguntaId: parseInt(formData!.perguntaId!),
          textoPergunta: formData.itemName,
          nivelGravidade: parseInt(formData.severityLevel),
          tempoResolucao: parseInt(formData.resolutionDays),
        };
      }else{
        itemIncluirAtualizar = {
          textoPergunta: formData.itemName,
          grupoParametroItem: grupoParametroItem,
          nivelGravidade: parseInt(formData.severityLevel),
          tempoResolucao: parseInt(formData.resolutionDays),
        };
      }
      const retorno = formData.id ? await atualizarParametro(itemIncluirAtualizar) as AxiosResponse 
                                  : await incluirParametro(itemIncluirAtualizar) as AxiosResponse;
      if (retorno.status === 200) {
        setExibirAlerta(false);
        atualizarTabela();
        limparCamposFechar();
        return;
      }else{
        setExibirAlerta(true);
        return;
      }
    }
    } catch (error: any) {
      setExibirAlerta(true);
      return;
    }
  };
  useEffect(() => {
    getNivelGravidade();
  }, []);

  const getNivelGravidade = async () => {
    const dados = await obterListaNivelGravidade();
    setNivelGravidade(dados.data);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    validateField(name as keyof ItemEdicaoInclusao, value);
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={limparCamposFechar}
      sx={{ "& .MuiDrawer-paper": { width: "400px" } }}
    >
      <Box sx={{ p: 3 }}>
        <IconButton onClick={limparCamposFechar} sx={{ mb: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" component="h2" gutterBottom>
             {mode === 'create' ? `Criar ${textoAba}` : `Editar ${textoAba}`}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Nome"
            placeholder="Digite o nome do parâmetro"
            value={formData.itemName}
            onChange={handleChange}
            name="itemName"
            margin="normal"
            error={errors.itemName}
            helperText={errors.itemName ? 'Este campo é obrigatório' : ''}
          />
          <Select
            fullWidth
            name="severityLevel"
            value={formData.severityLevel}
            onChange={handleChange}
            displayEmpty
            margin="dense"
          >
            <MenuItem value="" disabled>
              Selecione uma opção
            </MenuItem>
            {nivelGravidade.map((option: NivelGravidade) => {
              return (
                <MenuItem key={option.id} value={option.id}>
                  {option.nome}
                </MenuItem>
              );
            })}
          </Select>
          {errors.severityLevel && <FormHelperText>Este campo é obrigatório</FormHelperText>}
          <TextField
            fullWidth
            name="resolutionDays"
            label="Dias para resolução"
            placeholder="Digite o número em dias"
            type="number"
            value={formData.resolutionDays}
            onChange={handleChange}
            margin="normal"
            error={errors.resolutionDays}
            helperText={errors.resolutionDays ? 'Este campo é obrigatório' : ''}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={
              formData.itemName.length === 0 ||
              formData.severityLevel.length === 0 ||
              formData.resolutionDays.length === 0
            }
            sx={{ mt: 2 }}
          >
           {mode === 'create' ? 'Adicionar' : 'Salvar'}
          </Button>
          {exibirAlerta && (
            <Alert
              variant="filled"
              severity="warning"
              sx={{ marginTop: 1 }}
              onClose={() => setExibirAlerta(false)}
            >
              {mode === 'create' ? 'Verifique se já não existe um parâmetro com esse nome' : 'Falha ao atualizar parâmetro'}
            </Alert>
          )}
        </form>
      </Box>
    </Drawer>
  );
};

export default SideModalParametroItem;
