import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import {
  IMelhorEquipamento,
  IOrdemServico,
} from "../../../contexts/types/farolTypes";
import _ from "lodash";
import moment from "moment";
import { renderStatusOS } from "../../../helpers/statusOS";
import { ReactComponent as ExpandIcon } from "../../../assets/icons/ExpandIcon.svg";
import {
  BodyInfoItemEquip,
  BodyInfoItemEquipWrap,
} from "../../../components/cardOSList/style";
import DetalhesOSModal from "./farolDetalhesOSModal";
import { AuthContext } from "../../../contexts/auth";
import { Button } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import FarolErroImprimirModal from "./farolErroImprimirModal";
import { pdf } from "@react-pdf/renderer";
import { IPostConsultarOSsResponseOS } from "../../consultaOS/types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RoterizacaoModal from "./roterizacaoModal"; 
import { getRotaOtimizadaPorOs } from "../services";
import { AxiosResponse } from "axios";
import { RoteirizacaoTecnico } from "../types";

const headCells = [
  {
    id: "OS",
    cmpID: "oS",
    numeric: true,
    disablePadding: true,
    label: "OS",
  },
  {
    id: "Referência",
    cmpID: "referencia",
    numeric: true,
    disablePadding: false,
    label: "Referência",
  },
  {
    id: "NomeCliente",
    cmpID: "nomeCliente",
    numeric: true,
    disablePadding: false,
    label: "Nome do Cliente",
  },
  {
    id: "Data Abertura",
    cmpID: "dataAbertura",
    numeric: true,
    disablePadding: false,
    label: "Data abertura",
  },
  {
    id: "sLA",
    cmpID: "SLA",
    numeric: true,
    disablePadding: false,
    label: "SLA",
  },
  {
    id: "Status",
    cmpID: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "Serviço",
    cmpID: "servico",
    numeric: true,
    disablePadding: false,
    label: "Serviço",
  },
  {
    id: "CEP",
    cmpID: "cEP",
    numeric: true,
    disablePadding: false,
    label: "Cep",
  },
  {
    id: "Cidade",
    cmpID: "cidade",
    numeric: true,
    disablePadding: false,
    label: "Cidade",
  },
  {
    id: "Bairro",
    cmpID: "bairro",
    numeric: true,
    disablePadding: false,
    label: "Bairro",
  },
  {
    id: "Melhor Equipamento",
    cmpID: "melhorEquipamento",
    numeric: true,
    disablePadding: false,
    label: "Melhor Equipamento",
  },
];

interface EnhancedTableProps {
  allSelected: boolean;
  handleSelectAll: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  handleClickOrdenar: (sort: string) => void;
  activeArrowUP: (sort: string) => boolean;
  activeArrowDown: (sort: string) => boolean;
  ordenacao: string;
  numSelected: number;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    allSelected,
    handleSelectAll,
    activeArrowUP,
    activeArrowDown,
    ordenacao,
    handleClickOrdenar,
    numSelected,
    rowCount,
  } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      handleClickOrdenar(property);
    };

  return (
    <TableHead id={"farolOSTableHead"}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            id={"farolOSTableHeadCheckbox"}
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={allSelected}
            onChange={handleSelectAll}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={
              activeArrowUP(headCell.id)
                ? "asc"
                : activeArrowDown(headCell.id)
                ? "desc"
                : false
            }
          >
            <TableSortLabel
              id={"farolOSTableHeadSort:" + headCell.cmpID}
              active={ordenacao === headCell.id}
              direction={activeArrowUP(headCell.id) ? "asc" : "desc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell padding="checkbox" />
        <TableCell padding="checkbox" />
      </TableRow>
    </TableHead>
  );
}

export default function FarolTable({
  allSelected,
  handleSelectAll,
  handleSetOSSelected,
  handleClickOrdenar,
  activeArrowUP,
  activeArrowDown,
  ordenacao,
  osselected,
  listaOSFiltradaFarol,
  loadingFarol,
}: {
  allSelected: boolean;
  handleSelectAll: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  handleSetOSSelected: (id: number[] | IOrdemServico) => void;
  handleClickOrdenar: (sort: string) => void;
  activeArrowUP: (sort: string) => boolean;
  activeArrowDown: (sort: string) => boolean;
  ordenacao: string;
  osselected: any[];
  listaOSFiltradaFarol: any[];
  loadingFarol: boolean;
}) {
  const [dense, setDense] = React.useState(true);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isRoterizacaoModalOpen, setIsRoterizacaoModalOpen] = React.useState(false);
  const [dadosRotaOS, setDadosRotaOS] = React.useState<RoteirizacaoTecnico | undefined>(undefined);
  const [ordemServico, setOrdemServico] = React.useState<IOrdemServico | null>(
    null
  );

  const [selectedOSForPrint, setSelectedOSForPrint] = React.useState<
    (IPostConsultarOSsResponseOS | IOrdemServico)[]
  >([]);
  const [showErrorModal, setShowErrorModal] = React.useState(false);

  const { windowInnerHeight } = React.useContext(AuthContext);

  const tamanhoTelaAntesDaTablePX = 400; /*Layout acima da tabela*/
  const tamanhoTelaDepoisDaTablePX = 70; /*Paginacao*/
  const minHeightTable = 400;
  const tableHeight =
    windowInnerHeight -
    (tamanhoTelaAntesDaTablePX + tamanhoTelaDepoisDaTablePX);
  const maxHeightTable =
    tableHeight < minHeightTable ? minHeightTable : tableHeight;

  const handleOpenModal = (os: IOrdemServico) => {
    setOrdemServico(os);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setOrdemServico(null);
    setIsModalOpen(false);
  };

  const handlerShowModalRoterizacao = async (
    event: React.MouseEvent<HTMLButtonElement>,
    singleOS?: IOrdemServico
  ) => {
    event.stopPropagation();
    const dadosOs = await getRotaOtimizadaPorOs(singleOS?.chamado??0) as AxiosResponse;
    if(dadosOs.status === 200){
      const dadosRota = dadosOs.data as RoteirizacaoTecnico;
      setDadosRotaOS(dadosRota);
      setOrdemServico(singleOS || null);
      setIsRoterizacaoModalOpen(true);
    }
  };
  const handleCloseRoterizacaoModal = () => {
    setIsRoterizacaoModalOpen(false);
  };
  const handlePrintSelectedOS = (
    event: React.MouseEvent<HTMLButtonElement>,
    singleOS?: IOrdemServico | IPostConsultarOSsResponseOS
  ) => {
    event.stopPropagation();

    if (selectedOSForPrint.length > 1) {
      const contratantes = selectedOSForPrint.map((os) => os.contratante);
      const uniqueContratantes = Array.from(new Set(contratantes));
      if (uniqueContratantes.length === 1) {
        const contratante = uniqueContratantes[0];
        generatePDF(selectedOSForPrint, contratante);
        setSelectedOSForPrint([]);
      } else {
        setShowErrorModal(true);
      }
    } else if (selectedOSForPrint.length == 0 && singleOS) {
      generatePDF([singleOS], singleOS?.contratante);
      setSelectedOSForPrint([]);
    }
  };

  const generatePDF = async (
    selectedOS: (IPostConsultarOSsResponseOS | IOrdemServico)[],
    contratante: string
  ) => {
    let template;

    switch (contratante) {
      case "GETNET":
        template = await import(
          "../../../components/ImpressaoOS/templateGetNet"
        );
        break;
      default:
        template = await import(
          "../../../components/ImpressaoOS/templateDefault"
        );
    }

    const Template = template.default;
    const blob = await pdf(<Template ordemServicos={selectedOS} />).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url);
  };

  const handleOSSelected = (
    os: IPostConsultarOSsResponseOS | IOrdemServico
  ) => {
    const selectedIndex = selectedOSForPrint.findIndex(
      (selectedOS) => selectedOS.chamado === os.chamado
    );

    let newSelectedOS: (IPostConsultarOSsResponseOS | IOrdemServico)[] = [];

    if (selectedIndex === -1) {
      newSelectedOS = newSelectedOS.concat(selectedOSForPrint, os);
    } else if (selectedIndex === 0) {
      newSelectedOS = newSelectedOS.concat(selectedOSForPrint.slice(1));
    } else if (selectedIndex === selectedOSForPrint.length - 1) {
      newSelectedOS = newSelectedOS.concat(selectedOSForPrint.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedOS = newSelectedOS.concat(
        selectedOSForPrint.slice(0, selectedIndex),
        selectedOSForPrint.slice(selectedIndex + 1)
      );
    }
    setSelectedOSForPrint(newSelectedOS);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: maxHeightTable }}>
        <Table
          id={"farolOSTable"}
          stickyHeader
          aria-label="sticky table"
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            allSelected={allSelected}
            handleSelectAll={handleSelectAll}
            handleClickOrdenar={handleClickOrdenar}
            activeArrowUP={activeArrowUP}
            activeArrowDown={activeArrowDown}
            ordenacao={ordenacao}
            numSelected={osselected.length}
            rowCount={listaOSFiltradaFarol.length}
          />
          <TableBody id={"farolOSTableBody"}>
            {!!listaOSFiltradaFarol?.length ? (
              listaOSFiltradaFarol.map((row, index) => {
                const isItemSelected = !!osselected?.includes(row.chamado);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    id={"farolOSTableBodyRowOS:" + row.chamado}
                    hover
                    onClick={() => handleOpenModal(row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    tabIndex={-1}
                    key={row.chamado}
                    sx={{
                      cursor: "pointer",
                      backgroundColor: index % 2 == 0 ? "#4FD1A324" : "#FFF",
                      minHeight: "42px",
                      height: "42px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        id={"farolOSTableBodyRowOSCheckbox:" + row.chamado}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSetOSSelected(row);
                          handleOSSelected(row);
                        }}
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell padding="checkbox">
                      {renderStatusOS(row.statusFarol)?.icon}
                    </TableCell>
                    <TableCell align="center">{row.chamado}</TableCell>
                    <TableCell align="center">{row.referencia}</TableCell>
                    <TableCell align="center">{row.nomeFantasia}</TableCell>
                    <TableCell align="center">
                      {!!row.dataAbertura
                        ? moment(
                            row.dataAbertura,
                            "YYYY-MM-DDTHH:mm:ss"
                          ).format("DD/MM/YYYY HH:mm")
                        : ""}
                    </TableCell>
                    <TableCell align="center">
                      {!!row.previsaoAtendimento
                        ? moment(
                            row.previsaoAtendimento,
                            "YYYY-MM-DDTHH:mm:ss"
                          ).format("DD/MM/YYYY HH:mm")
                        : ""}
                    </TableCell>
                    <TableCell align="center">{row.status}</TableCell>
                    <TableCell align="center">{row.servico}</TableCell>
                    <TableCell align="center">{row.cep}</TableCell>
                    <TableCell align="center">{row.cidade}</TableCell>
                    <TableCell align="center">{row.bairro}</TableCell>

                    <TableCell align="center" sx={{ padding: "0px" }}>
                      {!!row.melhorEquipamento &&
                        !_.isEmpty(row.melhorEquipamento) && (
                          <BodyInfoItemEquipWrap
                            style={{
                              fontSize:
                                row.melhorEquipamento.length > 1
                                  ? "11px"
                                  : "14px",
                            }}
                          >
                            {row.melhorEquipamento.map(
                              (equipamento: IMelhorEquipamento) => {
                                return (
                                  <BodyInfoItemEquip key={equipamento.id}>
                                    {equipamento.descricao} -{" "}
                                    {equipamento.situacao}
                                  </BodyInfoItemEquip>
                                );
                              }
                            )}
                          </BodyInfoItemEquipWrap>
                        )}
                    </TableCell>
                    <TableCell>
                      {row.temRoteirizacao && (
                        <Button
                          onClick={(event) =>
                            handlerShowModalRoterizacao(event, row)
                          }
                          size="small"
                        >
                          <VisibilityIcon fontSize="small" />
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={(event) => handlePrintSelectedOS(event, row)}
                        size="small"
                      >
                        <PrintIcon fontSize="small" />
                      </Button>
                    </TableCell>

                    <TableCell align="center">
                      <ExpandIcon
                        id={"CardOS-ExpandIcon:" + row.chamado}
                        className={"CardOS-ExpandIcon cardClosed"}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <>
                {!!loadingFarol ? (
                  <TableRow
                    role="row"
                    tabIndex={-1}
                    sx={{
                      minHeight: "200px",
                      height: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <TableCell
                      colSpan={headCells.length + 2}
                      align="center"
                      sx={{ fontSize: "1.25rem" }}
                    >
                      Carregando resultados
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow
                    role="row"
                    tabIndex={-1}
                    sx={{
                      minHeight: "200px",
                      height: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <TableCell
                      colSpan={headCells.length + 2}
                      align="center"
                      sx={{ fontSize: "1.25rem" }}
                    >
                      Nenhuma OS encontrada
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!!isModalOpen && (
        <DetalhesOSModal
          handleClose={handleCloseModal}
          isModalOpen={isModalOpen}
          ordemServico={ordemServico}
        />
      )}
      <RoterizacaoModal
        open={isRoterizacaoModalOpen}
        onClose={handleCloseRoterizacaoModal}
        rotaOs={dadosRotaOS}
      />
      <FarolErroImprimirModal
        open={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />
    </Paper>
  );
}
