import './geralTab.scss'
import _ from 'lodash'
import Input from '../../../components/Input/Input';
import CustomTimeField from '../../../components/timeField/timeField';
import { IIncluirEditarPrestadorInitialValues } from '../types';
import moment from 'moment';
import { formatDateTimeBr, stringToDateTimeBr, timeToMoment } from '../../../helpers/util';
import { FormikErrors, FormikTouched } from 'formik';

interface RestricoesTabProps {
  formik: {
    values: IIncluirEditarPrestadorInitialValues;
    resetForm: () => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    handleChange: (e: React.ChangeEvent<any>) => void;
    handleBlur: (e: React.FocusEvent<any>) => void;
    isValid: boolean;
    errors: FormikErrors<IIncluirEditarPrestadorInitialValues>;
    touched: FormikTouched<IIncluirEditarPrestadorInitialValues>;
  };
}

const RestricoesTab: React.FC<RestricoesTabProps> = ({ formik }) => {

  return (
    <div className='GeralTabContainer'>

      <div className='NormalGrid'>
        <CustomTimeField
          value={formik.values.prestadorInicioOperacaoFormik || null}
          onChange={(e) => { formik.setFieldValue('prestadorInicioOperacaoFormik', e) }}
          label='Data de início da operação do depósito'
          id='window_daily[0]'
          dimension='sm'
          format="HH:mm"
        />
        <CustomTimeField
          value={formik.values.prestadorTerminoOperacaoFormik || null}
          onChange={(e) => { formik.setFieldValue('prestadorTerminoOperacaoFormik', e); }}
          label='Data de término da operação do depósito'
          id='window_daily[1]'
          dimension='sm'
          format="HH:mm"
        />
      </div>

    </div>
  )
}

export default RestricoesTab