import { useState } from 'react'
import { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import Button from '../../../components/Button/styles';

interface IErroRouteasyModal {
  isModalOpen: boolean,
  handleCloseModal: () => void
  handleClickButton: (value: boolean) => void
}

const ErroRouteasyModal = ({
  isModalOpen,
  handleCloseModal,
  handleClickButton,
}: IErroRouteasyModal) => {

  const [isOpen, setIsOpen] = useState(isModalOpen)
  useEffect(() => {
    setIsOpen(isModalOpen)
  }, [isModalOpen])

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Erro ao roteirizar</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          O que você deseja?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClickButton(false);
          }}
          variant="secondary">
          Tentar novamente
        </Button>
        <Button
          onClick={() => {
            handleClickButton(true);
            handleCloseModal();
          }}
          variant="primary"
          autoFocus>
          Enviar a campo sem roteirizar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErroRouteasyModal