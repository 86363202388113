import { apiGet, apiPost } from "../../../services/api";
import { IEnviarTecnico, ICorrigirEnvio, IConfirmarDevolucao } from "../types";

const baseUrlAtendimento = process.env.REACT_APP_API_ATENDIMENTO;
const baseUrlEstoque = process.env.REACT_APP_API_ESTOQUE;
const baseUrlOrdemServico = process.env.REACT_APP_API_ORDEMSERVICO

export const getPrestadores = async () => 
  await apiGet(`${baseUrlAtendimento}/Prestador`, {});

export const sendToTechnician = async (params: IEnviarTecnico) =>
  await apiPost(`${baseUrlEstoque}/Equipamento/enviar-equipamento`, params);

export const corrigirEnvio = async (params: ICorrigirEnvio) =>
  await apiPost(`${baseUrlEstoque}/Equipamento/corrigir-envio-equipamento`, params);

export const confirmarDevolucao = async (params: IConfirmarDevolucao) =>
  await apiPost(`${baseUrlEstoque}/Equipamento/confirmar-devolucao-equipamento`, params);

export const getOsById = async (chamado: number) => 
  await apiGet(`${baseUrlOrdemServico}/OrdemServico_/${chamado}`);