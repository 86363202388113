import { useLocation } from "react-router-dom";
import SectionCustom from "../../../components/section/section";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Alert,
} from "@mui/material";
import Collapse from "../../../components/collapse/collapse";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getPrestadores,
  getTecnicos,
  getTipoCombustivel,
  getTipoIntervalo,
  getTipoVeiculo,
  getTipoViagem,
  getVeiculoPorId,
  atualizarVeiculo,
} from "../services";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { IVeiculoEditForm, MenuSelecao } from "../types";
import { ResponseApi } from "../../../contexts/types/responsesHttp";

const EditarPrestadorVeiculo = () => {
  const location = useLocation();
  const [formData, setFormData] = useState<IVeiculoEditForm>({
    idVeiculo: 0,
    nomeVeiculo: "",
    idPrestador: 0,
    consumoDeCombustivel: 0,
    custoCombustivel: 0,
    tipoVeiculoId: 0,
    tipoViagemId: 0,
    tipoCombustivelId: 0,
    capacidades: [],
    tecnicos: [],
    restricoes: {
      id: 0,
      quantidadeVeiculosDisponiveis: 0,
      quantidadeMaximaParadas: 0,
      quantidadeMaximaServicos: 0,
      numeroMaximoHorasRota: 0,
      distanciaMaximaPercoridaRota: 0,
      distanciaMaximaEntreUmaParadaOutra: 0,
      intervalosParaPerfil: [],
    },
    caracteristicasEspeciaisVeiculo: [],
  });
  const [tecnicosDisponiveis, setTecnicosDisponiveis] = useState<MenuSelecao[]>(
    []
  );
  const [initialTecnicosCount, setInitialTecnicosCount] = useState(0);
  const [tecnicosAlterados, setTecnicosAlterados] = useState(false);
  const [listaPrestadores, setListaPrestadores] = useState<MenuSelecao[]>([]);
  const [listaTipoVeiculo, setListaTipoVeiculo] = useState<MenuSelecao[]>([]);
  const [listaTipoViagem, setListaTipoViagem] = useState<MenuSelecao[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [pendingChange, setPendingChange] = useState<{
    name: string;
    value: number;
  } | null>(null);
  const [listaTipoCombustivel, setListaTipoCombustivel] = useState<
    MenuSelecao[]
  >([]);
  const [listaTipoIntervalo, setListaTipoIntervalo] = useState<MenuSelecao[]>(
    []
  );
  const dadosVeiculo = location?.state?.dadosVeiculo || {};
  const [msgAlertError, setMsgAlertError] = useState<string>(
    "Falha na operação, tente novamente."
  );
  const [openAlertError, setOpenAlertError] = useState(false);
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(true);

  const obterVeiculo = async () => {
    setLoading(true);
    try {
      const respone = (await getVeiculoPorId(
        dadosVeiculo?.idVeiculo
      )) as AxiosResponse;
      if (respone.status === 200) {
        const veiculo = respone.data as IVeiculoEditForm;
        setFormData(veiculo);
        setInitialTecnicosCount(veiculo.tecnicos.length);
        setLoading(false);
      }
    } catch (e) {
      setMsgAlertError("Erro ao buscar dados do veículo:");
      setOpenAlertError(true);
      setLoading(false);
    }
  };
  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!formData) return;
    const { name, value } = e.target;
    const newFormData = { ...formData };

    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      if (parent === "restricoes") {
        newFormData.restricoes = {
          ...newFormData.restricoes,
          [child]:
            name.includes("quantidade") ||
            name.includes("distancia") ||
            name.includes("numero")
              ? handleIntegerInput(value)
              : value,
        };
      }
    } else {
      (newFormData as any)[name] = [
        "consumoDeCombustivel",
        "custoCombustivel",
      ].includes(name)
        ? handleIntegerInput(value)
        : value;
    }

    setFormData(newFormData);
  };
  const handleGetPrestadores = async () => {
    setLoading(true);
    await getPrestadores().then(
      (res: any) => {
        const respSuccess = res?.data as
          | ResponseApi<MenuSelecao[]>
          | [] as MenuSelecao[];
        if (respSuccess) {
          const listaPrestadores =
            respSuccess.map((item: any) => {
              return { descricao: item.descricao, id: item.id.toString() };
            }) || [];
          setListaPrestadores([...[], ...listaPrestadores]);
          setLoading(false);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<MenuSelecao[]>;
        if (respErr) {
          setMsgAlertError(respErr.errors[0].Message);
          setOpenAlertError(true);
          setListaPrestadores([]);
          setLoading(false);
        }
      }
    );
  };

  const handleGetTipoVeiculo = async () => {
    setLoading(true);
    await getTipoVeiculo().then(
      (res: any) => {
        const respSuccess = res?.data as
          | ResponseApi<MenuSelecao[]>
          | [] as MenuSelecao[];
        if (respSuccess) {
          const listaTipoVeiculo =
            respSuccess.map((item: any) => {
              return { descricao: item.descricao, id: item.id.toString() };
            }) || [];
          setListaTipoVeiculo([...[], ...listaTipoVeiculo]);
          setLoading(false);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<MenuSelecao[]>;
        if (respErr) {
          setMsgAlertError(respErr.errors[0].Message);
          setOpenAlertError(true);
          setListaTipoVeiculo([]);
          setLoading(false);
        }
      }
    );
  };

  const handleGetTipoViagem = async () => {
    setLoading(true);
    await getTipoViagem().then(
      (res: any) => {
        const respSuccess = res?.data as
          | ResponseApi<MenuSelecao[]>
          | [] as MenuSelecao[];
        if (respSuccess) {
          const listaTipoViagem =
            respSuccess.map((item: any) => {
              return { descricao: item.descricao, id: item.id.toString() };
            }) || [];
          setListaTipoViagem([...[], ...listaTipoViagem]);
          setLoading(false);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<MenuSelecao[]>;
        if (respErr) {
          setMsgAlertError(respErr.errors[0].Message);
          setOpenAlertError(true);
          setListaTipoViagem([]);
          setLoading(false);
        }
      }
    );
  };

  const handleGetTipoCombustivel = async () => {
    setLoading(true);
    await getTipoCombustivel().then(
      (res: any) => {
        const respSuccess = res?.data as
          | ResponseApi<MenuSelecao[]>
          | [] as MenuSelecao[];
        if (respSuccess) {
          const listaTipoCombustivel =
            respSuccess.map((item: any) => {
              return { descricao: item.descricao, id: item.id.toString() };
            }) || [];
          setListaTipoCombustivel([...[], ...listaTipoCombustivel]);
          setLoading(false);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<MenuSelecao[]>;
        if (respErr) {
          setMsgAlertError(respErr.errors[0].Message);
          setOpenAlertError(true);
          setListaTipoCombustivel([]);
          setLoading(false);
        }
      }
    );
  };
  const handleGetTipoIntervalo = async () => {
    setLoading(true);
    await getTipoIntervalo().then(
      (res: any) => {
        const respSuccess = res?.data as
          | ResponseApi<MenuSelecao[]>
          | [] as MenuSelecao[];
        if (respSuccess) {
          const listaTipoInvtervalo =
            respSuccess.map((item: any) => {
              return { descricao: item.descricao, id: item.id.toString() };
            }) || [];
          setListaTipoIntervalo([...[], ...listaTipoInvtervalo]);
          setLoading(false);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<MenuSelecao[]>;
        if (respErr) {
          setMsgAlertError(respErr.errors[0].Message);
          setOpenAlertError(true);
          setListaTipoIntervalo([]);
          setLoading(false);
        }
      }
    );
  };
  const handleGetTecnico = async () => {
    setTecnicosDisponiveis([]);
    setLoading(true);
    const idPrestador = formData?.idPrestador ?? dadosVeiculo?.prestadorId;
    const idTipoCombustivel =
      formData?.tipoCombustivelId ?? dadosVeiculo?.tipoCombustivelId;
    await getTecnicos(idPrestador, idTipoCombustivel).then(
      (res: any) => {
        const respSuccess = res?.data as
          | ResponseApi<MenuSelecao[]>
          | [] as MenuSelecao[];
        if (respSuccess) {
          const listaTecnicos =
            respSuccess.map((item: any) => {
              return { descricao: item.descricao, id: item.id.toString() };
            }) || [];
          setTecnicosDisponiveis([...[], ...listaTecnicos]);
          setLoading(false);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<MenuSelecao[]>;
        if (respErr) {
          setMsgAlertError(respErr.errors[0].Message);
          setOpenAlertError(true);
          setTecnicosDisponiveis([]);
          setLoading(false);
        }
      }
    );
  };

  const handleSelectChange = (e: SelectChangeEvent<number | string>) => {
    if (!formData) return;
    const { name, value } = e.target;
    const numericValue = Number(value);

    if (
      (name === "idPrestador" || name === "tipoCombustivelI") &&
      formData.tecnicos.length > 0
    ) {
      setPendingChange({ name, value: numericValue });
      setOpenModal(true);
      return;
    }

    applyChange(name, numericValue);
  };
  const applyChange = (name: string, value: number) => {
    const newFormData = {
      ...formData,
      [name]: Number(value),
    };
    setFormData(newFormData);
  };
  const handleModalClose = () => {
    setPendingChange(null);
    setOpenModal(false);
  };
  const handleModalConfirm = () => {
    if (pendingChange) {
      const newFormData = {
        ...formData,
        [pendingChange.name]: pendingChange.value,
        tecnicos: [],
      };
      setFormData(newFormData);
      setInitialTecnicosCount(0);
      setTecnicosAlterados(true);
      setPendingChange(null);
      setOpenModal(false);
    }
  };
  const handleCapacidadeAdd = () => {
    if (!formData) return;
    const newFormData = {
      ...formData,
      capacidades: [...formData.capacidades, 0],
    };
    setFormData(newFormData);
    validateForm();
  };
  const handleCapacidadeChange = (index: number, value: string) => {
    if (!formData) return;
    const newFormData = {
      ...formData,
      capacidades: formData.capacidades.map((cap, i) =>
        i === index ? handleIntegerInput(value) : cap
      ),
    };
    setFormData(newFormData);
  };
  const handleCapacidadeRemove = (index: number) => {
    if (!formData) return;
    const newFormData = {
      ...formData,
      capacidades: formData.capacidades.filter((_, i) => i !== index),
    };
    setFormData(newFormData);
    validateForm();
  };

  const handleCaracteristicaAdd = () => {
    if (!formData) return;
    const newFormData = {
      ...formData,
      caracteristicasEspeciaisVeiculo: [
        ...formData.caracteristicasEspeciaisVeiculo,
        "",
      ],
    };
    setFormData(newFormData);
  };
  const handleCaracteristicaRemove = (index: number) => {
    if (!formData) return;
    const newFormData = {
      ...formData,
      caracteristicasEspeciaisVeiculo:
        formData.caracteristicasEspeciaisVeiculo.filter((_, i) => i !== index),
    };
    setFormData(newFormData);
  };

  const handleCaracteristicaChange = (index: number, value: string) => {
    if (!formData) return;
    const newFormData = {
      ...formData,
      caracteristicasEspeciaisVeiculo:
        formData.caracteristicasEspeciaisVeiculo.map((car, i) =>
          i === index ? value : car
        ),
    };
    setFormData(newFormData);
  };
  const handleIntervaloRemove = (intervaloIndex: number) => {
    if (!formData) return;
    const newFormData = {
      ...formData,
      restricoes: {
        ...formData.restricoes,
        intervalosParaPerfil: formData.restricoes.intervalosParaPerfil.filter(
          (_, i) => i !== intervaloIndex
        ),
      },
    };
    setFormData(newFormData);
    validateForm();
  };
  const handleIntervaloAdd = () => {
    if (!formData) return;
    const newFormData = {
      ...formData,
      restricoes: {
        ...formData.restricoes,
        intervalosParaPerfil: [
          ...formData.restricoes.intervalosParaPerfil,
          {
            id: 0,
            tempoIntervalo: 0,
            nomeIntervalo: "",
            tipoIntervaloId: 1,
            horas: [
              {
                id: 0,
                dataInicio: "",
                dataFim: "",
              },
            ],
          },
        ],
      },
    };
    setFormData(newFormData);
    validateForm();
  };
  const handleIntervaloChange = (
    intervaloIndex: number,
    field: string,
    value: string | number
  ) => {
    if (!formData) return;
    const newFormData = {
      ...formData,
      restricoes: {
        ...formData.restricoes,
        intervalosParaPerfil: formData.restricoes.intervalosParaPerfil.map(
          (intervalo, index) => {
            if (index === intervaloIndex) {
              return {
                ...intervalo,
                [field]:
                  field === "tempoIntervalo"
                    ? handleIntegerInput(value as string)
                    : value,
              };
            }
            return intervalo;
          }
        ),
      },
    };
    setFormData(newFormData);
  };
  const handleHorarioAdd = (intervaloIndex: number) => {
    if (!formData) return;
    const newFormData = {
      ...formData,
      restricoes: {
        ...formData.restricoes,
        intervalosParaPerfil: formData.restricoes.intervalosParaPerfil.map(
          (intervalo, index) => {
            if (index === intervaloIndex) {
              return {
                ...intervalo,
                horas: [
                  ...intervalo.horas,
                  {
                    id: 0,
                    dataInicio: "",
                    dataFim: "",
                  },
                ],
              };
            }
            return intervalo;
          }
        ),
      },
    };
    setFormData(newFormData);
  };

  const handleHorarioRemove = (
    intervaloIndex: number,
    horarioIndex: number
  ) => {
    if (!formData) return;
    const newFormData = {
      ...formData,
      restricoes: {
        ...formData.restricoes,
        intervalosParaPerfil: formData.restricoes.intervalosParaPerfil.map(
          (intervalo, index) => {
            if (index === intervaloIndex) {
              return {
                ...intervalo,
                horas: intervalo.horas.filter((_, idx) => idx !== horarioIndex),
              };
            }
            return intervalo;
          }
        ),
      },
    };
    setFormData(newFormData);
  };
  function generateGuid(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  const handleHorarioChange = (
    intervaloIndex: number,
    horarioIndex: number,
    field: string,
    value: string
  ) => {
    if (!formData) return;
    const newFormData = {
      ...formData,
      restricoes: {
        ...formData.restricoes,
        intervalosParaPerfil: formData.restricoes.intervalosParaPerfil.map(
          (intervalo, index) => {
            if (index === intervaloIndex) {
              return {
                ...intervalo,
                horas: intervalo.horas.map((horario, idx) => {
                  if (idx === horarioIndex) {
                    return {
                      ...horario,
                      [field]: value,
                    };
                  }
                  return horario;
                }),
              };
            }
            return intervalo;
          }
        ),
      },
    };
    setFormData(newFormData);
  };
  const handleTecnicoAdd = () => {
    if (!formData) return;
    const newFormData = {
      ...formData,
      tecnicos: [...formData.tecnicos, { idTecnico: 0 }],
    };
    setFormData(newFormData);
    validateForm();
  };

  const handleTecnicoRemove = (index: number) => {
    if (!formData) return;
    const newFormData = {
      ...formData,
      tecnicos: formData.tecnicos.filter((_, i) => i !== index),
    };
    setFormData(newFormData);
    validateForm();
  };

  const handleTecnicoChange = (index: number, value: number) => {
    if (!formData) return;
    const newFormData = {
      ...formData,
      tecnicos: formData.tecnicos.map((tec, i) =>
        i === index ? { idTecnico: value } : tec
      ),
    };
    setFormData(newFormData);
  };
  const validateForm = () => {
    const hasCapacidades =
      formData?.capacidades && formData.capacidades.length > 0;

    const hasTecnicos = formData?.tecnicos && formData.tecnicos.length > 0;

    const hasValidIntervalos = formData?.restricoes.intervalosParaPerfil.every(
      (intervalo) => intervalo.horas && intervalo.horas.length > 0
    );

    const hasIntervalos =
      formData?.restricoes.intervalosParaPerfil &&
      formData.restricoes.intervalosParaPerfil.length > 0;

    const isValid =
      hasCapacidades && hasTecnicos && hasValidIntervalos && hasIntervalos;

    setIsFormValid(isValid);
  };
  const handleIntegerInput = (value: string): number => {
    const intValue = parseInt(value, 10);
    return isNaN(intValue) ? 0 : intValue;
  };
  const handleSubmit = async () => {
    setLoading(true);
    if (isFormValid && formData) {
      try {
        const retorno = (await atualizarVeiculo(formData)) as AxiosResponse;
        if (retorno.status === 200) {
          setOpenAlertError(false);
          navigate("/ConsultarPrestadorVeiculo");
        } else {
          setOpenAlertError(true);
          setLoading(false);
        }
      } catch (e: any) {
        setOpenAlertError(true);
        setLoading(false);
        if (e?.response?.data)
          setMsgAlertError(e?.response?.data);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    if (formData) {
      validateForm();
    }
  }, [formData]);
  useEffect(() => {
    obterVeiculo();
    handleGetPrestadores();
    handleGetTipoVeiculo();
    handleGetTipoViagem();
    handleGetTipoCombustivel();
    handleGetTipoIntervalo();
    handleGetTecnico();
  }, []);
  useEffect(() => {
    if (formData?.idPrestador && formData?.tipoCombustivelId) {
      handleGetTecnico();
    }
  }, [formData?.idPrestador, formData?.tipoCombustivelId]);

  return (
    <SectionCustom
      id={"EditarPrestadorVeiculos"}
      className="EditarPrestadorVeiculos"
      titleHeader="Veículo"
      titleBreadcrumbItem="Editar Veículo"
      rotaVoltar="/ConsultarPrestadorVeiculo"
      titleBreadcrumbItemBack="Consultar Veículos"
    >
      {openAlertError && (
        <div className="modalErro-Modelo">
          <Collapse onClose={setOpenAlertError} in={openAlertError}>
            <Alert
              severity="error"
              icon={<div />}
              variant="filled"
              sx={{
                mb: 2,
                bgcolor: "rgb(255, 35, 35, 0.7)",
                borderRadius: "15px",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "50px",
              }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="medium"
                  onClick={() => setOpenAlertError(false)}
                >
                  <CloseIcon
                    id={"ModalModeloCompativel-AlertCloseIcon"}
                    fontSize="inherit"
                    sx={{
                      color: "white",
                    }}
                  />
                </IconButton>
              }
            >
              <span
                style={{
                  color: "white",
                  fontSize: "16px",
                }}
                dangerouslySetInnerHTML={{ __html: msgAlertError }}
              />
            </Alert>
          </Collapse>
        </div>
      )}
      {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}
      <Box sx={{ p: 3 }}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">Editar Veículo</Typography>
            <Button
              type="button"
              variant="contained"
              color="primary"
              disabled={!isFormValid}
              onClick={handleSubmit}
            >
              Salvar
            </Button>
          </Box>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Grid container spacing={2}>
              {/* Informações Básicas */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Informações Básicas
                </Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Nome do Veículo"
                  name="nomeVeiculo"
                  InputLabelProps={{ shrink: true }}
                  value={formData?.nomeVeiculo}
                  onChange={handleTextFieldChange}
                  required
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Prestador</InputLabel>
                  <Select
                    name="idPrestador"
                    value={formData?.idPrestador || ""}
                    onChange={handleSelectChange}
                    label="Prestador"
                    required
                  >
                    {listaPrestadores.map((c: MenuSelecao) => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Consumo de Combustível"
                  name="consumoDeCombustivel"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ step: 1, min: 0 }}
                  type="number"
                  value={formData?.consumoDeCombustivel}
                  onChange={handleTextFieldChange}
                  required
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Custo do Combustível"
                  InputLabelProps={{ shrink: true }}
                  name="custoCombustivel"
                  type="number"
                  inputProps={{ step: 1, min: 0 }}
                  value={formData?.custoCombustivel}
                  onChange={handleTextFieldChange}
                  required
                />
              </Grid>

              {/* Tipos */}
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Tipo de Veículo</InputLabel>
                  <Select
                    name="tipoVeiculoId"
                    value={formData?.tipoVeiculoId || ""}
                    onChange={handleSelectChange}
                    label="Tipo de Veículo"
                    required
                  >
                    {listaTipoVeiculo.map((c: MenuSelecao) => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Tipo de Viagem</InputLabel>
                  <Select
                    name="tipoViagemId"
                    value={formData?.tipoViagemId || ""}
                    onChange={handleSelectChange}
                    label="Tipo de Viagem"
                    required
                  >
                    {listaTipoViagem.map((c: MenuSelecao) => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Tipo de Combustível</InputLabel>
                  <Select
                    name="tipoCombustivelI"
                    value={formData?.tipoCombustivelId || ""}
                    onChange={handleSelectChange}
                    label="Tipo de Combustível"
                    required
                  >
                    {listaTipoCombustivel.map((c: MenuSelecao) => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* Capacidades */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Capacidades
                </Typography>
                {formData?.capacidades.map((capacidade, index) => (
                  <Grid
                    container
                    spacing={2}
                    key={index}
                    alignItems="center"
                    sx={{ mb: 2 }}
                  >
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label={`Capacidade ${index + 1}`}
                        type="number"
                        value={capacidade}
                        inputProps={{ step: 1, min: 0 }}
                        onChange={(e) =>
                          handleCapacidadeChange(index, e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => handleCapacidadeRemove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleCapacidadeAdd}
                  sx={{ mt: 1 }}
                >
                  Adicionar Capacidade
                </Button>
              </Grid>
              {/* Técnicos */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Técnicos
                </Typography>
                {formData?.tecnicos.map((tecnico, index) => (
                  <Grid
                    container
                    spacing={2}
                    key={index}
                    alignItems="center"
                    sx={{ mb: 2 }}
                  >
                    <Grid item xs={10}>
                      <FormControl fullWidth>
                        <InputLabel shrink>Técnico</InputLabel>
                        <Select
                          value={tecnico.idTecnico}
                          onChange={(e) =>
                            handleTecnicoChange(index, Number(e.target.value))
                          }
                          label="Técnico"
                        >
                          {tecnicosDisponiveis.map((tec) => (
                            <MenuItem key={tec.id} value={tec.id}>
                              {tec.descricao}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => handleTecnicoRemove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleTecnicoAdd}
                  sx={{ mt: 1 }}
                >
                  Adicionar Técnico
                </Button>
              </Grid>
              {/* Restrições de Roteirização */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Restrições de Roteirização
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Quantidade de Veículos Disponíveis"
                  name="restricoes.quantidadeVeiculosDisponiveis"
                  type="number"
                  inputProps={{ step: 1, min: 0 }}
                  value={formData?.restricoes.quantidadeVeiculosDisponiveis}
                  onChange={handleTextFieldChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Quantidade Máxima de Paradas"
                  name="restricoes.quantidadeMaximaParadas"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  inputProps={{ step: 1, min: 0 }}
                  value={formData?.restricoes.quantidadeMaximaParadas}
                  onChange={handleTextFieldChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Quantidade Máxima de Serviços"
                  name="restricoes.quantidadeMaximaServicos"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ step: 1, min: 0 }}
                  value={formData?.restricoes.quantidadeMaximaServicos}
                  onChange={handleTextFieldChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Número Máximo de Horas na Rota"
                  name="restricoes.numeroMaximoHorasRota"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ step: 1, min: 0 }}
                  value={formData?.restricoes.numeroMaximoHorasRota}
                  onChange={handleTextFieldChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Distância Máxima Percorrida na Rota"
                  name="restricoes.distanciaMaximaPercoridaRota"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ step: 1, min: 0 }}
                  value={formData?.restricoes.distanciaMaximaPercoridaRota}
                  onChange={handleTextFieldChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Distância Máxima Entre Paradas"
                  name="restricoes.distanciaMaximaEntreUmaParadaOutra"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ step: 1, min: 0 }}
                  value={
                    formData?.restricoes.distanciaMaximaEntreUmaParadaOutra
                  }
                  onChange={handleTextFieldChange}
                />
              </Grid>

              {/* Características Especiais */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Características Especiais do Veículo
                </Typography>
                {formData?.caracteristicasEspeciaisVeiculo.map(
                  (caracteristica, index) => (
                    <Grid
                      container
                      spacing={2}
                      key={index}
                      alignItems="center"
                      sx={{ mb: 2 }}
                    >
                      <Grid item xs={10}>
                        <TextField
                          fullWidth
                          label={`Característica ${index + 1}`}
                          InputLabelProps={{ shrink: true }}
                          value={caracteristica}
                          onChange={(e) =>
                            handleCaracteristicaChange(index, e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() => handleCaracteristicaRemove(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )
                )}
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleCaracteristicaAdd}
                  sx={{ mt: 1 }}
                >
                  Adicionar Característica
                </Button>
              </Grid>

              {/* Intervalos para Perfil */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                  Intervalos para Perfil
                </Typography>
                {formData?.restricoes.intervalosParaPerfil.map(
                  (intervalo, intervaloIndex) => (
                    <Paper key={intervalo.id} sx={{ p: 2, mb: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" gutterBottom>
                            Intervalo {intervaloIndex + 1}
                            <IconButton
                              onClick={() =>
                                handleIntervaloRemove(intervaloIndex)
                              }
                              sx={{ float: "right" }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <TextField
                            fullWidth
                            label="Tempo do Intervalo"
                            type="number"
                            inputProps={{ step: 1, min: 0 }}
                            value={intervalo.tempoIntervalo}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                              handleIntervaloChange(
                                intervaloIndex,
                                "tempoIntervalo",
                                Number(e.target.value)
                              )
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <TextField
                            fullWidth
                            label="Nome do Intervalo"
                            InputLabelProps={{ shrink: true }}
                            value={intervalo.nomeIntervalo}
                            onChange={(e) =>
                              handleIntervaloChange(
                                intervaloIndex,
                                "nomeIntervalo",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl fullWidth>
                            <InputLabel>Tipo de Intervalo</InputLabel>
                            <Select
                              name="tipoIntervaloId"
                              value={intervalo.tipoIntervaloId || ""}
                              onChange={(e) =>
                                handleIntervaloChange(
                                  intervaloIndex,
                                  "tipoIntervaloId",
                                  Number(e.target.value)
                                )
                              }
                              label="Tipo de Combustível"
                              required
                            >
                              {listaTipoIntervalo.map((c: MenuSelecao) => (
                                <MenuItem key={c.id} value={c.id}>
                                  {c.descricao}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="subtitle2" gutterBottom>
                            Horários
                            <Button
                              startIcon={<AddIcon />}
                              size="small"
                              onClick={() => handleHorarioAdd(intervaloIndex)}
                              sx={{ ml: 2 }}
                            >
                              Adicionar Horário
                            </Button>
                          </Typography>

                          {intervalo.horas.map((horario, horarioIndex) => (
                            <Grid
                              container
                              spacing={2}
                              id={generateGuid()}
                              key={generateGuid()}
                              sx={{ mb: 1 }}
                            >
                              <Grid item xs={5}>
                                <TextField
                                  fullWidth
                                  label="Data/Hora Início"
                                  type="datetime-local"
                                  value={horario.dataInicio}
                                  onChange={(e) =>
                                    handleHorarioChange(
                                      intervaloIndex,
                                      horarioIndex,
                                      "dataInicio",
                                      e.target.value
                                    )
                                  }
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <TextField
                                  fullWidth
                                  label="Data/Hora Fim"
                                  type="datetime-local"
                                  value={horario.dataFim}
                                  onChange={(e) =>
                                    handleHorarioChange(
                                      intervaloIndex,
                                      horarioIndex,
                                      "dataFim",
                                      e.target.value
                                    )
                                  }
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <IconButton
                                  onClick={() =>
                                    handleHorarioRemove(
                                      intervaloIndex,
                                      horarioIndex
                                    )
                                  }
                                  sx={{ mt: 1 }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Paper>
                  )
                )}

                <Button
                  startIcon={<AddIcon />}
                  onClick={handleIntervaloAdd}
                  variant="outlined"
                  sx={{ mt: 1 }}
                >
                  Adicionar Intervalo
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>
      {/* Modal quando mudar contratante e tipo combustivel */}
      <Dialog
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Atenção</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Os técnicos já selecionados perderão os vínculos com este veículo.
            Deseja continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Não
          </Button>
          <Button onClick={handleModalConfirm} color="primary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </SectionCustom>
  );
};

export default EditarPrestadorVeiculo;
