import { useEffect, useState } from "react";
import Modal from "../../../components/modal";
import { Container } from "./modalPendenciasOSStyles";
import _ from "lodash";

interface IModalPendenciasOS {
    isModalOpen: boolean;
    handleCloseModal: () => void;
    pendencias?: any[];
}

const ModalPendenciasOS = ({isModalOpen, handleCloseModal, pendencias}: IModalPendenciasOS) => {
    const [isOpen, setIsOpen] = useState(isModalOpen);

    useEffect(() => {
        setIsOpen(isModalOpen);
    }, [isModalOpen]);

    return (
        <Modal
            handleCloseModal={handleCloseModal}
            title='Lista de pendências'
            titleStyle={{
                color: "#797D8C",
                bgcolor: 'none'
            }}
            isOpen={isOpen}
            sx={{ "& .MuiPaper-elevation": { maxWidth: "1000px" } }}
        >
            <Container>
                {!!pendencias && !_.isEmpty(pendencias) && pendencias.map((p: {}, i) => {
                    let pText: string = '';
                    _.mapKeys(p, (value: any, key: string) => pText = `${key}: ${value}`);

                    return (<div key={i}>{pText}</div>);
                })}
            </Container>
        </Modal>
    );
};

export default ModalPendenciasOS;
