import { apiGet, apiPost } from "../../../services/api";
import { IGetParametrosRoterizador, IGetParametrosRoterizadorResponse } from "../types/types";

const baseUrlOrdemServico = process.env.REACT_APP_API_ORDEMSERVICO;
const baseUrl = process.env.REACT_APP_API_ATENDIMENTO;

// Lista de perfis
export const getPerfilPrestadorParametrosRoterizador = async () =>
    await apiGet(`${baseUrl}/ParametrizacaoRoteirizador/Perfis`);

// Lista de parametros passando se é prestador ou perfil
export const getParametrosRoterizador = async (params: IGetParametrosRoterizador) =>
    await apiGet(`${baseUrl}/ParametrizacaoRoteirizador/Parametrizacao`, params);

//Envia a lista de objetos preparados no front
export const postParametrosRoterizador = async (params: IGetParametrosRoterizadorResponse) =>
    await apiPost(`${baseUrl}/ParametrizacaoRoteirizador/Parametros`, params);


export const getListagemParametros = async () =>
    await apiGet(`${baseUrl}/ParametrizacaoRoteirizador/ListagemParametros`);

export const getListagemSequenciamentos = async () =>
    await apiGet(`${baseUrl}/ParametrizacaoRoteirizador/ListagemSequenciamentos`);

export const getListagemPrioridades = async () =>
    await apiGet(`${baseUrl}/ParametrizacaoRoteirizador/ListagemPrioridades`);
