import React, { useContext, useMemo } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { ButtonMenu } from './styles'
import { HomeContext } from "../../contexts/homeContext";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MenuLateralExpandOption = ({
  item,
  isActiveMenu,
  firstMenu,
}: {
  item: any
  isActiveMenu: boolean
  firstMenu: boolean
}) => {
  const { setMenuLateral } = useContext(HomeContext);
  
  const renderSubMenu = (subMenu: any) => {
    return subMenu.map((subItem: any) => {
      if (subItem.subMenu) {
        const isActiveSubItemMenu = subItem.subMenu.some((sub: any) => sub.link === window.location.pathname);
        return (
          <MenuLateralExpandOption key={subItem.title} item={subItem} isActiveMenu={isActiveSubItemMenu} firstMenu={false} />
        );
      }
      return (
        <RouterLink id={subItem.link} key={subItem.link} to={subItem.link} >
          <ButtonMenu
            id={'button_' + subItem.link}
            sx={buttonMenuStyle}
            variant="contained"
            size="small"
            startIcon={subItem.icon}
            className={`${window.location.pathname === subItem.link ? "isActive" : ''}`}
            onClick={() => setMenuLateral(false)}
          >
            {subItem.title}
          </ButtonMenu>
        </RouterLink>
      );
    });
  };

  return (
    <Accordion
      id={'Accordion_' + item.title}
      sx={accordionStyle(isActiveMenu, firstMenu)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id={'AccordionSummary_' + item.title}
      >
        <ButtonMenu
          disableRipple={true}
          key={'buttonkey_' + item.title}
          id={'button_' + item.title}
          variant="contained"
          startIcon={item.icon}
          className={`${isActiveMenu ? "isActive" : ''}`}
        >
          {item.title}
        </ButtonMenu>
      </AccordionSummary>
      <AccordionDetails>
        {renderSubMenu(item.subMenu)}
      </AccordionDetails>
    </Accordion>
  );
};

export default MenuLateralExpandOption;

const accordionStyle = (isActiveMenu: boolean, firstMenu: boolean) => (
  {
    background: 'transparent',
    boxShadow: 'none',
    marginTop: '16px',
    '&.Mui-expanded': {
      marginTop: '16px',
      '&:first-of-type': {
        marginTop: '16px'
      },
    },
    '.MuiAccordionSummary-root': {
      padding: 0,
      minHeight: '40px',
      background: firstMenu ? (isActiveMenu ? '#fff' : 'rgb(41, 64, 51)') : isActiveMenu ? '#fff' : 'rgb(41, 64, 51) !important',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: firstMenu ? (isActiveMenu ? '#fff' : '#1e2e25') : (isActiveMenu ? '#fff' : '#1e2e25 !important'),
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
      },
      '&.Mui-expanded': {
        minHeight: '40px'
      },
      '& .MuiButtonBase-root': {
        backgroundColor: 'inherit',
        boxShadow: 'none',
      },
      '& .MuiAccordionSummary-expandIconWrapper': {
        padding: '5px'
      },
      '& .MuiSvgIcon-root path': {
        fill: isActiveMenu ? '#1e2e25' : '#fff',
      },
    },
    '.MuiAccordionSummary-content': {
      margin: 0,
      '&.Mui-expanded': {
        margin: 0,
      },
    },
    '.MuiAccordionDetails-root': {
      padding: '0px 0px 0px 10px',
    }
  }
);

const buttonMenuStyle = {
  marginTop: '8px',
  textAlign: 'initial',
  fontSize: '12px !important',
  lineHeight: 1.2,
  padding: '10px 5px 10px 30px',
  "& .MuiButton-startIcon": {
    marginRight: '20px',
  }
};
