import React, { useContext, useEffect, useState, useRef } from 'react';
import Header from '../../../components/header/header';
import { HomeContext } from '../../../contexts/homeContext';
import { AuthContext } from "../../../contexts/auth";
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import { useNavigate } from 'react-router';
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId';
import Input from '../../../components/Input/Input';
import moment from 'moment';
import Button from '../../../components/Button/styles';
import _, { get } from 'lodash';
import { useFormik } from "formik";
import * as yup from "yup";
import "./incluirEditarParametro.scss";
import { AxiosResponse } from 'axios';
import { postContratanteServicoParametro, putContratanteServicoParametro, getParametros } from '../services/services';
import { Grupo_Servico, IServico } from "../../../contexts/types/farolTypes";
import { getContratantesNovo, getGrouped_Services, getPrestadoresAtivos } from "../../farol/services";
import { CircularProgress } from '@mui/material';
import { ResponseApi } from '../../../contexts/types/responsesHttp';
import AutocompleteMultiple from '../../../components/autocompleteMultiple/autocompleteMultiple';
import { getListaServicosComFiltro } from '../../servicos/services';
import { IGetResponseParametro } from '../types';
import { IContratantesDTO } from '../../contratante/types';
import AlertMessage from '../../../components/AlertMessage/alertMessage';
import { IAutocompleteValue } from '../../../components/autocompleteMultiple/types';
import { IGetResponsePrestadoresOrdemServico } from '../../farol/types';

interface IInitialValues {
  ativo: string[],
  contratante: IAutocompleteValue,
  prestador: IAutocompleteValue,
  grupoServico: IAutocompleteValue,
  servico: IAutocompleteValue,
  parametro: { name: string, id: string, tipo: string }[],
  valor: string,
  observacao: string,
  mensagemErro: string,
  usuarioCriacao: string | number,
  dataCriacao: string,
  usuarioModificacao: string | number,
  dataModificacao: string,
};

const IncluirEditarParametro = () => {

  const isIncluding = window.location.pathname.includes('IncluirParametro');

  const isInitialMount = useRef(true);

  const { funcionalidadeDaTelaTemPermissao, user } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao(
    isIncluding ? "Incluir Parâmetro" : "Editar Parâmetro",
    funcionalidade);

  const { setMenuLateral, contratanteServicoParametroSelecionado: parametroSelecionado, setContratanteServicoParametroSelecionado: setParametroSelecionado } = useContext(HomeContext);

  const navigate = useNavigate();

  const [listaStatus, setListaStatus] = useState<{ name: string, id: string }[]>([
    { name: 'Ativo', id: '0' },
    { name: 'Inativo', id: '1' },
  ]);

  const nomeParametroRoteirizador = 'Permite_Roteirização_Automatica';
  const [listaContratantes, setListaContratantes] = useState<any[]>([]);
  const [listaPrestadores, setListaPrestadores] = useState<any[]>([]);
  const [listaParametros, setListaParametros] = useState<{ name: string, id: string, tipo: string }[]>([]);

  const [listaGrupoServico, setListaGrupoServico] = useState<Grupo_Servico[]>([]);
  const [listaServicos, setListaServicos] = useState<IServico[]>([]);
  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);
  const [disableActions, setDisableActions] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [desativarContratante, setDesativarContratante] = useState<boolean>(false);

  const getGroupByID = (ID: number) => {
    const resultadoDados: any = _.isArray(listaGrupoServico) ? [...listaGrupoServico] : [];

    return resultadoDados.find(
      (item: any) => item.id === ID
    )!;
  };

  const getServiceByID = (idGrupo: number, ID: number) => {
    const grupo = getGroupByID(idGrupo);
    if (!grupo) return [];

    const resultadoDados: any = _.isArray(grupo.servicos) ? [...grupo.servicos] : [];

    return resultadoDados.find(
      (item: any) => item.id === ID
    )!;
  };

  const getParametroByID = (ID: number) => {
    const resultadoDados: any = _.isArray(listaParametros) ? [...listaParametros] : [];
    const parametro = resultadoDados.find((item: any) => item.id === ID)!;
    return parametro;
  };

  const localGetContratantes = async () => {
    return await getContratantesNovo()
      .then((res: any) => {
        const respSuccess = res?.data as ResponseApi<IContratantesDTO[]> | null;

        if (respSuccess) {
          const listaContratante = respSuccess.data?.map((item: any) => { return { name: item.nome, id: item.id?.toString() ?? "", idOppay: item.id_Oppay?.toString() ?? "" } }) || [];
          setListaContratantes(filtraContratantesDoUsuario(listaContratante));
        };

      }, err => {
        const respErr = err?.response?.data as ResponseApi<IContratantesDTO[]>;
        if (respErr) setListaContratantes([]);
      });
  };

  const filtraContratantesDoUsuario = (contratantes: IAutocompleteValue) => {
    let options = [...contratantes];

    if (!!user?.contratanteOrdemServico) {
      options = options.filter((o: any) => Number(o.idOppay) === user.contratanteOrdemServico);
    }

    return options;
  };

  const validationSchema = yup.object({
    ativo: yup.array().max(1).min(1, 'Campo obrigatório'),
    contratante: yup.array().max(desativarContratante ? 0 : 1).min(desativarContratante ? 0 : 1, 'Campo obrigatório'),
    prestador: yup.array().max(desativarContratante ? 1 : 0).min(desativarContratante ? 1 : 0, 'Campo obrigatório'),
    valor: yup.string().required("Campo obrigatório"),
    parametro: yup.array().max(1).min(1, 'Campo obrigatório'),
  });

  const initialValuesIncluir: IInitialValues = {
    ativo: ['1'],
    grupoServico: [],
    servico: [],
    contratante: [],
    prestador: [],
    parametro: [],
    valor: '',
    mensagemErro: '',
    observacao: '',
    usuarioCriacao: '',
    dataCriacao: '',
    usuarioModificacao: '',
    dataModificacao: '',
  };

  const initialValuesEditar: IInitialValues = {
    ativo: [(parametroSelecionado?.ativo ? '0' : '1')],
    grupoServico: setInitialGroup(),
    servico: setInitialService(),
    contratante: parametroSelecionado?.idContratante ? [{ name: parametroSelecionado?.contratante ?? "", id: String(parametroSelecionado?.idContratante) }] : [],
    prestador: parametroSelecionado?.idPrestador ? [{ name: parametroSelecionado?.prestador ?? "", id: String(parametroSelecionado?.idPrestador) }] : [],
    mensagemErro: parametroSelecionado?.mensagemErro || '',
    observacao: parametroSelecionado?.observacao || '',
    valor: parametroSelecionado?.valor || '',
    usuarioCriacao: parametroSelecionado?.usuarioCriacao ?? '',
    dataCriacao: parametroSelecionado?.dataCriacao ? moment(parametroSelecionado?.dataCriacao, "YYYY/MM/DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : '',
    usuarioModificacao: parametroSelecionado?.usuarioModificacao ?? '',
    dataModificacao: parametroSelecionado?.dataModificacao ? moment(parametroSelecionado?.dataModificacao, "YYYY/MM/DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : '',
    parametro: setItinialParam(),
  };

  function setInitialGroup(): { name: string; id: string; }[] {
    const grupo = getGroupByID(parametroSelecionado?.idGrupoServico);
    return !!grupo ? [{ name: grupo?.nome ?? "", id: String(parametroSelecionado?.idGrupoServico) }] : [];
  }

  function setInitialService(): { name: string; id: string; }[] {
    const servico = getServiceByID(parametroSelecionado?.idGrupoServico, parametroSelecionado?.idServico);
    return !!servico ? [{ name: servico?.nome ?? "", id: String(parametroSelecionado?.idServico) }] : [];
  }

  function setItinialParam(): { name: string; id: string; tipo: string; }[] {
    const param = getParametroByID(parametroSelecionado?.idParametro);
    return !!param ? [{ name: param?.nome ?? "", id: String(parametroSelecionado?.idParametro), tipo: param?.tipo ?? "" }] : [];
  }

  const formik = useFormik({
    initialValues: isIncluding ? initialValuesIncluir : initialValuesEditar,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      if (isIncluding) {
        if (!!profileHasPermission("Novo Registro")) return handleIncludeParametro(values);
      } else {
        if (!!profileHasPermission("Editar Registro")) return handleEditParametro(values);
      }
    }
  });

  useEffect(() => {
    if(formik.values.parametro.length){
      if(formik.values.parametro[0].name === nomeParametroRoteirizador)
                          setDesativarContratante(true);
    }
  }, [initialValuesEditar,desativarContratante]);

  const handleParametros = async () => {
    try {

      await getParametros().then((resp: any) => {
        const respSuccess = resp?.data as ResponseApi<IGetResponseParametro[]>;
        const parametros = respSuccess?.data as any[] ?? [];
        setListaParametros(parametros);
      });
    } catch (e) {
      setListaParametros([])
    }
  }

  const handleIncludeParametro = async (values: IInitialValues) => {
    setDisableActions(true);
    setOpenAlert(false);
    setLoading(true);

    try {
      const statusBool: boolean = values.ativo[0] === '0' ? true : false;
      const { data } = await postContratanteServicoParametro({
        ativo: statusBool,
        idContratante: values.contratante?.[0]?.id ? Number(values.contratante[0].id) : 0,
        idPrestador: values.prestador?.[0]?.id ? Number(values.prestador[0].id) : null,
        idGrupoServico: values.grupoServico?.[0]?.id ? Number(values.grupoServico[0].id) : null,
        idServico: values.servico?.[0]?.id ? Number(values.servico[0].id) : null,
        idParametro: values.parametro?.[0]?.id ? Number(values.parametro[0].id) : 0,
        tipo: values.parametro?.[0]?.tipo ? values.parametro[0].tipo : "",
        valor: values.valor ?? "",
        observacao: values.observacao ?? "",
        mensagemErro: values.mensagemErro ?? "",
      }) as AxiosResponse;

      setLoading(false);
      setMsgAlert(!!data && typeof data === "string" ? data : 'Parâmetro criado com sucesso');

      setTipoMsgAlert("success");
      setOpenAlert(true);
      setDisableActions(false);

      formik.resetForm();

      handleGetGroupesServices();
    } catch (e: any) {
      setLoading(false);
      setMsgAlert(e?.response?.data?.errors ? Object.values(e.response.data.errors[0].Message).join("") : "Erro ao incluir parâmetro");
      setTipoMsgAlert("error");
      setOpenAlert(true);
      setDisableActions(false);
    }
  }

  const handleEditParametro = async (values: IInitialValues) => {
    setDisableActions(true);
    setOpenAlert(false);
    setLoading(true);

    try {
      const statusBool: boolean = values.ativo[0] === '0' ? true : false;

      const { data } = await putContratanteServicoParametro({
        id: Number(parametroSelecionado.id),
        ativo: statusBool,
        idContratante: values.contratante?.[0]?.id ? Number(values.contratante[0].id) : 0,
        idPrestador: values.prestador?.[0]?.id ? Number(values.prestador[0].id) : null,
        idGrupoServico: values.grupoServico?.[0]?.id ? Number(values.grupoServico[0].id) : null,
        idServico: values.servico?.[0]?.id ? Number(values.servico[0].id) : null,
        idParametro: values.parametro?.[0]?.id ? Number(values.parametro[0].id) : 0,
        tipo: parametroSelecionado?.tipo ?? "",
        valor: values.valor ?? "",
        observacao: values.observacao ?? "",
        mensagemErro: values.mensagemErro ?? "",

      }, Number(parametroSelecionado.id)) as AxiosResponse;

      /**Atualiza dados do parametro no contexto */
      setParametroSelecionado({
        ...parametroSelecionado,
        ativo: statusBool,
        idContratante: values.contratante?.[0]?.id ? Number(values.contratante[0].id) : 0,
        idPrestador: values.prestador?.[0]?.id ? Number(values.prestador[0].id) : null,
        prestador: values.prestador?.[0]?.name ? values.prestador[0].name : "",
        idGrupoServico: values.grupoServico?.[0]?.id ? Number(values.grupoServico[0].id) : null,
        idServico: values.servico?.[0]?.id ? Number(values.servico[0].id) : null,
        parametro: values.parametro?.[0]?.id ? Number(values.parametro[0].id) : 0,
        tipo: parametroSelecionado?.tipo ?? "",
        valor: values.valor ?? "",
        observacao: values.observacao ?? "",
        mensagemErro: values.mensagemErro ?? "",
        idUsuarioModificacao: user?.idUsuario ?? 0,
        dataModificacao: moment().format("YYYY-MM-DD HH:mm:ss"),
      });

      setLoading(false);

      setMsgAlert(!!data && typeof data === "string" ? data : "Paramêtro alterado com sucesso!");

      setTipoMsgAlert("success");
      setOpenAlert(true);
      setDisableActions(false);

      handleGetGroupesServices();
    } catch (e: any) {
      setLoading(false);
      const errorMsg = e?.response?.data?.errors ? Object.values(e.response.data.errors[0].Message).join("") : "Erro ao editar paramêtro";
      setMsgAlert(errorMsg);
      setTipoMsgAlert("error");
      setOpenAlert(true);
      setDisableActions(false);
    }
  }

  const handleGetGroupesServices = async () => {
    await getGrouped_Services().then((resp: any) => {

      const respSuccess = resp?.data as ResponseApi<Grupo_Servico[]>;
      const grupos = respSuccess?.data as any[] ?? [];

      setListaGrupoServico(grupos);
    }, (err: any) => {
      const respErr = err?.response?.data as ResponseApi<any>;
    });
  };

  const handleChangeGrupoServico = (e: any) => {
    handleGetServicosPorGrupo(Number(e[0]?.id));
    return formik.setFieldValue('grupoServico', e);
  }

  const handleChangeContratante = (e: any) => {
    return formik.setFieldValue('contratante', e);
  }

  const handleChangePrestador = (e: any) => {
    return formik.setFieldValue('prestador', e);
  }

  const handleGetServicosPorGrupo = async (idGrupoServico: number) => {

    await getListaServicosComFiltro({ IdGrupoServico: idGrupoServico })
      .then((resp: any) => {
        const respSuccess = resp?.data as ResponseApi<IServico[]>;
        const servicos = respSuccess?.data as any[] ?? [];
        setListaServicos(servicos);
      }, err => {
        const respErr = err?.response?.data as ResponseApi<any>;
        // Implementar tratamento de erro
      });
  };

  const getProvider = async () => {
    try {
      const { data }: { data: IGetResponsePrestadoresOrdemServico } = await getPrestadoresAtivos() as AxiosResponse;

      let prestadores: IAutocompleteValue = [];

      if (!!data?.success && !_.isEmpty(data?.data?.prestadores)) {
        prestadores = data?.data?.prestadores.map((m) => ({ ...m, name: m.nomePrestador, id: m.id?.toString() }))
          .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      };

      setListaPrestadores(filtraPrestadoresDoUsuario(prestadores));

    } catch (e) {
      setListaPrestadores([]);
    }
  };

  const filtraPrestadoresDoUsuario = (prestadores: any) => {
    let options: any[] = [...prestadores];

    if (!!user?.prestadores && !!user.prestadores.length) {
      options = options.filter((o: any) => user.prestadores.includes(Number(o.id)));
    }

    return options;
  };

  useEffect(() => {
    if (isInitialMount.current) {
      handleGetGroupesServices();
      handleParametros();
      localGetContratantes();
      getProvider();

      if (!isIncluding && !parametroSelecionado?.id) navigate("/IncluirParametro");

      isInitialMount.current = false;
    } else {
      if (isIncluding) formik.setValues(initialValuesIncluir);
    }
  }, [
    isIncluding
  ]);

  return (
    <section className='IncluirEditarParametro'>
      <Header setMenuLateral={() => setMenuLateral(true)} title={isIncluding ? 'Incluir parâmetro' : 'Editar parâmetro'} />
      <Breadcrumb>
        <BreadcrumbItem onClick={() => navigate("/ConsultarParametros")}>Consultar Parâmetros</BreadcrumbItem>
        <BreadcrumbItem>{isIncluding ? 'Incluir parâmetro' : 'Editar parâmetro'}</BreadcrumbItem>
      </Breadcrumb>

      <form className="Container" onSubmit={formik.handleSubmit}>
        <div className="IncluirParametro">
          <div className={'firstRow grid'}>
            <MultipleSelectCheckmarksId
              nodata={"Nenhum status encontrado"}
              options={listaStatus}
              value={formik.values.ativo}
              onChange={(e) => formik.setFieldValue('ativo', e)}
              placeholder={"Selecionar status"}
              id="multiple-checkbox-status"
              idOption="multiple-checkbox-option-status"
              labelId="multiple-checkbox-label-status"
              multiple={false}
              label='Status'
              name="status"
              className='IncluirServicoSelectStatus'
              dimension='sm'
              error={formik.touched.ativo && Boolean(formik.errors.ativo)}
              helperText={formik.touched.ativo && formik.errors.ativo}
              disabled={disableActions}
            />
            <AutocompleteMultiple
              dimension="sm"
              label={"Contratante"}
              placeholder={""}
              noOptionsText={"Nenhum contratante encontrado"}
              options={listaContratantes}
              value={formik.values.contratante}
              onChange={handleChangeContratante}
              id="multiple-checkbox-contratante"
              error={formik.touched.contratante && Boolean(formik.errors.contratante)}
              helperText={formik.touched.contratante && formik.errors.contratante}
              multiple={false}
              disabled={disableActions || desativarContratante}
            />

            <AutocompleteMultiple
              label={"Prestador"}
              placeholder={""}
              noOptionsText={"Nenhum prestador encontrado"}
              id="autocomplete-prestador"
              options={listaPrestadores}
              value={formik.values.prestador}
              onChange={handleChangePrestador}
              disabled={disableActions}
              multiple={false}
              dimension='sm'
              error={formik.touched.prestador && Boolean(formik.errors.prestador)}
              helperText={formik.touched.prestador && formik.errors.prestador}
            />

            <AutocompleteMultiple
              dimension="sm"
              label={"Grupo Serviços"}
              placeholder={""}
              noOptionsText={"Nenhum grupo de serviço encontrado"}
              options={listaGrupoServico?.map((item: any) => ({ name: item.nome, id: item.id.toString() }))!}
              value={formik.values.grupoServico}
              onChange={handleChangeGrupoServico}
              id="multiple-checkbox-grupoServico"
              error={formik.touched.grupoServico && Boolean(formik.errors.grupoServico)}
              helperText={formik.touched.grupoServico && formik.errors.grupoServico}
              multiple={false}
              disabled={disableActions}
            />
            <AutocompleteMultiple
              dimension="sm"
              label={"Serviços"}
              placeholder={""}
              noOptionsText={"Nenhum serviço encontrado"}
              options={listaServicos?.map((item: any) => ({ name: item.nome, id: item.id.toString() }))!}
              value={formik.values.servico}
              onChange={(e: any) => formik.setFieldValue('servico', e)}
              id="multiple-checkbox-servico"
              error={formik.touched.servico && Boolean(formik.errors.servico)}
              helperText={formik.touched.servico && formik.errors.servico}
              multiple={false}
              disabled={disableActions}
            />
          </div>

          <div className={'secondRow grid'}>
            <AutocompleteMultiple
              dimension="sm"
              label={"Parâmetro"}
              placeholder={""}
              noOptionsText={"Nenhum Parâmetro encontrado"}
              options={listaParametros?.map((item: any) => ({ name: item.nome, id: item.id.toString(), tipo: item.tipo }))!}
              value={formik.values.parametro}
              onChange={(e: any) =>{
                  if(e[0].name === nomeParametroRoteirizador){
                    formik.setFieldValue('contratante', []);
                    setDesativarContratante(true);
                  }else{
                    setDesativarContratante(false);
                  }
                  formik.setFieldValue('parametro', e)
                }}
              id="multiple-checkbox-parametro"
              error={formik.touched.parametro && Boolean(formik.errors.parametro)}
              helperText={formik.touched.parametro && formik.errors.parametro}
              multiple={false}
              disabled={disableActions}
            />

            <Input
              label='Valor'
              id='valor'
              dimension='sm'
              name='valor'
              error={formik.touched.valor && Boolean(formik.errors.valor)}
              helperText={formik.touched.valor && formik.errors.valor}
              value={formik.values.valor}
              maxLength={50}
              onChange={formik.handleChange}
              disabled={disableActions}
            />
          </div>

          <div className="thirdRow grid">
            <Input
              label='Observação'
              id='observacao'
              dimension='sm'
              name='observacao'
              error={formik.touched.observacao && Boolean(formik.errors.observacao)}
              helperText={formik.touched.observacao && formik.errors.observacao}
              value={formik.values.observacao}
              maxLength={50}
              onChange={formik.handleChange}
              disabled={disableActions}
            />

            <Input
              label='Mensagem de erro'
              id='mensagemErro'
              dimension='sm'
              name='mensagemErro'
              error={formik.touched.mensagemErro && Boolean(formik.errors.mensagemErro)}
              helperText={formik.touched.mensagemErro && formik.errors.mensagemErro}
              value={formik.values.mensagemErro}
              maxLength={50}
              onChange={formik.handleChange}
              disabled={disableActions}
            />
          </div>

          <div className={'fourthRow grid'}>
            <Input
              dimension="sm"
              label='Criado Por'
              name='usuarioCriacao'
              id='usuarioCriacao'
              value={formik.values.usuarioCriacao}
              onChange={formik.handleChange}
              error={formik.touched.usuarioCriacao && Boolean(formik.errors.usuarioCriacao)}
              helperText={formik.touched.usuarioCriacao && formik.errors.usuarioCriacao}
              readOnly
            />
            <Input
              dimension="sm"
              label='Criado em'
              id="dataCriacao"
              value={formik.values.dataCriacao}
              onChange={(e) => (formik.setFieldValue('dataCriacao', e))}
              error={formik.touched.dataCriacao && Boolean(formik.errors.dataCriacao)}
              helperText={(formik.touched.dataCriacao && formik.errors.dataCriacao) ? "Campo obrigatório" : ""}
              readOnly
            />
            <Input
              dimension="sm"
              label='Editado por'
              name='usuarioModificacao'
              id='usuarioModificacao'
              value={formik.values.usuarioModificacao}
              onChange={formik.handleChange}
              error={formik.touched.usuarioModificacao && Boolean(formik.errors.usuarioModificacao)}
              helperText={formik.touched.usuarioModificacao && formik.errors.usuarioModificacao}
              readOnly
            />
            <Input
              dimension="sm"
              label='Editado em'
              id="dataModificacao"
              value={formik.values.dataModificacao}
              onChange={(e) => (formik.setFieldValue('dataModificacao', e))}
              error={formik.touched.dataModificacao && Boolean(formik.errors.dataModificacao)}
              helperText={(formik.touched.dataModificacao && formik.errors.dataModificacao) ? "Campo obrigatório" : ""}
              readOnly
            />
          </div>

          {!!isIncluding &&
            <>
              {!!profileHasPermission("Novo Registro") ?
                <Button
                  type="submit"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                >
                  Salvar
                </Button>
                :
                <Button
                  type="button"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                  style={{ cursor: 'not-allowed', opacity: 0.5 }}
                  disabled={true}>
                  Salvar
                </Button>
              }
            </>
          }

          {!isIncluding &&
            <>
              {!!profileHasPermission("Editar Registro") ?
                <Button
                  type="submit"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                >
                  Salvar
                </Button>
                :
                <Button
                  type="button"
                  variant='primary'
                  dimension='sm'
                  width='146px'
                  style={{ cursor: 'not-allowed', opacity: 0.5 }}
                  disabled={true}>
                  Salvar
                </Button>
              }
            </>
          }
        </div>

        {!!loading && <CircularProgress style={{ margin: '0px auto' }} />}

        <AlertMessage
          isOpenAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertType={tipomsgAlert}
          msgAlert={msgAlert}
        />
      </form>
    </section>
  );
};

export default IncluirEditarParametro;
