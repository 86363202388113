import SectionCustom from "../../../components/section/section";
import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  CircularProgress,
  SelectChangeEvent,
  IconButton,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { IPostVeiculo, MenuSelecao } from "../types";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "../../../components/collapse/collapse";
import {
  getPrestadores,
  getTipoCombustivel,
  getTipoIntervalo,
  getTipoVeiculo,
  getTipoViagem,
  postVeiculo,
} from "../services";
import { ResponseApi } from "../../../contexts/types/responsesHttp";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
const initialState: IPostVeiculo = {
  nomeVeiculo: "",
  idPrestador: 0,
  consumoDeCombustivel: 0,
  custoCombustivel: 0,
  tipoVeiculo: 1,
  tipoViagem: 1,
  tipoCombustivel: 1,
  capacidades: [],
  restricoesRoteirizacaoVeiculo: {
    quantidadeVeiculosDisponiveis: 0,
    quantidadeMaximaParadas: 0,
    quantidadeMaximaServicos: 0,
    numeroMaximoHorasRota: 0,
    distanciaMaximaPercoridaRota: 0,
    distanciaMaximaEntreUmaParadaOutra: 0,
    caracteristicasEspeciaisVeiculo: [],
    intervalosParaPerfil: [
      {
        tempoIntervalo: 0,
        nomeIntervalo: "",
        tipoIntervalo: 1,
        prestadorVeiculoRestricaoIntervaloPerfilHoras: [
          {
            dataInicio: "",
            dataFim: "",
          },
        ],
      },
    ],
  },
};
const CriarPrestadorVeiculo = () => {
  const [formData, setFormData] = useState<IPostVeiculo>(initialState);
  const [listaPrestadores, setListaPrestadores] = useState<MenuSelecao[]>([]);
  const [listaTipoVeiculo, setListaTipoVeiculo] = useState<MenuSelecao[]>([]);
  const [listaTipoViagem, setListaTipoViagem] = useState<MenuSelecao[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [listaTipoCombustivel, setListaTipoCombustivel] = useState<
    MenuSelecao[]
  >([]);
  const [listaTipoIntervalo, setListaTipoIntervalo] = useState<MenuSelecao[]>(
    []
  );
  const [msgAlertError, setMsgAlertError] = useState<string>(
    "Falha na operação, tente novamente."
  );
  const [openAlertError, setOpenAlertError] = useState(false);
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const checkFormValidity = (data: IPostVeiculo) => {
    const isValid =
      data.nomeVeiculo !== "" &&
      data.tipoVeiculo !== 0 &&
      data.tipoCombustivel !== 0 &&
      data.capacidades.length > 0 &&
      data.restricoesRoteirizacaoVeiculo.quantidadeVeiculosDisponiveis > 0 &&
      data.idPrestador !== 0;

    setIsFormValid(isValid);
  };
  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newFormData = { ...formData };

    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      if (parent === "restricoesRoteirizacaoVeiculo") {
        newFormData.restricoesRoteirizacaoVeiculo = {
          ...newFormData.restricoesRoteirizacaoVeiculo,
          [child]:
            name.includes("quantidade") ||
            name.includes("distancia") ||
            name.includes("numero")
              ? handleIntegerInput(value)
              : value,
        };
      }
    } else {
      (newFormData as any)[name] = [
        "consumoDeCombustivel",
        "custoCombustivel",
      ].includes(name)
        ? handleIntegerInput(value)
        : value;
    }

    setFormData(newFormData);
    checkFormValidity(newFormData);
  };

  const handleSelectChange = (e: SelectChangeEvent<number>) => {
    const { name, value } = e.target;
    const newFormData = {
      ...formData,
      [name]: Number(value),
    };
    setFormData(newFormData);
    checkFormValidity(newFormData);
  };

  const handleCapacidadeAdd = () => {
    const newFormData = {
      ...formData,
      capacidades: [...formData.capacidades, 0],
    };
    setFormData(newFormData);
    checkFormValidity(newFormData);
  };
  const handleCapacidadeChange = (index: number, value: string) => {
    setFormData((prev) => ({
      ...prev,
      capacidades: prev.capacidades.map((cap, i) =>
        i === index ? handleIntegerInput(value) : cap
      ),
    }));
  };

  const handleCapacidadeRemove = (index: number) => {
    const newFormData = {
      ...formData,
      capacidades: formData.capacidades.filter((_, i) => i !== index),
    };
    setFormData(newFormData);
    checkFormValidity(newFormData);
  };
  const handleCaracteristicaAdd = () => {
    setFormData((prev) => ({
      ...prev,
      restricoesRoteirizacaoVeiculo: {
        ...prev.restricoesRoteirizacaoVeiculo,
        caracteristicasEspeciaisVeiculo: [
          ...prev.restricoesRoteirizacaoVeiculo.caracteristicasEspeciaisVeiculo,
          "",
        ],
      },
    }));
  };

  const handleCaracteristicaChange = (index: number, value: string) => {
    setFormData((prev) => ({
      ...prev,
      restricoesRoteirizacaoVeiculo: {
        ...prev.restricoesRoteirizacaoVeiculo,
        caracteristicasEspeciaisVeiculo:
          prev.restricoesRoteirizacaoVeiculo.caracteristicasEspeciaisVeiculo.map(
            (car, i) => (i === index ? value : car)
          ),
      },
    }));
  };

  const handleCaracteristicaRemove = (index: number) => {
    setFormData((prev) => ({
      ...prev,
      restricoesRoteirizacaoVeiculo: {
        ...prev.restricoesRoteirizacaoVeiculo,
        caracteristicasEspeciaisVeiculo:
          prev.restricoesRoteirizacaoVeiculo.caracteristicasEspeciaisVeiculo.filter(
            (_, i) => i !== index
          ),
      },
    }));
  };

  const handleIntervaloAdd = () => {
    setFormData((prev) => ({
      ...prev,
      restricoesRoteirizacaoVeiculo: {
        ...prev.restricoesRoteirizacaoVeiculo,
        intervalosParaPerfil: [
          ...prev.restricoesRoteirizacaoVeiculo.intervalosParaPerfil,
          {
            tempoIntervalo: 0,
            nomeIntervalo: "",
            tipoIntervalo: 1,
            prestadorVeiculoRestricaoIntervaloPerfilHoras: [
              {
                dataInicio: "",
                dataFim: "",
              },
            ],
          },
        ],
      },
    }));
  };

  const handleIntervaloRemove = (intervaloIndex: number) => {
    setFormData((prev) => ({
      ...prev,
      restricoesRoteirizacaoVeiculo: {
        ...prev.restricoesRoteirizacaoVeiculo,
        intervalosParaPerfil:
          prev.restricoesRoteirizacaoVeiculo.intervalosParaPerfil.filter(
            (_, index) => index !== intervaloIndex
          ),
      },
    }));
  };

  const handleIntervaloChange = (
    intervaloIndex: number,
    field: string,
    value: string | number
  ) => {
    setFormData((prev) => ({
      ...prev,
      restricoesRoteirizacaoVeiculo: {
        ...prev.restricoesRoteirizacaoVeiculo,
        intervalosParaPerfil:
          prev.restricoesRoteirizacaoVeiculo.intervalosParaPerfil.map(
            (intervalo, index) => {
              if (index === intervaloIndex) {
                return {
                  ...intervalo,
                  [field]:
                    field === "tempoIntervalo"
                      ? handleIntegerInput(value as string)
                      : value,
                };
              }
              return intervalo;
            }
          ),
      },
    }));
  };
  const handleHorarioAdd = (intervaloIndex: number) => {
    setFormData((prev) => ({
      ...prev,
      restricoesRoteirizacaoVeiculo: {
        ...prev.restricoesRoteirizacaoVeiculo,
        intervalosParaPerfil:
          prev.restricoesRoteirizacaoVeiculo.intervalosParaPerfil.map(
            (intervalo, index) => {
              if (index === intervaloIndex) {
                return {
                  ...intervalo,
                  prestadorVeiculoRestricaoIntervaloPerfilHoras: [
                    ...intervalo.prestadorVeiculoRestricaoIntervaloPerfilHoras,
                    {
                      dataInicio: "",
                      dataFim: "",
                    },
                  ],
                };
              }
              return intervalo;
            }
          ),
      },
    }));
  };

  const handleHorarioRemove = (
    intervaloIndex: number,
    horarioIndex: number
  ) => {
    setFormData((prev) => ({
      ...prev,
      restricoesRoteirizacaoVeiculo: {
        ...prev.restricoesRoteirizacaoVeiculo,
        intervalosParaPerfil:
          prev.restricoesRoteirizacaoVeiculo.intervalosParaPerfil.map(
            (intervalo, index) => {
              if (index === intervaloIndex) {
                return {
                  ...intervalo,
                  prestadorVeiculoRestricaoIntervaloPerfilHoras:
                    intervalo.prestadorVeiculoRestricaoIntervaloPerfilHoras.filter(
                      (_, idx) => idx !== horarioIndex
                    ),
                };
              }
              return intervalo;
            }
          ),
      },
    }));
  };

  const handleHorarioChange = (
    intervaloIndex: number,
    horarioIndex: number,
    field: string,
    value: string
  ) => {
    setFormData((prev) => ({
      ...prev,
      restricoesRoteirizacaoVeiculo: {
        ...prev.restricoesRoteirizacaoVeiculo,
        intervalosParaPerfil:
          prev.restricoesRoteirizacaoVeiculo.intervalosParaPerfil.map(
            (intervalo, index) => {
              if (index === intervaloIndex) {
                return {
                  ...intervalo,
                  prestadorVeiculoRestricaoIntervaloPerfilHoras:
                    intervalo.prestadorVeiculoRestricaoIntervaloPerfilHoras.map(
                      (horario, idx) => {
                        if (idx === horarioIndex) {
                          return {
                            ...horario,
                            [field]: value,
                          };
                        }
                        return horario;
                      }
                    ),
                };
              }
              return intervalo;
            }
          ),
      },
    }));
  };
  const handleGetPrestadores = async () => {
    setLoading(true);
    await getPrestadores().then(
      (res: any) => {
        const respSuccess = res?.data as
          | ResponseApi<MenuSelecao[]>
          | [] as MenuSelecao[];
        if (respSuccess) {
          const listaPrestadores =
            respSuccess.map((item: any) => {
              return { descricao: item.descricao, id: item.id.toString() };
            }) || [];
          setListaPrestadores([...[], ...listaPrestadores]);
          setLoading(false);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<MenuSelecao[]>;
        if (respErr) {
          setMsgAlertError(respErr.errors[0].Message);
          setOpenAlertError(true);
          setListaPrestadores([]);
          setLoading(false);
        }
      }
    );
  };

  const handleGetTipoVeiculo = async () => {
    setLoading(true);
    await getTipoVeiculo().then(
      (res: any) => {
        const respSuccess = res?.data as
          | ResponseApi<MenuSelecao[]>
          | [] as MenuSelecao[];
        if (respSuccess) {
          const listaTipoVeiculo =
            respSuccess.map((item: any) => {
              return { descricao: item.descricao, id: item.id.toString() };
            }) || [];
          setListaTipoVeiculo([...[], ...listaTipoVeiculo]);
          setLoading(false);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<MenuSelecao[]>;
        if (respErr) {
          setMsgAlertError(respErr.errors[0].Message);
          setOpenAlertError(true);
          setListaTipoVeiculo([]);
          setLoading(false);
        }
      }
    );
  };

  const handleGetTipoViagem = async () => {
    setLoading(true);
    await getTipoViagem().then(
      (res: any) => {
        const respSuccess = res?.data as
          | ResponseApi<MenuSelecao[]>
          | [] as MenuSelecao[];
        if (respSuccess) {
          const listaTipoViagem =
            respSuccess.map((item: any) => {
              return { descricao: item.descricao, id: item.id.toString() };
            }) || [];
          setListaTipoViagem([...[], ...listaTipoViagem]);
          setLoading(false);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<MenuSelecao[]>;
        if (respErr) {
          setMsgAlertError(respErr.errors[0].Message);
          setOpenAlertError(true);
          setListaTipoViagem([]);
          setLoading(false);
        }
      }
    );
  };

  const handleGetTipoCombustivel = async () => {
    setLoading(true);
    await getTipoCombustivel().then(
      (res: any) => {
        const respSuccess = res?.data as
          | ResponseApi<MenuSelecao[]>
          | [] as MenuSelecao[];
        if (respSuccess) {
          const listaTipoCombustivel =
            respSuccess.map((item: any) => {
              return { descricao: item.descricao, id: item.id.toString() };
            }) || [];
          setListaTipoCombustivel([...[], ...listaTipoCombustivel]);
          setLoading(false);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<MenuSelecao[]>;
        if (respErr) {
          setMsgAlertError(respErr.errors[0].Message);
          setOpenAlertError(true);
          setListaTipoCombustivel([]);
          setLoading(false);
        }
      }
    );
  };
  const handleGetTipoIntervalo = async () => {
    setLoading(true);
    await getTipoIntervalo().then(
      (res: any) => {
        const respSuccess = res?.data as
          | ResponseApi<MenuSelecao[]>
          | [] as MenuSelecao[];
        if (respSuccess) {
          const listaTipoInvtervalo =
            respSuccess.map((item: any) => {
              return { descricao: item.descricao, id: item.id.toString() };
            }) || [];
          setListaTipoIntervalo([...[], ...listaTipoInvtervalo]);
          setLoading(false);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<MenuSelecao[]>;
        if (respErr) {
          setMsgAlertError(respErr.errors[0].Message);
          setOpenAlertError(true);
          setListaTipoIntervalo([]);
          setLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    handleGetPrestadores();
    handleGetTipoVeiculo();
    handleGetTipoViagem();
    handleGetTipoCombustivel();
    handleGetTipoIntervalo();
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (isFormValid) {
        const retorno = (await postVeiculo(formData)) as AxiosResponse;
        if (retorno.status === 200) {
          setOpenAlertError(false);
          navigate("/ConsultarPrestadorVeiculo");
        } else {
          setOpenAlertError(true);
          setLoading(false);
        }
      }
      setLoading(false);
    } catch (e: any) {
      setOpenAlertError(true);
      setLoading(false);
      console.log(e);
      if (e?.response?.data?.detail)
        setMsgAlertError(e?.response?.data?.detail);
    }
  };
  function generateGuid(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  const handleIntegerInput = (value: string): number => {
    const intValue = parseInt(value, 10);
    return isNaN(intValue) ? 0 : intValue;
  };
  return (
    <SectionCustom
      id={"CriarPrestadorVeiculos"}
      className="CriarPrestadorVeiculos"
      titleHeader="Veículo"
      titleBreadcrumbItem="Cadastrar Veículo"
      rotaVoltar="/ConsultarPrestadorVeiculo"
      titleBreadcrumbItemBack="Consultar Veículos"
    >
      {openAlertError && (
        <div className="modalErro-Modelo">
          <Collapse onClose={setOpenAlertError} in={openAlertError}>
            <Alert
              severity="error"
              icon={<div />}
              variant="filled"
              sx={{
                mb: 2,
                bgcolor: "rgb(255, 35, 35, 0.7)",
                borderRadius: "15px",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "50px",
              }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="medium"
                  onClick={() => setOpenAlertError(false)}
                >
                  <CloseIcon
                    id={"ModalModeloCompativel-AlertCloseIcon"}
                    fontSize="inherit"
                    sx={{
                      color: "white",
                    }}
                  />
                </IconButton>
              }
            >
              <span
                style={{
                  color: "white",
                  fontSize: "16px",
                }}
                dangerouslySetInnerHTML={{ __html: msgAlertError }}
              />
            </Alert>
          </Collapse>
        </div>
      )}
      {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}
      <Box sx={{ p: 3 }}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">Cadastro de Veículo</Typography>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isFormValid}
              onClick={handleSubmit}
            >
              Salvar
            </Button>
          </Box>
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Grid container spacing={2}>
              {/* Informações Básicas */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Informações Básicas
                </Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Nome do Veículo"
                  name="nomeVeiculo"
                  value={formData.nomeVeiculo}
                  onChange={handleTextFieldChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3} key={generateGuid()}>
                <Autocomplete
                  options={listaPrestadores}
                  getOptionLabel={(option: MenuSelecao) =>
                    typeof option === "string" ? option : option.descricao
                  }
                  value={
                    listaPrestadores.find(
                      (p) => Number(p.id) === formData.idPrestador
                    ) || null
                  }
                  onChange={(event, newValue: MenuSelecao | null) => {
                    const simulatedEvent: SelectChangeEvent<number> = {
                      target: {
                        name: "idPrestador",
                        value: newValue ? Number(newValue.id) : 0,
                      },
                    } as SelectChangeEvent<number>;

                    handleSelectChange(simulatedEvent);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    Number(option.id) === Number(value.id)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Prestador"
                      required
                      error={formData.idPrestador === 0}
                    />
                  )}
                  filterOptions={(options, { inputValue }) => {
                    return options.filter((option) =>
                      option.descricao
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    );
                  }}
                  noOptionsText="Nenhum prestador encontrado"
                  loadingText="Carregando..."
                  clearOnBlur={false}
                  clearOnEscape={false}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Consumo de Combustível"
                  name="consumoDeCombustivel"
                  type="number"
                  inputProps={{ step: 1, min: 0 }}
                  value={formData.consumoDeCombustivel}
                  onChange={handleTextFieldChange}
                  required
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Custo do Combustível"
                  name="custoCombustivel"
                  type="number"
                  inputProps={{ step: 1, min: 0 }}
                  value={formData.custoCombustivel}
                  onChange={handleTextFieldChange}
                  required
                />
              </Grid>

              {/* Tipos */}
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Tipo de Veículo *</InputLabel>
                  <Select
                    name="tipoVeiculo"
                    value={formData.tipoVeiculo}
                    required
                    onChange={handleSelectChange}
                    label="Tipo de Veículo *"
                  >
                    {listaTipoVeiculo.map((c: MenuSelecao) => (
                      <MenuItem value={c.id}>{c.descricao}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Tipo de Viagem</InputLabel>
                  <Select
                    name="tipoViagem"
                    value={formData.tipoViagem}
                    onChange={handleSelectChange}
                    label="Tipo de Viagem"
                  >
                    {listaTipoViagem.map((c: MenuSelecao) => (
                      <MenuItem value={c.id}>{c.descricao}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Tipo de Combustível *</InputLabel>
                  <Select
                    name="tipoCombustivel"
                    value={formData.tipoCombustivel}
                    required
                    onChange={handleSelectChange}
                    label="Tipo de Combustível *"
                  >
                    {listaTipoCombustivel.map((c: MenuSelecao) => (
                      <MenuItem value={c.id}>{c.descricao}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Capacidades */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Capacidades
                </Typography>
                {formData.capacidades.map((capacidade, index) => (
                  <Grid
                    container
                    spacing={2}
                    key={index}
                    alignItems="center"
                    sx={{ mb: 2 }}
                  >
                    <Grid item xs={10} md={6}>
                      <TextField
                        fullWidth
                        label={`Capacidade ${index + 1}`}
                        type="number"
                        inputProps={{ step: 1, min: 0 }}
                        value={capacidade}
                        onChange={(e) =>
                          handleCapacidadeChange(index, e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => handleCapacidadeRemove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleCapacidadeAdd}
                  sx={{ mt: 1 }}
                >
                  Adicionar Capacidade
                </Button>
              </Grid>

              {/* Restrições de Roteirização */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Restrições de Roteirização
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Quantidade de Veículos Disponíveis *"
                  name="restricoesRoteirizacaoVeiculo.quantidadeVeiculosDisponiveis"
                  type="number"
                  inputProps={{ step: 1, min: 0 }}
                  value={
                    formData.restricoesRoteirizacaoVeiculo
                      .quantidadeVeiculosDisponiveis
                  }
                  onChange={handleTextFieldChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Quantidade Máxima de Paradas"
                  name="restricoesRoteirizacaoVeiculo.quantidadeMaximaParadas"
                  type="number"
                  inputProps={{ step: 1, min: 0 }}
                  value={
                    formData.restricoesRoteirizacaoVeiculo
                      .quantidadeMaximaParadas
                  }
                  onChange={handleTextFieldChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Quantidade Máxima de Serviços"
                  name="restricoesRoteirizacaoVeiculo.quantidadeMaximaServicos"
                  type="number"
                  inputProps={{ step: 1, min: 0 }}
                  value={
                    formData.restricoesRoteirizacaoVeiculo
                      .quantidadeMaximaServicos
                  }
                  onChange={handleTextFieldChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Número Máximo de Horas na Rota"
                  name="restricoesRoteirizacaoVeiculo.numeroMaximoHorasRota"
                  type="number"
                  inputProps={{ step: 1, min: 0 }}
                  value={
                    formData.restricoesRoteirizacaoVeiculo.numeroMaximoHorasRota
                  }
                  onChange={handleTextFieldChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Distância Máxima Percorrida na Rota"
                  name="restricoesRoteirizacaoVeiculo.distanciaMaximaPercoridaRota"
                  type="number"
                  inputProps={{ step: 1, min: 0 }}
                  value={
                    formData.restricoesRoteirizacaoVeiculo
                      .distanciaMaximaPercoridaRota
                  }
                  onChange={handleTextFieldChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Distância Máxima Entre Paradas"
                  name="restricoesRoteirizacaoVeiculo.distanciaMaximaEntreUmaParadaOutra"
                  type="number"
                  inputProps={{ step: 1, min: 0 }}
                  value={
                    formData.restricoesRoteirizacaoVeiculo
                      .distanciaMaximaEntreUmaParadaOutra
                  }
                  onChange={handleTextFieldChange}
                />
              </Grid>

              {/* Características Especiais */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Características do Veículo
                </Typography>
                {formData.restricoesRoteirizacaoVeiculo.caracteristicasEspeciaisVeiculo.map(
                  (caracteristica, index) => (
                    <Grid
                      container
                      spacing={2}
                      key={index}
                      alignItems="center"
                      sx={{ mb: 2 }}
                    >
                      <Grid item xs={10}>
                        <TextField
                          fullWidth
                          label={`Característica ${index + 1}`}
                          value={caracteristica}
                          onChange={(e) =>
                            handleCaracteristicaChange(index, e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() => handleCaracteristicaRemove(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )
                )}
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleCaracteristicaAdd}
                  sx={{ mt: 1 }}
                >
                  Adicionar Característica
                </Button>
              </Grid>

              {/* Intervalos para Perfil */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                  Intervalos
                </Typography>
                {formData.restricoesRoteirizacaoVeiculo.intervalosParaPerfil.map(
                  (intervalo, intervaloIndex) => (
                    <Paper key={intervaloIndex} sx={{ p: 2, mb: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" gutterBottom>
                            Intervalo {intervaloIndex + 1}
                            <IconButton
                              onClick={() =>
                                handleIntervaloRemove(intervaloIndex)
                              }
                              sx={{ float: "right" }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <TextField
                            fullWidth
                            label="Tempo do Intervalo"
                            type="number"
                            inputProps={{ step: 1, min: 0 }}
                            value={intervalo.tempoIntervalo}
                            onChange={(e) =>
                              handleIntervaloChange(
                                intervaloIndex,
                                "tempoIntervalo",
                                Number(e.target.value)
                              )
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <TextField
                            fullWidth
                            label="Nome do Intervalo"
                            value={intervalo.nomeIntervalo}
                            onChange={(e) =>
                              handleIntervaloChange(
                                intervaloIndex,
                                "nomeIntervalo",
                                e.target.value
                              )
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <InputLabel>Tipo de Intervalo</InputLabel>
                            <Select
                              value={intervalo.tipoIntervalo}
                              label="Tipo de Intervalo"
                              onChange={(e) =>
                                handleIntervaloChange(
                                  intervaloIndex,
                                  "tipoIntervalo",
                                  Number(e.target.value)
                                )
                              }
                            >
                              {listaTipoIntervalo.map((c: MenuSelecao) => (
                                <MenuItem value={c.id}>{c.descricao}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="subtitle2" gutterBottom>
                            Horários
                            <Button
                              startIcon={<AddIcon />}
                              size="small"
                              onClick={() => handleHorarioAdd(intervaloIndex)}
                              sx={{ ml: 2 }}
                            >
                              Adicionar Horário
                            </Button>
                          </Typography>

                          {intervalo.prestadorVeiculoRestricaoIntervaloPerfilHoras.map(
                            (horario, horarioIndex) => (
                              <Grid
                                container
                                spacing={2}
                                key={horarioIndex}
                                sx={{ mb: 1 }}
                              >
                                <Grid item xs={5}>
                                  <TextField
                                    fullWidth
                                    label="Data/Hora Início"
                                    type="datetime-local"
                                    value={horario.dataInicio}
                                    onChange={(e) =>
                                      handleHorarioChange(
                                        intervaloIndex,
                                        horarioIndex,
                                        "dataInicio",
                                        e.target.value
                                      )
                                    }
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </Grid>
                                <Grid item xs={5}>
                                  <TextField
                                    fullWidth
                                    label="Data/Hora Fim"
                                    type="datetime-local"
                                    value={horario.dataFim}
                                    onChange={(e) =>
                                      handleHorarioChange(
                                        intervaloIndex,
                                        horarioIndex,
                                        "dataFim",
                                        e.target.value
                                      )
                                    }
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <IconButton
                                    onClick={() =>
                                      handleHorarioRemove(
                                        intervaloIndex,
                                        horarioIndex
                                      )
                                    }
                                    sx={{ mt: 1 }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  )
                )}

                <Button
                  startIcon={<AddIcon />}
                  onClick={handleIntervaloAdd}
                  variant="outlined"
                  sx={{ mt: 1 }}
                >
                  Adicionar Intervalo
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>
    </SectionCustom>
  );
};

export default CriarPrestadorVeiculo;
