import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 650px;
  background-color: #ffffff;
  padding: 0px 40px 30px 40px;
  box-sizing: border-box;
  & .MuiAlert-action {
    margin: unset;
  }
`;
