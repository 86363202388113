import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import './estoqueTecnico.scss';
import { exportCustodias, getCustodias, getDefeitos, getStatusCustodias, getUsuariosOrdemServico } from '../services/services';
import { ICustodia, IDefeitos, IGETStatusCustodiaResponse, IGETUsuarioResponse, IPostCustodia, IPostCustodiaResponse } from '../types';
import { getPrestadores } from '../services/services';
import Pagination from "../../../components/pagination/paginacao";
import Button from '../../../components/Button/styles';
import Header from '../../../components/header/header';
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import { getListaTecnicosByLotePrestador } from '../../encaminharOS/services/services';
import { AuthContext } from '../../../contexts/auth';
import { HomeContext } from '../../../contexts/homeContext';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { ISeletorMultiploId } from '../../farol/types';
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { IAutocompleteValue } from '../../../components/autocompleteMultiple/types';
import { ITecnicos } from '../../../contexts/types/encaminharTypes';
import DatePicker from "../../../components/datePicker/datePicker";
import { getContratantesNovo } from '../../farol/services';
import { ResponseApi } from '../../../contexts/types/responsesHttp';
import { IContratantesDTO } from '../../contratante/types';
import AlertMessage from '../../../components/AlertMessage/alertMessage';
import { createErrorMsg } from '../../../services/api';
import CustodiaTable from './custodiasTable';
import EstoqueTecnicoModal from './estoqueTecnicoModal';
import AuditCustodiaModal from './auditCustodiaModal';
import Input from '../../../components/Input/Input';
import EditarCustodiaModal from './editarCustodiaModal';

const EstoqueTecnico = () => {
  const navigate = useNavigate();
  const { setMenuLateral } = useContext(HomeContext);
  const { user, funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Estoque do Técnico", funcionalidade);

  const [listaPrestadores, setListaPrestadores] = useState<any[]>([]);
  const [listaTecnicos, setListaTecnicos] = useState<any[]>([]);
  const [listaContratante, setListaContratante] = useState<IAutocompleteValue>([]);
  const [listaStatus, setListaStatus] = useState<IAutocompleteValue>([]);
  const [listaUsuarios, setListaUsuarios] = useState<IAutocompleteValue>([]);
  const [listaTipoData] = useState<IAutocompleteValue>([
    { name: 'Data de envio para o técnico', id: 'dataEnvioTecnico' },
    { name: 'Data de correção de envio', id: 'dataCorrecaoEnvio' },
    { name: 'Data de recebimento pelo técnico', id: 'dataRecebimentoTecnico' },
    { name: 'Data de baixa da OS do equipamento', id: 'dataBaixaOSEquipamento' },
    { name: 'Data de entrega ao P.A.', id: 'dataEntregaPA' },
    { name: 'Data de criação da custódia', id: 'dataCriacao' },
    { name: 'Data da última modificação da custódia', id: 'dataModificacao' },
  ]);
  const [selectedPrestadores, setSelectedPrestadores] = useState<any[]>([]);
  const [tecnicoSelecionado, setTecnicoSelecionado] = useState<any[]>([]);
  const [filtroContratante, setFiltroContratante] = useState<any[]>([]);
  const [statusSelecionado, setStatusSelecionado] = useState<IAutocompleteValue>([]);
  const [numeroSerie, setNumeroSerie] = useState<string>("");
  const [tipoDataSelecionado, setTipoDataSelecionado] = useState<IAutocompleteValue>([]);
  const [dataInicio, setDataInicio] = useState<any | null>(null);
  const [dataFim, setDataFim] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [defeitoList, setDefeitoList] = useState([]);
  const [estoqueTecnico, setEstoqueTecnico] = useState<ICustodia[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [qtdeRegistros, setQtdeRegistros] = useState<number>(0);
  const [listaOrdenarValor] = useState<any>({
    "Editar": "editar",
    "ID": "id",
    "RegistroEditado": "registroEditado",
    "Auditoria": "auditoria",
    "Contratante": "contratante",
    "StatusDeCustodia": "statusCustodia",
    "Prestador": "prestador",
    "NomeDoTecnico": "tecnico",
    "NumeroDeSerie": "numeroSerie",
    "ModeloDoEquipamento": "modelo",
    "ResponsavelPeloEnvioDoEquipamento": "responsavelEnvio",
    "DataEHoraDeEnvioParaOTecnico": "dataEnvio",
    "DataEHoraDeRecebimentoPeloTecnico": "dataRecebimento",
    "DataDeBaixaDaOSDoEquipamento": "dataBaixaOS",
    "DataDeEntregaAoPA": "dataEntregaPA",
    "NomeDoResponsavelPelaConfirmacaoDeRecebimentoNoPA": "nomeResponsavelRecebimentoPA",
  });
  const [ordenacao, setOrdenacao] = useState<string>("");
  const [ordenacaoReverse, setOrdenacaoReverse] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [custodiaEditModal, setCustodiaEditModal] = useState<ICustodia | null>(null);
  const [isModalAuditOpen, setIsModalAuditOpen] = useState(false);
  const [custodiaAuditModal, setCustodiaAuditModal] = useState<ICustodia | null>(null);

  const getItemByID = (ID: number, list: any[]) => {
    return [...list].find((item) => item.id === ID)!;
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  
  const handleCloseModalEdit = () => {
    setCustodiaEditModal(null);
    setIsModalEditOpen(false);
  };
 
  const handleClickEdit = (custodia: any) => {
    setCustodiaEditModal(getItemByID(custodia.id, estoqueTecnico));
    setIsModalEditOpen(true);
  };

  const handleCloseModalAudit = () => {
    setCustodiaAuditModal(null);
    setIsModalAuditOpen(false);
  };
 
  const handleClickAudit = (custodia: any) => {
    setCustodiaAuditModal(getItemByID(custodia.id, estoqueTecnico));
    setIsModalAuditOpen(true);
  };

  const handleChangeTechnician = (technician: any[]) => {
    setTecnicoSelecionado(technician)
  };

  const handleChangeProvider = (prestadores: any[]) => {
    setSelectedPrestadores(prestadores);
  };

  const handleGetUsuarios = async () => {
    try {
      const { data: { usuarios } }: { data: IGETUsuarioResponse } = await getUsuariosOrdemServico(
        { Ativo: true }
      ) as AxiosResponse;

      let listUsuarios: IAutocompleteValue = [];

      if (!!usuarios && !!usuarios.length) {
        listUsuarios = usuarios.map((m) => ({ name: m.nome, id: m.id?.toString() }))
          .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      };

      setListaUsuarios(listUsuarios);

    } catch (e) {
      setListaUsuarios([]);
    }
  };

  const handleGetStatusCustodias = async () => {
    try {
      const { data: { custodiaStatus } }: { data: { custodiaStatus: IGETStatusCustodiaResponse } } = await getStatusCustodias(
        { Ativo: true }
      ) as AxiosResponse;

      let listStatusCustodias: IAutocompleteValue = [];

      if (!!custodiaStatus && !!custodiaStatus.length) {
        listStatusCustodias = custodiaStatus.map((m) => ({ name: m.nome, id: m.id?.toString() }))
          .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      };

      setListaStatus(listStatusCustodias);

    } catch (e) {
      setListaStatus([]);
    }
  };

  const getProvider = async () => {
    try {
      const { data } = await getPrestadores() as AxiosResponse;

      let prestadores = [];

      if (!!data && !!data.length) {
        prestadores = data.filter((p: any) => !!p.ativo).map((m: any) => ({ ...m, name: m.nomePrestador, id: m.id?.toString() }))
          .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      };

      setListaPrestadores(filtraPrestadoresDoUsuario(prestadores));

    } catch (e) {
      setListaPrestadores([]);
    }
  };

  const filtraPrestadoresDoUsuario = (prestadores: any) => {
    let options: any[] = [...prestadores];

    if (!!user?.prestadores && !!user.prestadores.length) {
      options = options.filter((o: any) => user.prestadores.includes(Number(o.id)));

      /**Auto preenche o filtro prestadores */
      if (user.prestadores.length === 1) {
        setSelectedPrestadores(options);
      }
    }

    return options;
  };

  const requestListaTecnicosByLotePrestador = async () => {
    let listAllPrestadores: number[] = [];

    if (!!selectedPrestadores.length) {
      listAllPrestadores = selectedPrestadores.map(p => parseInt(p.id));
    }

    const tecnicos = await requestTecnicosByLotePrestador(listAllPrestadores) as ITecnicos[];
    setListaTecnicos(tecnicos.map(p => ({ name: p?.nome ?? "", id: p?.idTecnico?.toString() ?? "" })));
  };

  const requestTecnicosByLotePrestador = async (ids: number[]) => {
    try {
      if (!!ids.length) {
        let retorno = await getListaTecnicosByLotePrestador(ids) as AxiosResponse;
        return retorno?.data ?? [];
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };

  const handleButtonSearch = () => {
    currentPage !== 1 ? setCurrentPage(1) : fetchCustodias();
  };

  const fetchCustodias = async () => {
    setLoading(true);

    try {
      const params: IPostCustodia = {
        ...(!!selectedPrestadores.length ? { prestadores: selectedPrestadores.map(p => Number(p.id)) } : {}),
        ...(!!tecnicoSelecionado.length ? { tecnicos: tecnicoSelecionado.map(p => Number(p.id)) } : {}),
        ...(!!filtroContratante.length ? { contratantes: filtroContratante.map(p => Number(p.id)) } : {}),
        ...(!!statusSelecionado.length ? { custodiaStatus: statusSelecionado.map(p => Number(p.id)) } : {}),
        ...(!!numeroSerie.length ? { numeroSerie: numeroSerie } : {}),
        ...(!!tipoDataSelecionado.length ? { tipoData: tipoDataSelecionado[0].id } : {}),
        ...(!!tipoDataSelecionado.length && !!dataInicio ? { dataInicio: dataInicio.format("YYYY-MM-DDTHH:mm:ss") } : {}),
        ...(!!tipoDataSelecionado.length && !!dataFim ? { dataFim: dataFim.format("YYYY-MM-DDTHH:mm:ss") } : {}),
        pageNumber: currentPage,
        pageSize: pageSize
      };

      const { data }: { data: IPostCustodiaResponse } = await getCustodias(params) as AxiosResponse;
      
      setLoading(false);
      setEstoqueTecnico(data?.items ?? []);
      setQtdeRegistros(data?.totalCount ?? 0);

    } catch (error: any) {
      setEstoqueTecnico([]);
      setLoading(false);
      setMsgAlert(createErrorMsg(error, 'Nenhuma custódia encontrada'));
      setOpenAlert(true);
      setTipoMsgAlert('error');
    }
  };

  const handleExportCSV = async () => {
    setLoading(true);

    try {
      const params: IPostCustodia = {
        ...(!!selectedPrestadores.length ? { prestadores: selectedPrestadores.map(p => Number(p.id)) } : {}),
        ...(!!tecnicoSelecionado.length ? { tecnicos: tecnicoSelecionado.map(p => Number(p.id)) } : {}),
        ...(!!filtroContratante.length ? { contratantes: filtroContratante.map(p => Number(p.id)) } : {}),
        ...(!!statusSelecionado.length ? { custodiaStatus: statusSelecionado.map(p => Number(p.id)) } : {}),
        ...(!!tipoDataSelecionado.length ? { tipoData: tipoDataSelecionado[0].id } : {}),
        ...(!!tipoDataSelecionado.length && !!dataInicio ? { dataInicio: dataInicio.format("YYYY-MM-DDTHH:mm:ss") } : {}),
        ...(!!tipoDataSelecionado.length && !!dataFim ? { dataFim: dataFim.format("YYYY-MM-DDTHH:mm:ss") } : {}),
      };

      const { data } = await exportCustodias(params) as AxiosResponse;

      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Custodias.csv');
      document.body.appendChild(link);
      link.click();

    } catch (error: any) {
      setLoading(false);
      setOpenAlert(true);
      setTipoMsgAlert('error');
      setMsgAlert(createErrorMsg(error, 'Erro ao exportar dados.'));
    }
  };

  const handleGetDefeitos = async () => {
    try {
      const { data } = await getDefeitos() as AxiosResponse;

      setDefeitoList(data.defeitos.map((item: IDefeitos) =>
        ({ name: item.codigoDefeito, id: item.id.toString() }))
        .sort((a: ISeletorMultiploId, b: ISeletorMultiploId) =>
          (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
    } catch (e) {
      setDefeitoList([]);
    };
  };

  const localGetContratantes = async () => {
    return await getContratantesNovo()
      .then((res: any) => {
        const respSuccess = res?.data as ResponseApi<IContratantesDTO[]> | null;

        if (respSuccess) {
          const listaContratante = respSuccess.data?.map((item: any) => { return { name: item.nome, id: item.id_Oppay?.toString() ?? "" } }) || [];
          setListaContratante(filtraContratantesDoUsuario([...[], ...listaContratante]));
        };

      }, err => {
        const respErr = err?.response?.data as ResponseApi<IContratantesDTO[]>;
        if (respErr) setListaContratante([]);
      });
  };

  const filtraContratantesDoUsuario = (contratantes: IAutocompleteValue) => {
    let options = [...contratantes];

    if (!!user?.contratanteOrdemServico) {
      options = options.filter((o: any) => Number(o.id) === user.contratanteOrdemServico);

      setFiltroContratante(options);
    }

    return options;
  };

  const dadosFormatados = useMemo(() => {
    let dados = [...estoqueTecnico];

    /** Formata campos para tabela */
    //@ts-ignore
    dados = dados.map((item, index) => {
      return {
        id: item.id,
        //@ts-ignore
        registroEditado: !!item.motivoAlteracao ? 'Sim' : 'Não',
        contratante: item.contratante ? item.contratante : 'N/A',
        statusCustodia: item.custodiaStatus ? item.custodiaStatus : 'N/A',
        prestador: item.prestador ? item.prestador : 'N/A',
        tecnico: item.tecnico ? item.tecnico : 'N/A',
        numeroSerie: item.numeroSerie ? item.numeroSerie : 'N/A',
        modelo: item.modelo ? item.modelo : 'N/A',
        responsavelEnvio: item.responsavelEntregaEquipamentoAoTecnico ? item.responsavelEntregaEquipamentoAoTecnico : 'N/A',
        dataEnvio: (!item.dataEnvioTecnico || item.dataEnvioTecnico === "0001-01-01T00:00:00") ? 'N/A' : item.dataEnvioTecnico,
        dataRecebimento: (!item.dataRecebimentoTecnico || item.dataRecebimentoTecnico === "0001-01-01T00:00:00") ? 'N/A' : item.dataRecebimentoTecnico,
        dataBaixaOS: (!item.dataBaixaOSEquipamento || item.dataBaixaOSEquipamento === "0001-01-01T00:00:00") ? 'N/A' : item.dataBaixaOSEquipamento,
        dataEntregaPA: (!item.dataEntregaPA || item.dataEntregaPA === "0001-01-01T00:00:00") ? 'N/A' : item.dataEntregaPA,
        nomeResponsavelRecebimentoPA: item.responsavelConfirmacaoRecebimentoPA ? item.responsavelConfirmacaoRecebimentoPA : 'N/A',
      };
    });

    if (ordenacao !== "") {
      //@ts-ignore
      dados = _.sortBy(dados, [
        listaOrdenarValor[ordenacao],
      ]);
    }

    if (!!ordenacaoReverse) dados = dados.reverse();

    return dados;
  }, [estoqueTecnico, ordenacao, ordenacaoReverse, listaOrdenarValor, currentPage, pageSize]);


  const handleClickOrdenar = (sort: string) => {
    if (sort === ordenacao) {

      if (!ordenacaoReverse) {
        setOrdenacaoReverse(true);
      } else {
        setOrdenacao("");
        setOrdenacaoReverse(false);
      }
    } else {
      setOrdenacao(sort);
      setOrdenacaoReverse(false);
    }
  };

  const activeArrowUP = (sort: string) => {
    if (sort === ordenacao) {
      return ordenacaoReverse;
    } else {
      return false;
    }
  };

  const activeArrowDown = (sort: string) => {
    if (sort === ordenacao) {
      return !ordenacaoReverse;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getProvider();
    localGetContratantes();
    handleGetStatusCustodias();
    handleGetDefeitos();
    if (!!profileHasPermission("Editar registro")) {
      handleGetUsuarios();
    }
  }, []);

  useEffect(() => {
    requestListaTecnicosByLotePrestador();
    setTecnicoSelecionado([]);
  }, [selectedPrestadores]);

  const firstUpdate = useRef(true);
  
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    fetchCustodias();
  }, [currentPage, pageSize]);

  return (
    <section className="TechnicianStock">
      {loading && <FullscreenLoading />}

      <Header setMenuLateral={() => setMenuLateral(true)} title="Consulta de custódias" />

      <Breadcrumb>
        <BreadcrumbItem>Consulta de custódias</BreadcrumbItem>
      </Breadcrumb>

      {!!isModalOpen &&
        <EstoqueTecnicoModal
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          setLoading={setLoading}
          listPrestadores={listaPrestadores}
          prestadoresSelecionados={selectedPrestadores}
          listTecnicos={listaTecnicos}
          tecnicosSelecionado={tecnicoSelecionado}
          defeitoList={defeitoList}
        />
      }
      
      {!!isModalEditOpen &&
        <EditarCustodiaModal
          isModalOpen={isModalEditOpen}
          handleCloseModal={handleCloseModalEdit}
          custodia={custodiaEditModal}
          listaPrestadores={listaPrestadores}
          listaStatus={listaStatus}
          listaUsuarios={listaUsuarios}
        />
      }
      
      {!!isModalAuditOpen &&
        <AuditCustodiaModal
          isModalOpen={isModalAuditOpen}
          handleCloseModal={handleCloseModalAudit}
          custodia={custodiaAuditModal}
        />
      }

      <div className='grid1'>
        <AutocompleteMultiple
          label={"Prestador"}
          placeholder={""}
          noOptionsText={"Nenhum prestador encontrado"}
          id="multiple-checkbox-Prestadores-Estoque"
          options={listaPrestadores}
          value={selectedPrestadores}
          onChange={handleChangeProvider}
          disabled={(!!user?.prestadores && user.prestadores.length === 1)}
          multiple={true}
          dimension='sm'
          limitTags={2}
          showCheckbox
          //allSelected
        />
        <AutocompleteMultiple
          label={"Técnico"}
          placeholder={""}
          noOptionsText={"Nenhum técnico encontrado"}
          id="multiple-checkbox-tecnico-Estoque"
          options={listaTecnicos}
          value={tecnicoSelecionado}
          onChange={handleChangeTechnician}
          disabled={false}
          multiple={true}
          dimension='sm'
          limitTags={2}
          showCheckbox
          allSelected
        />
        <AutocompleteMultiple
          label={"Contratante"}
          placeholder={""}
          noOptionsText={"Nenhum contratante encontrado"}
          id="multiple-checkbox-contratante-Estoque"
          options={listaContratante}
          value={filtroContratante}
          onChange={setFiltroContratante}
          disabled={!!user?.contratanteOrdemServico}
          multiple={true}
          dimension='sm'
          limitTags={2}
          showCheckbox
          allSelected
        />
      </div>

      <div className='grid2'>
        <AutocompleteMultiple
          label={"Status"}
          placeholder={""}
          noOptionsText={"Nenhum status encontrado"}
          id="multiple-checkbox-Prestadores-Estoque"
          options={listaStatus}
          value={statusSelecionado}
          onChange={setStatusSelecionado}
          multiple={true}
          dimension='sm'
          limitTags={2}
          showCheckbox
          allSelected
        />
        <Input
          label={"Número de série"}
          placeholder={""}
          id="input-numeroSerie"
          value={numeroSerie}
          onChange={(e: any) => setNumeroSerie(e.target.value)}
          multiple={true}
          dimension='sm'
        />
        <AutocompleteMultiple
          label={"Campo Data"}
          placeholder={""}
          noOptionsText={"Nenhum tipo de data encontrado"}
          id="multiple-checkbox-Prestadores-Estoque"
          options={listaTipoData}
          value={tipoDataSelecionado}
          onChange={setTipoDataSelecionado}
          multiple={false}
          dimension='sm'
          limitTags={2}
          showCheckbox
        />
        <DatePicker
          label={"Data inicial"}
          id="DatePicker-dataInicio"
          placeholder="Início"
          value={dataInicio}
          onChange={setDataInicio}
          dimension='sm'
          maxDate={dataFim}
          disabled={_.isEmpty(tipoDataSelecionado)}
        />
        <DatePicker
          label={"Data final"}
          id="DatePicker-dataFim"
          placeholder="Fim"
          value={dataFim}
          onChange={setDataFim}
          dimension='sm'
          minDate={dataInicio}
          disabled={_.isEmpty(tipoDataSelecionado)}
        />
      </div>

      <div className='grid3'>
        <Button
          id={"technicianStockBackButton"}
          dimension='sm'
          onClick={() => navigate(-1)}
          variant='secondary'
        >
          Voltar
        </Button>

        {!!profileHasPermission("Enviar Equipamento") ?
          <Button
            id={"technicianStockEnviarButton"}
            dimension='sm'
            onClick={() => navigate('/EnviarEquipamento')}
            variant='secondary'
          >
            Enviar equipamento
          </Button>
          :
          <Button
            id={"technicianStockEnviarButton"}
            dimension='sm'
            type="button"
            variant='secondary'
            style={{ cursor: 'not-allowed', opacity: 0.5 }}
            disabled={true}>
            Enviar equipamento
          </Button>
        }

        {!!profileHasPermission("Devolver Equipamento") ?
          <Button
            id={"technicianStockDevolverButton"}
            dimension='sm'
            onClick={() => setIsModalOpen(true)}
            variant='secondary'
          >
            Devolver equipamento
          </Button>
          :
          <Button
            id={"technicianStockDevolverButton"}
            dimension='sm'
            type="button"
            variant='secondary'
            style={{ cursor: 'not-allowed', opacity: 0.5 }}
            disabled={true}>
            Devolver equipamento
          </Button>
        }

        {!!profileHasPermission("Exportar CSV") ?
          <Button
            id={"technicianStockExportButton"}
            dimension='sm'
            onClick={handleExportCSV}
            variant='primary'
            disabled={_.isEmpty(estoqueTecnico)}
          >
            Exportar
          </Button>
          :
          <Button
            id={"technicianStockExportButton"}
            dimension='sm'
            type="button"
            variant='primary'
            style={{ cursor: 'not-allowed', opacity: 0.5 }}
            disabled={true}>
            Exportar
          </Button>
        }
        
        <Button
          id={"technicianStockSearchButton"}
          dimension='sm'
          variant="primary"
          className='searchButton'
          onClick={handleButtonSearch}
        >
          Pesquisar
        </Button>
      </div>

      <AlertMessage
        isOpenAlert={openAlert}
        setOpenAlert={setOpenAlert}
        alertType={tipomsgAlert}
        msgAlert={msgAlert}
      />

      {!_.isEmpty(dadosFormatados) &&
        <>
          <CustodiaTable
            handleClickOrdenar={handleClickOrdenar}
            activeArrowUP={activeArrowUP}
            activeArrowDown={activeArrowDown}
            ordenacao={ordenacao ?? ''}
            dataList={dadosFormatados}
            allowEdit={!!profileHasPermission("Editar registro")}
            handleClickEdit={handleClickEdit}
            allowSeeAudit={!!profileHasPermission("Auditoria")}
            handleClickAudit={handleClickAudit}
          />

          <Pagination
            arrQtdPageSelector={[20, 50, 100]}
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={qtdeRegistros}
            pageSize={pageSize}
            setPageSize={(value: string) => setPageSize(Number(value))}
            onPageChange={(page: number) => setCurrentPage(Number(page))}
            showLabel={false}
          />
        </>
      }
    </section>
  )
};

export default EstoqueTecnico;
