import { useState } from 'react'
import Button from '../../../components/Button/styles';
import { IGetRotasOtimizadas, IInitialValuesForm, INovaRoteirizacaoRotasOtimizadas } from '../types/types';
import { postRotasOtimizadasNovaRoteirizacao } from '../services/services';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface IButtonModalNovaRoteirizacao {
  values: IInitialValuesForm;
  currentPage: number;
  pageSize: number;
  setOpenAlertError: Function;
  setMsgAlertError: Function;
  setAlertType: Function;
  setLoading: Function;
  reload: Function;
}

const ButtonModalNovaRoteirizacao = ({ values,
  currentPage,
  pageSize,
  setOpenAlertError,
  setMsgAlertError,
  setAlertType,
  setLoading,
  reload,
}: IButtonModalNovaRoteirizacao) => {

  const [isOpen, setIsOpen] = useState(false);
  const [textModal, setTextModal] = useState("");

  const handleNovaRouteirizacao = async (
    values: IInitialValuesForm,
    ehPrimeiraSolicitacao: boolean,
    deveRoteirizarTodas: boolean) => {
    setLoading(true);

    if (values.prestadoresFormik.length == 0) {
      setOpenAlertError(true);
      setAlertType("error");
      setMsgAlertError("Pelo menos um prestador deve ser selecionado");
      setLoading(false);
      return;
    }

    setIsOpen(false);

    try {
      const params = {
        dataInicial: values.dataInicialFormik,
        dataFinal: values.dataFinalFormik != "" ? values.dataFinalFormik : undefined,
        idPrestadores: values.prestadoresFormik.map(m => Number(m.id)),
        idTecnicos: values.tecnicosFormik.map(m => Number(m.id)),
        status: values.statusFormik.map(m => Number(m.id)),
        pageNumber: currentPage,
        pageSize: pageSize,
        primeiraSolicitacao: ehPrimeiraSolicitacao,
        deveRoteirizarTodas: deveRoteirizarTodas
      } as INovaRoteirizacaoRotasOtimizadas;
      await postRotasOtimizadasNovaRoteirizacao(params);

      setAlertType("success");
      setOpenAlertError(true);
      setMsgAlertError("OS's roteirizadas com sucesso!");

      reload();
    } catch (erro: any) {

      setAlertType("error");

      const msgErr = [
        "Não existe items no status em roteirização.",
        "Não foi possível encontrar OS's para roteirização.",
        "Erro ao roteirizar novamente.",
        "Falha ao enviar roteirizações.",
        "OS's não encontradas no sistema."
      ]
      if (msgErr.includes(erro.response.data)) {
        setOpenAlertError(true);
        setMsgAlertError(erro.response.data);
        setIsOpen(false);
      } else {
        if (typeof erro.response.data === 'object' || erro.response.data == "") {
          setOpenAlertError(true);
          setMsgAlertError("Erro ao roteirizar.");
        } else {
          setTextModal(erro.response.data);
          setIsOpen(true);
        }
      }
    }
    setLoading(false);
  }

  return (
    <>
      <Button
        variant="contained"
        dimension="sm"
        className='bt'
        type='button'
        onClick={() => handleNovaRouteirizacao(values, true, false)}
      >
        Nova Roteirização
      </Button>

      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minWidth: 600 }}
      >
        <DialogTitle>
          Erro ao gerar nova roteirização
          <IconButton
            aria-label="close"
            onClick={() => { setIsOpen(false) }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>
              {typeof textModal === 'object'
                ? ""
                : textModal}
            </span>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              handleNovaRouteirizacao(values, false, false);
            }}
            variant="secondary">
            Não
          </Button>
          <Button
            onClick={() => {
              handleNovaRouteirizacao(values, false, true);
            }}
            variant="primary"
            autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ButtonModalNovaRoteirizacao