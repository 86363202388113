import { IAutocompleteValueItem } from "../../../components/autocompleteMultiple/types";
import { IPaginationList } from "../../../core/types";
import { objToQueryString } from "../../../helpers/util";
import { apiDelete, apiPost, apiPut } from "../../../services/api";
import { apiGetGeneric, apiPostGeneric } from "../../../services/apiGeneric";
import { IGetRotasOtimizadas, INovaRoteirizacaoRotasOtimizadas, IRouteasyFiltroRoteirizacaoDTO, IRouteasyRoteirizacaoDTO, } from "../types/types";

const baseUrl = process.env.REACT_APP_API_ATENDIMENTO;
const baseUrlOrdemServico = process.env.REACT_APP_API_ORDEMSERVICO;

export const getRotasOtimizadasStatus = async (): Promise<IAutocompleteValueItem[]> =>
    await apiGetGeneric<IAutocompleteValueItem>(`${baseUrl}/Routeasy/RotasOtimizadas/status`);

export const getRotasOtimizadasParametro = async (idPrestador:number): Promise<boolean> =>
    await apiGetGeneric<boolean>(`${baseUrlOrdemServico}/parametro/roteirizacao-automatica-ativada/${idPrestador}`);

//Pesquisar
export const getRotasOtimizadas = async (params: IGetRotasOtimizadas): Promise<IPaginationList<IRouteasyRoteirizacaoDTO[]>> => {
    let query = objToQueryString(params);
    return await apiGetGeneric<IPaginationList<IRouteasyRoteirizacaoDTO[]>>(`${baseUrl}/Routeasy/RotasOtimizadas?${query}`);
}

export const getRotasOtimizadasFiltro = async (params: IGetRotasOtimizadas): Promise<IPaginationList<IRouteasyFiltroRoteirizacaoDTO[]>> => {
    let query = objToQueryString(params);
    return await apiGetGeneric<IPaginationList<IRouteasyFiltroRoteirizacaoDTO[]>>(`${baseUrl}/Routeasy/RotasOtimizadas/filtro?${query}`);
}

// Exportar
export const exportarRotasOtimizadasCSV = async (params: IGetRotasOtimizadas) =>
    await apiPost(`${baseUrl}/Routeasy/RotasOtimizadas/exportar`, params);

// Enviar A Campo
export const postRotasOtimizadasEnviarACampo = async (params: IGetRotasOtimizadas): Promise<any> =>
    await apiPostGeneric(`${baseUrl}/Encaminhamento/EnviarACampo`, params);

// NovaRoteirização
export const postRotasOtimizadasNovaRoteirizacao = async (params: INovaRoteirizacaoRotasOtimizadas): Promise<any> =>
    await apiPostGeneric(`${baseUrl}/Routeasy/RotasOtimizadas/novaRoteirizacao`, params);

// Enviar Em Campo (TABELA)
export const postRotasOtimizadasEnviarEmCampo = async (idTecnico: number, idRota: number): Promise<any> =>
    await apiPut(`${baseUrl}/Routeasy/RotasOtimizadas/EnviarEmCampo`, { idTecnico, idRota });

export const getRotasOtimizadasDeletar = async (id: number): Promise<any> =>
    await apiDelete(`${baseUrl}/Routeasy/RotasOtimizadas/${id}`);