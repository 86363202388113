import React, { useState } from 'react';
import { CircularProgress, Collapse, Table, TableCell, TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import _ from "lodash";

import { DeleteOutline } from "@mui/icons-material";
import * as Styles from "../../../components/table/styles";
import Button from '../../../components/Button/styles';
import { IRouteasyFiltroRotaDTO, IRouteasyFiltroRoteirizacaoDTO } from '../types/types';
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ERouteasyRoteirizacaoStatus } from '../types/constants';
import AutocompleteMultiple from '../../../components/autocompleteMultiple/autocompleteMultiple';
import { IAutocompleteValueItem } from '../../../components/autocompleteMultiple/types';
import { getRotasOtimizadasDeletar, postRotasOtimizadasEnviarEmCampo } from '../services/services';
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from '../../../components/modal';
import {AxiosResponse} from "axios";

interface IProps {
  rows: IRouteasyFiltroRoteirizacaoDTO[];
  setOpenAlertError: Function;
  setMsgAlertError: Function;
  setAlertType: Function;
  reload: Function;
  tecnicosAPI: IAutocompleteValueItem[];
}

const RotasOtimizadasTabela = ({
  rows,
  setMsgAlertError,
  setOpenAlertError,
  setAlertType,
  reload,
  tecnicosAPI
}: IProps) => {

  const [loading, setLoading] = React.useState<boolean>(false);

  const [openRowId, setOpenRowId] = useState<string | null>(null);

  const handleRowClick = (rowId: string) => {
    setOpenRowId(prevId => prevId === rowId ? null : rowId);
  };

  const [modalSequenciaOSData, setModalSequenciaOSData] = React.useState<IRouteasyFiltroRotaDTO | undefined>(undefined);

  //Tabela
  function RenderTableRota(props: { rows: IRouteasyFiltroRotaDTO[], index: number, idStatus: number }) {
    const { rows, index, idStatus } = props;

    const handleEnviarEmCampo = async (row: any) => {
      setLoading(true);
      try {
        const idTecnicoSelecionado = row?.veiculo?.tecnicoSelecionado?.id ?? 0;
        const resposta  = (await postRotasOtimizadasEnviarEmCampo(idTecnicoSelecionado, row.id)) as AxiosResponse;
        if(resposta.status == 200){
          setOpenAlertError(true);
          setAlertType("success");
          setMsgAlertError("Rota enviada para campo");
        }
        else if(resposta.status !== 200){
          setOpenAlertError(true);
          setAlertType("warning");
          setMsgAlertError("Não foi possível enviar rota para campo.");
        }
      } catch(e:any) {
        setOpenAlertError(true);
        setAlertType("error");
        setMsgAlertError(e?.response?.data);
      }
      setLoading(false);
    }

    return (
      <>
        <span className='Span'>Rotas</span>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <Styles.StyledTableCell className={`center`}>Rota</Styles.StyledTableCell>
                <Styles.StyledTableCell className={`center`}>N° de OS's</Styles.StyledTableCell>
                <Styles.StyledTableCell className={`center`}>Prestador</Styles.StyledTableCell>
                <Styles.StyledTableCell className={`center`}>Veículo</Styles.StyledTableCell>
                <Styles.StyledTableCell className={`center`}>Técnicos</Styles.StyledTableCell>
                <Styles.StyledTableCell className={`center`}>Ação</Styles.StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, idx) => {

                let tec = {} as IAutocompleteValueItem;
                if (row.idTecnicoSelecionado) {
                  tec = tecnicosAPI.find(f => f.id == row.idTecnicoSelecionado.toString()) || {} as IAutocompleteValueItem;
                }

                return (
                  <>
                    <TableRow
                      key={idx}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell className='center' component="th" scope="row" style={{ minWidth: 150 }}>
                        {row.nomeRota}
                      </TableCell>
                      <TableCell className='center' component="th" scope="row" style={{ minWidth: 150 }}>
                        {row.deliveries.length}
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setModalSequenciaOSData(row);
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell className='center' component="th" scope="row" style={{ minWidth: 150 }}>
                        {row.veiculo.nomePrestador}
                      </TableCell>
                      <TableCell className='center' component="th" scope="row" style={{ minWidth: 150 }}>
                        {row.veiculo.nomeVeiculo} - 
                      </TableCell>
                      <TableCell className='center' component="th" scope="row" style={{ minWidth: 250 }}>
                        {idStatus == ERouteasyRoteirizacaoStatus.EXCLUIDO
                          ? tec ? tec.name : ""
                          : (
                            <AutocompleteMultiple
                              label={""}
                              placeholder={"Técnicos"}
                              noOptionsText={"Nenhum técnico encontrado"}
                              id={`rotas.${idx}.veiculo.idTecnico`}
                              options={tecnicosAPI}
                              value={tecnicosAPI.filter(tech => tech.id === row.veiculo.tecnicoSelecionado.id.toString())}
                              onChange={(e: any) => {
                                row.veiculo.tecnicoSelecionado.id = Number(e[0].id);
                              }}
                              limitTags={1}
                              fontSize={12}
                              allSelected
                              dimension='sm'
                              multiple={false}
                              disableCloseOnSelect={false}
                            />
                          )

                        }
                      </TableCell>
                      <TableCell className='center' component="th" scope="row" style={{ minWidth: 150 }}>
                        <Button
                          variant="contained"
                          dimension="sm"
                          onClick={() => handleEnviarEmCampo(row)}
                          disabled={idStatus == ERouteasyRoteirizacaoStatus.EXCLUIDO}
                        >
                          Enviar em Campo
                        </Button>
                      </TableCell>
                    </TableRow>

                    {modalSequenciaOSData != undefined && ModalSequenciaOS()}
                  </>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}
      </>
    )
  }

  //Linha
  function RenderRow(props: { row: IRouteasyFiltroRoteirizacaoDTO, idx: number }) {
    const { row, idx } = props;
    const isOpen = openRowId === row.starredVersion;

    const handleDelete = async (row: IRouteasyFiltroRoteirizacaoDTO) => {
      setLoading(true);
      try {
        if (!row.id) throw "";
        await getRotasOtimizadasDeletar(row.id);
        reload();
      } catch {
        setOpenAlertError(true);
        setAlertType("error");
        setMsgAlertError("Erro ao excluir roteirização");
      }
      setLoading(false);
    }

    return (
      <>
        <TableRow
          key={idx}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell component="th" scope="row" style={{ minWidth: 150 }}>
            {row.nome}
            {row.rotas.length > 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRowClick(row.starredVersion);
                }}
              >
                {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TableCell>
          <TableCell className='center' component="th" scope="row" style={{ minWidth: 150 }}>
            {row.quantidadeOS}
          </TableCell>
          <TableCell className='center' component="th" scope="row" style={{ minWidth: 150 }}>
            {row.status}
          </TableCell>
          <TableCell className='center' component="th" scope="row" style={{ minWidth: 150 }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleDelete(row)}
              disabled={row.idStatus == ERouteasyRoteirizacaoStatus.EXCLUIDO}
            >
              <DeleteOutline style={{ cursor: 'pointer' }} />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              {RenderTableRota({ rows: row.rotas, index: idx, idStatus: row.idStatus })}
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  function ModalSequenciaOS() {

    return (
      <Modal
        title={"Sequência OS"}
        isOpen={modalSequenciaOSData != undefined}
        sx={{ "& .MuiPaper-elevation": { maxWidth: "1000px" } }}
        handleCloseModal={() => {
          setModalSequenciaOSData(undefined);
        }}
      >
        <div className='modal-container'>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <Styles.StyledTableCell className={`center`}>N° OS</Styles.StyledTableCell>
                  <Styles.StyledTableCell className={`center`} >Sequência</Styles.StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {modalSequenciaOSData?.deliveries
                  .sort((a, b) => a.sequencia - b.sequencia)
                  .map(d => (
                    <TableRow
                      key={d.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">{d.codigo}</TableCell>
                      <TableCell component="th" scope="row">{d.sequencia}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

      </Modal>
    )
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <Styles.StyledTableCell className={`center`}>Roteirização</Styles.StyledTableCell>
              <Styles.StyledTableCell className={`center`}>N° de OS's</Styles.StyledTableCell>
              <Styles.StyledTableCell className={`center`}>Status</Styles.StyledTableCell>
              <Styles.StyledTableCell className={`center`}>Excluir</Styles.StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <RenderRow key={idx} idx={idx} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default RotasOtimizadasTabela