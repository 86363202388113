import axios from "axios";
import { apiGet, apiPost, apiPut } from "../../../services/api";
import { IFarolExtracao, IGetGroupedServices, IPutValidaPerimetro } from "../types";
import { arrayToQueryString } from "../../../helpers/util";

const baseUrlOrdemServico = process.env.REACT_APP_API_ORDEMSERVICO;

const baseUrlAtendimento = process.env.REACT_APP_API_ATENDIMENTO;

const baseUrlIdentidade = process.env.REACT_APP_API_IDENTIDADE;

const token = process.env.REACT_APP_MAPA_TOKEN;

const api = axios.create();

export const findContratanteServicoParametro = async (chamado:number) => 
  await apiGet(`${baseUrlOrdemServico}/ContratanteServicoParametros/chamado?chamado=${chamado}`)

export const getListaTecnicosByPrestadorEncaminhar = async (idPrestador: number) =>
  await apiGet(`${baseUrlAtendimento}/Tecnico/Lista/${idPrestador}`);

export const getPrestadorPorId = async (prestadorId: number) => 
  await apiGet(`${baseUrlAtendimento}/Prestador/${prestadorId}`);

export const getGroupedServices = async () => 
  await apiGet(`${baseUrlOrdemServico}/GrupoServico`);

export const getGrouped_Services = async (params?: IGetGroupedServices) => 
  await apiGet(`${baseUrlOrdemServico}/GrupoServico_?CarregarServicos=true`, params);

export const exportFarolList = async (params: IFarolExtracao) => 
  await apiPost(`${baseUrlOrdemServico}/OrdemServico/farol/extracao`, params);

export const getEnderecoCoord = async (endereco: string) =>
  await api.get(`https://api.tomtom.com/search/2/geocode/${endereco}.json?storeResult=false&key=${token}`);

export const getContratantes = async () => 
  await apiGet(`${baseUrlIdentidade}/Contratante`);

export const getContratantesNovo = async () => 
  await apiGet(`${baseUrlOrdemServico}/contratante_`);

export const getPrestadores = async () => 
  await apiGet(`${baseUrlOrdemServico}/prestadores`, {});

export const getPrestadoresAtivos = async () => 
  await apiGet(`${baseUrlOrdemServico}/prestadores?Ativo=true`, {});

export const getListaTecnicosByPrestadorOrdemServico = async (prestadores:number[]) => 
  {
    const queryString = arrayToQueryString('prestadores', prestadores);
    const  urlConsultar  = `${baseUrlOrdemServico}/tecnicoprestadores?${queryString}&GroupTecnico=true`
    return  await apiGet(urlConsultar, {})
  };
  
export const updatevalidaPerimetro = async (params: IPutValidaPerimetro) => 
  await apiPut(`${baseUrlOrdemServico}/OrdemServico_/alterar-valida-perimetro`, params);

export const getRotaOtimizadaPorOs = async (numeroChamado:number) => 
  await apiGet(`${baseUrlAtendimento}/Routeasy/ObterRotasOtimizadasPorOs/${numeroChamado}`);
