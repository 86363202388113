import { apiDelete, apiGet, apiPost, apiPut } from "../../../services/api";
import { IGetVeiculoPesquisa, IPostVeiculo, PaginatedVehicleResponse } from "../types";

const baseUrl = process.env.REACT_APP_API_ATENDIMENTO
export const postVeiculo = async (params: IPostVeiculo) => 
    await apiPost(`${baseUrl}/PrestadorVeiculo`, params)

export const atualizarVeiculo = async (params: any) =>
    await apiPut(`${baseUrl}/PrestadorVeiculo`, params)


export const getTipoVeiculo = async () =>
    await apiGet(`${baseUrl}/PrestadorVeiculo/tipo-veiculo`)

export const getTipoViagem = async () =>
    await apiGet(`${baseUrl}/PrestadorVeiculo/tipo-viagem`)


export const getTipoCombustivel = async () =>
    await apiGet(`${baseUrl}/PrestadorVeiculo/tipo-combustivel`)


export const getTipoIntervalo = async () =>
    await apiGet(`${baseUrl}/PrestadorVeiculo/tipo-intervalo`)

export const getPrestadores = async () =>
    await apiGet(`${baseUrl}/PrestadorVeiculo/prestador`)

export const getTecnicos = async (prestadorId:number,tipoCombustivelId:number) =>
    await apiGet(`${baseUrl}/PrestadorVeiculo/tecnicos/${prestadorId}/${tipoCombustivelId}`)

export const deleteVeiculo = async (id: number) =>
    await apiDelete(`${baseUrl}/PrestadorVeiculo/${id}`)


export const getVeiculoPorId = async (veiculoId: number) =>
    await apiGet(`${baseUrl}/PrestadorVeiculo/${veiculoId}`)

export const getPesquisaVeiculos = async (parametros:IGetVeiculoPesquisa) => {
    const queryParams = new URLSearchParams();
    parametros.idPrestadores.forEach(p => queryParams.append('IdPrestadores', p.toString()));
    queryParams.append('PageNumber', parametros.pageNumber.toString());
    queryParams.append('PageSize', parametros.pageSize.toString());
    
    const response  = ( await apiGet(`${baseUrl}/PrestadorVeiculo?${queryParams.toString()}`)) as any;
    const data = response.data;

    return data as PaginatedVehicleResponse;
}
