import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import FormControl from "@mui/material/FormControl";
import './timeField.scss';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { Moment } from 'moment';

export interface ICustomTimeField {
    id?: string,
    label?: string,
    className?: string,
    dimension?: 'sm' | 'lg',
    value: Moment | null,
    onChange: (e: any) => void,
    disabled?: boolean,
    format?: string;
    error?: boolean,
    helperText?: any,
    readOnly?: boolean,
    sx?: SxProps<Theme>
}

export default function CustomTimeField({
    id = "CustomTimeField",
    label = "",
    className,
    dimension,
    value,
    onChange,
    disabled = false,
    format = "HH:mm",
    error,
    helperText,
    readOnly,
    sx
}: ICustomTimeField) {

    return (
        <FormControl className="container" sx={{ m: 1, width: "100%" }}>
            {label && <label htmlFor={id} className={`label-input ${dimension}`}>{label}</label>}
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimeField
                    className={`InputComponentDate ${className} ${dimension} ${!!error ? "error" : ""}`}
                    value={value}
                    sx={sx}
                    onChange={(newValue) => onChange(newValue)}
                    disabled={disabled || readOnly}
                    format={format}
                    readOnly={readOnly}
                />
            </LocalizationProvider>
            <span className="errorMessage">{helperText}</span>
        </FormControl>
    );
}
