import { useContext, useEffect, useState } from "react";
import SectionCustom from "../../../components/section/section";
import Button from "../../../components/Button/styles";
import { AuthContext } from "../../../contexts/auth";
import "./consultarPrestadorVeiculo.scss";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  deleteVeiculo,
  getPesquisaVeiculos,
  getPrestadores,
} from "../services";
import {
  IGetVeiculoPesquisa,
  IOptionsValue,
  MenuSelecao,
  RestricaoIntervalo,
  VeiculoResponse,
} from "../types";
import { ResponseApi } from "../../../contexts/types/responsesHttp";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import {
  Alert,
  Box,
  Modal,
  CircularProgress,
  Typography,
  FormControl,
  Grid,
  IconButton,
  TableRow,
  Table,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Collapse from "../../../components/collapse/collapse";
import CloseIcon from "@mui/icons-material/Close";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import * as Styles from "../../../components/table/styles";
import { EditIconStyled } from "../../../components/stickyTable/stickyTable_styles";
import Pagination from "../../../components/pagination/paginacao";
import { StyledTableRow } from "../../parametroItemChecklist/view/consultarParametroItem_Style";
const initialState: IGetVeiculoPesquisa = {
  idPrestadores: [],
  idPrestadoreOptions: [],
  pageNumber: 1,
  pageSize: 10,
};
const ConsultarPrestadorVeiculo = () => {
  const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const [formData, setFormData] = useState<IGetVeiculoPesquisa>(initialState);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedVeiculoId, setSelectedVeiculoId] = useState<number | null>(
    null
  );
  const navigate = useNavigate();

  const [listaPrestadores, setListaPrestadores] = useState<MenuSelecao[]>([]);
  const [listaResponse, setListaResponse] = useState<VeiculoResponse[]>([]);
  const [openCaracteristicasModal, setOpenCaracteristicasModal] =
    useState(false);
  const [selectedCaracteristicas, setSelectedCaracteristicas] = useState<
    string[]
  >([]);
  const [openIntervaloModal, setOpenIntervaloModal] = useState(false);
  const [selectedRestricoes, setSelectedRestricoes] = useState<
    RestricaoIntervalo[]
  >([]);
  const [msgAlertError, setMsgAlertError] = useState<string>(
    "Falha na operação, tente novamente."
  );
  const [openAlertError, setOpenAlertError] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const isIncluding = window.location.pathname.includes(
    "ConsultarPrestadorVeiculo"
  );
  const intervaloModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
    maxHeight: "80vh",
    overflow: "auto",
  };
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
  };
  const caracteristicasModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
    maxHeight: "80vh",
    overflow: "auto",
  };
  const handleClickPrestadorVeiculoEdit = (idVeiculo: number,prestadorId :number, tipoCombustivelId:number) => {
    const dados = {
      idVeiculo,
      prestadorId,
      tipoCombustivelId
    }
    navigate("/EditarPrestadorVeiculo", {
      state: {
        dadosVeiculo: dados,
        rota: {
          url: window.location.pathname,
          name: "Editar Veiculo Selecionado",
        },
      },
    });
  };
  const handleOpenCaracteristicas = (caracteristicas: string[]) => {
    setSelectedCaracteristicas(caracteristicas);
    setOpenCaracteristicasModal(true);
  };
  const handleDeleteClick = (veiculoId: number) => {
    setSelectedVeiculoId(veiculoId);
    setOpenDeleteModal(true);
  };
  const handleCloseCaracteristicas = () => {
    setOpenCaracteristicasModal(false);
    setSelectedCaracteristicas([]);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedVeiculoId(null);
  };
  const handleConfirmDelete = async () => {
    setLoading(true);
    if (selectedVeiculoId) {
      try {
        await deleteVeiculo(selectedVeiculoId);
        handleCloseDeleteModal();
        await handlePesquisar();
        handlePesquisar();
        setLoading(false);
      } catch (error) {
        setMsgAlertError("Erro ao excluir o veículo.");
        setOpenAlertError(true);
        setLoading(false);
      }
    }
  };
  const handleOpenIntervalo = (restricoes: RestricaoIntervalo[]) => {
    setSelectedRestricoes(restricoes);
    setOpenIntervaloModal(true);
  };

  const handleCloseIntervalo = () => {
    setOpenIntervaloModal(false);
    setSelectedRestricoes([]);
  };
  const profileHasPermission = (funcionalidade: string) =>
    funcionalidadeDaTelaTemPermissao(isIncluding, funcionalidade);

  const podeExcluir = profileHasPermission(
    "Acesso a funcionalidade excluir prestador veículo"
  );
  const handlePesquisar = async () => {
    setLoading(true);
    try {
      if (isFormValid) {
        const result = await getPesquisaVeiculos(formData);
        setListaResponse(result.items);
        setTotalCount(result.totalCount);
        setLoading(false);
      } else {
        setListaResponse([]);
        setTotalCount(0);
        setLoading(false);
      }
    } catch (e) {
      setListaResponse([]);
      setTotalCount(0);
      setLoading(false);
    }
  };
  const handleGetPrestadores = async () => {
    setLoading(true);
    await getPrestadores().then(
      (res: any) => {
        const respSuccess = res?.data as
          | ResponseApi<MenuSelecao[]>
          | [] as MenuSelecao[];
        if (respSuccess) {
          const listaPrestadores =
            respSuccess.map((item: any) => {
              return { descricao: item.descricao, id: item.id.toString() };
            }) || [];
          setListaPrestadores([...[], ...listaPrestadores]);
          setLoading(false);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<MenuSelecao[]>;
        if (respErr) {
          setMsgAlertError(respErr.errors[0].Message);
          setOpenAlertError(true);
          setListaPrestadores([]);
          setLoading(false);
        }
      }
    );
  };
  const checkFormValidity = (data: IGetVeiculoPesquisa) => {
    const isValid = data.idPrestadores.length > 0;

    setIsFormValid(isValid);
  };
  const handleSelectChange = (event: IOptionsValue[]) => {
    const newFormData = {
      ...formData,
      idPrestadores: event.map((e: IOptionsValue) => parseInt(e.id)),
    };
    setFormData(newFormData);
    checkFormValidity(newFormData);
  };
  const handleClickNovoVeiculo = () => {
    navigate("/CriarPrestadorVeiculo", {
      state: {
        rota: {
          url: window.location.pathname,
          name: "Cadastrar Novo Veículos",
        },
      },
    });
  };
  useEffect(() => {
    handleGetPrestadores();
  }, []);
  return (
    <>
      <SectionCustom
        id={"ConsultarPrestadorVeiculos"}
        className="ConsultarPrestadorVeiculos"
        titleHeader="Veículos"
        titleBreadcrumbItem="Consultar Veículos"
      >
        {openAlertError && (
          <div className="modalErro-Modelo">
            <Collapse onClose={setOpenAlertError} in={openAlertError}>
              <Alert
                severity="error"
                icon={<div />}
                variant="filled"
                sx={{
                  mb: 2,
                  bgcolor: "rgb(255, 35, 35, 0.7)",
                  borderRadius: "15px",
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "50px",
                }}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="medium"
                    onClick={() => setOpenAlertError(false)}
                  >
                    <CloseIcon
                      id={"ModalModeloCompativel-AlertCloseIcon"}
                      fontSize="inherit"
                      sx={{
                        color: "white",
                      }}
                    />
                  </IconButton>
                }
              >
                <span
                  style={{
                    color: "white",
                    fontSize: "16px",
                  }}
                  dangerouslySetInnerHTML={{ __html: msgAlertError }}
                />
              </Alert>
            </Collapse>
          </div>
        )}
        {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}
        <div className="ConsultarPrestadorVeiculos-Container">
          <div className="filtro-botoes">
            <Button
              dimension="sm"
              variant="primary"
              onClick={() => handleClickNovoVeiculo()}
            >
               Cadastrar Veículo
            </Button>
            <Button
              dimension="sm"
              disabled={!isFormValid}
              variant="primary"
              onClick={() => handlePesquisar()}
            >
              Pesquisar
            </Button>
          </div>
          <div className="filtro-select-filial">
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <AutocompleteMultiple
                  dimension="sm"
                  label={"Filial"}
                  placeholder={""}
                  noOptionsText={"Nenhuma filial encontrada"}
                  options={
                    listaPrestadores?.map((item: MenuSelecao) => ({
                      name: item.descricao,
                      id: item.id.toString(),
                    }))!
                  }
                  value={formData.idPrestadoreOptions}
                  onChange={(e: IOptionsValue[]) => {
                    handleSelectChange(e);
                  }}
                  id="multiple-checkbox-filial"
                  multiple={true}
                  disabled={false}
                />
              </FormControl>
            </Grid>
          </div>
          <Box sx={{ padding: 2, marginTop: 2 }}>
            <>
              {listaResponse?.length > 0 ? (
                <>
                  <TableContainer component={Styles.StyledPaper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          {[
                            "Nome",
                            "Tipo de veículo",
                            "Capacidade",
                            "Tipo de combustível",
                            "Consumo",
                            "Custo de Combustível",
                            "Tipo de Viagem",
                            "Características especiais do veículo",
                            "Quantidade de veículos",
                            "Quantidade máxima de paradas nas rotas do perfil de veículo",
                            "Quantidade máxima de serviços nas rotas do perfil de veículo",
                            "Número máximo de horas das rotas do perfil de veículo",
                            "Distância máxima entre uma parada e outra",
                            "Intervalo",
                            "Ações",
                          ]?.map((title) => (
                            <Styles.StyledTableCell
                              className={`${title} center`}
                              key={title}
                            >
                              {title}
                            </Styles.StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {listaResponse.map((row, index) => (
                          <StyledTableRow key={`${row.veiculoId}`}>
                            <Styles.StyledTableCell component="th" scope="row">
                              {row.veiculoNome}
                            </Styles.StyledTableCell>
                            <Styles.StyledTableCell component="th" scope="row">
                              {row.tipoVeiculo}
                            </Styles.StyledTableCell>
                            <Styles.StyledTableCell component="th" scope="row">
                              {row.capacidade}
                            </Styles.StyledTableCell>
                            <Styles.StyledTableCell component="th" scope="row">
                              {row.tipoCombustivel}
                            </Styles.StyledTableCell>
                            <Styles.StyledTableCell component="th" scope="row">
                              {row.consumoDeCombustivel}
                            </Styles.StyledTableCell>
                            <Styles.StyledTableCell component="th" scope="row">
                              {row.custoCombustivel}
                            </Styles.StyledTableCell>
                            <Styles.StyledTableCell component="th" scope="row">
                              {row.tipoViagem}
                            </Styles.StyledTableCell>
                            <Styles.StyledTableCell component="th" scope="row">
                              <IconButton
                                onClick={() =>
                                  handleOpenCaracteristicas(
                                    row.caracteristicasEspeciaisVeiculo
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Styles.StyledTableCell>
                            <Styles.StyledTableCell component="th" scope="row">
                              {row.quantidadeVeiculosDisponiveis}
                            </Styles.StyledTableCell>
                            <Styles.StyledTableCell component="th" scope="row">
                              {row.quantidadeMaximaParadas}
                            </Styles.StyledTableCell>
                            <Styles.StyledTableCell component="th" scope="row">
                              {row.quantidadeMaximaServicos}
                            </Styles.StyledTableCell>
                            <Styles.StyledTableCell component="th" scope="row">
                              {row.numeroMaximoHorasRota}
                            </Styles.StyledTableCell>
                            <Styles.StyledTableCell component="th" scope="row">
                              {row.distanciaMaximaEntreUmaParadaOutra}
                            </Styles.StyledTableCell>
                            <Styles.StyledTableCell component="th" scope="row">
                              <IconButton
                                onClick={() =>
                                  handleOpenIntervalo(row.restricoesIntervalo)
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Styles.StyledTableCell>
                            <Styles.StyledTableCell component="th" scope="row">
                              <EditIconStyled
                                onClick={(e) => {
                                  handleClickPrestadorVeiculoEdit(row.veiculoId,row.prestadorId,row.tipoCombustivelId);
                                }}
                              />
                              <IconButton
                                onClick={(e) => {
                                  handleDeleteClick(row.veiculoId);
                                }}
                                disabled={!podeExcluir}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Styles.StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={totalCount}
                    pageSize={pageSize}
                    showLabel={false}
                    showQtdPageSelectBox={false}
                    setPageSize={(value: string) => setPageSize(Number(value))}
                    onPageChange={(page: number) =>
                      setCurrentPage(Number(page))
                    }
                  />
                </>
              ) : (
                <>Sem dados para exibição.</>
              )}
            </>
          </Box>
        </div>
      </SectionCustom>
      {/* Modal de Características Especiais */}
      <Modal
        open={openCaracteristicasModal}
        onClose={handleCloseCaracteristicas}
        aria-labelledby="caracteristicas-modal-title"
      >
        <Box sx={caracteristicasModalStyle}>
          <Typography
            id="caracteristicas-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            Características Especiais do Veículo
          </Typography>
          <TableContainer component={Styles.StyledPaper}>
            <Table>
              <TableHead>
                <TableRow>
                  <Styles.StyledTableCell>
                    Característica
                  </Styles.StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedCaracteristicas.map((caracteristica, index) => (
                  <StyledTableRow key={index}>
                    <Styles.StyledTableCell>
                      {caracteristica}
                    </Styles.StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              dimension="sm"
              variant="primary"
              onClick={handleCloseCaracteristicas}
            >
              Fechar
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* Modal de Delete */}
      <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-modal-title"
      >
        <Box sx={modalStyle}>
          <Typography
            id="delete-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            Confirmar exclusão
          </Typography>
          <Typography sx={{ mb: 3 }}>
            Deseja realmente excluir este veículo?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button
              dimension="sm"
              variant="secondary"
              onClick={handleCloseDeleteModal}
            >
              Cancelar
            </Button>
            <Button
              dimension="sm"
              variant="primary"
              onClick={handleConfirmDelete}
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* Modal de Intervalo  */}
      <Modal
        open={openIntervaloModal}
        onClose={handleCloseIntervalo}
        aria-labelledby="intervalo-modal-title"
      >
        <Box sx={intervaloModalStyle}>
          <Typography
            id="intervalo-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            Restrições de Intervalo
          </Typography>

          {/* Primeira tabela com dados da restrição */}
          <TableContainer component={Styles.StyledPaper} sx={{ mb: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <Styles.StyledTableCell>
                    Nome do Intervalo
                  </Styles.StyledTableCell>
                  <Styles.StyledTableCell>
                    Tempo de Intervalo
                  </Styles.StyledTableCell>
                  <Styles.StyledTableCell>
                    Tipo de Intervalo
                  </Styles.StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedRestricoes.map((restricao, index) => (
                  <StyledTableRow key={index}>
                    <Styles.StyledTableCell>
                      {restricao.nomeIntervalo}
                    </Styles.StyledTableCell>
                    <Styles.StyledTableCell>
                      {restricao.tempoIntervalo}
                    </Styles.StyledTableCell>
                    <Styles.StyledTableCell>
                      {restricao.tipoIntervalo}
                    </Styles.StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Título para a tabela de horários */}
          <Typography variant="h6" component="h3" sx={{ mb: 2 }}>
            Horários
          </Typography>

          {/* Segunda tabela com os horários */}
          <TableContainer component={Styles.StyledPaper}>
            <Table>
              <TableHead>
                <TableRow>
                  <Styles.StyledTableCell>Início</Styles.StyledTableCell>
                  <Styles.StyledTableCell>Fim</Styles.StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedRestricoes.flatMap((restricao) =>
                  restricao.horariosIntervalo.map((horario, idx) => (
                    <StyledTableRow key={idx}>
                      <Styles.StyledTableCell>
                        {horario.dataInicio}
                      </Styles.StyledTableCell>
                      <Styles.StyledTableCell>
                        {horario.dataFim}
                      </Styles.StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              dimension="sm"
              variant="primary"
              onClick={handleCloseIntervalo}
            >
              Fechar
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ConsultarPrestadorVeiculo;
