import React, { useRef, useState } from 'react'
import Modal from '../../../components/modal'
import Button from '../../../components/Button/styles';
import Input from '../../../components/Input/Input'
import Collapse from '../../../components/collapse/collapse';
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Container, ButtonList, ErrorMessage } from './EnviarTecnicoModalStyle';
import { getOsById, sendToTechnician } from '../services/services';
import { useEffect } from 'react';
import { IEnviarTecnicoModal } from '../types'
import { AxiosResponse } from 'axios';
import { encaminharOSs } from '../../encaminharOS/services/services';
import { IOrdemServicoEncaminhada } from '../../farol/types';
import ErroRouteasyModal from '../../farol/view/farolErroRouteasyModal';

const EnviarTecnicoModal = ({
  serial,
  isModalOpen,
  handleCloseModal,
  idUsuario,
  idTecnico,
  serialNumber,
  setLoading,
  idPrestador,
  handleSubmit
}: IEnviarTecnicoModal) => {

  const [isOpen, setIsOpen] = useState(isModalOpen)
  const [error, setError] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState('')
  const [chamado, setChamado] = useState<string>()

  const [ehPularRoteirizacaoModal, setEhPularRoteirizacaoModal] = useState(false);
  const [ehPularRoteirizacao, setEhPularRoteirizacao] = useState(false);
  const [contadorAtualizacao, setContadorAtualizacao] = useState(0);
  const deveInvocarEncaminhar = useRef(false);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleSubmitModal();
    }
  }

  const handleSubmitModal = async () => {
    if (!chamado) {
      setTipoMsgAlert('error');
      setMsgAlert("Entre um chamado válido");
      setOpenAlert(true);
      setLoading(false);
      return;
    };

    const { data } = await getOsById(Number(chamado)) as AxiosResponse;

    if (data?.idOrdemServicoStatus?.toString() === '5' || data?.idOrdemServicoStatus?.toString() === '24'){
      const response = await handleSubmit();
      if(response.status === 200) await handleSubmitChamado();
      setChamado('');
    } else {
      setTipoMsgAlert('error');
      setMsgAlert('Apenas OSs com status "encaminhada" e "reencaminhada" poderão ser enviadas a campo.');
      setOpenAlert(true);
      setLoading(false);
    }
  };

  const handleSubmitChamado = async () => {
    setEhPularRoteirizacaoModal(false);
    try{
      const { data } = await encaminharOSs({
        idUsuario: idUsuario,
        idTecnico: idTecnico,
        idPrestador: idPrestador,
        prestadores: [idPrestador],
        ordensServico: [{
            id: Number(chamado!),
            serial: serialNumber?.toString() || ''
          }],
        seriais: [{
            serial: serialNumber?.toString() || ''
          }],
        ehPularRoteirizacao: ehPularRoteirizacao
      }) as AxiosResponse

      const listaEncaminharResponseMsgError = data.ordensOppay.filter(
        (item: IOrdemServicoEncaminhada) => !item.isSucesso)

      setMsgAlert(listaEncaminharResponseMsgError.map(
        (item: IOrdemServicoEncaminhada) =>
          item.message !== '' ?
            `${item.message}` :
            `Situação da OS ${item.idOs} inválida para o encaminhamento.`
      )
        .toString()
        .replaceAll(',', '</br>'))
      setTipoMsgAlert('error')
      setOpenAlert(true)
      
    } catch (e: any) {
      setEhPularRoteirizacao(false);
      if (e && e.response && e.response.data && e.response.data.includes("Erro ao roteirizar")) {
        setEhPularRoteirizacaoModal(true);
        setOpenAlert(false);
      }
    }
  }

  useEffect(() => {
    setIsOpen(isModalOpen)
    setOpenAlert(false)
  }, [isModalOpen])


  const handleCliqueBotao = (valor: boolean) => {
    if (ehPularRoteirizacao !== valor) {
      setEhPularRoteirizacao(valor);
    } else {
      setContadorAtualizacao((prev) => prev + 1);
    }
    deveInvocarEncaminhar.current = true;
  };

  useEffect(() => {
    if (deveInvocarEncaminhar.current) {
      handleSubmitChamado();
      deveInvocarEncaminhar.current = false;
    }
  }, [ehPularRoteirizacao, contadorAtualizacao]);

  return (
    <>
    <Modal
      handleCloseModal={handleCloseModal}
      title='Enviar equipamento'
      isOpen={isOpen}
    >
      <Container>
        <div>
          <Input
            autoFocus={true}
            id={"inputChamado"}
            label='Chamado'
            type='number'
            className="Chamado"
            onKeyDown={handleKeyDown}
            onChange={(e) => setChamado((e.target as HTMLInputElement).value)}
            onFocus={() => setOpenAlert(false)}
            dimension='sm'
          />
          {error && <ErrorMessage>Chamado não existe! Favor Inserir Chamado existente</ErrorMessage>}
        </div>
        <Collapse
          onClose={setOpenAlert}
          in={openAlert}
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <Alert
            severity={tipomsgAlert}
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: tipomsgAlert === "success" ? "#178B319E" : "rgb(255, 35, 35, 0.7)",
              height: "fit-content",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 0,
              borderRadius: 0,
            }}

            action={
              <IconButton
                id={"ModalEncaminharOS-AlertCloseIcon"}
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlert(false)}
              >
                <CloseIcon
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
            >
              {msgAlert}
            </span>
          </Alert>
        </Collapse>
        <ButtonList>
          <Button dimension='sm' variant='primary' onClick={handleSubmitModal} type='submit'>Inserir</Button>
          <Button dimension='sm' variant='secondary' onClick={handleCloseModal}>Fechar</Button>
        </ButtonList>

      </Container>
    </Modal>

      <ErroRouteasyModal
        isModalOpen={ehPularRoteirizacaoModal}
        handleCloseModal={() => setEhPularRoteirizacaoModal(!ehPularRoteirizacaoModal)}
        handleClickButton={(valor) => handleCliqueBotao(valor)}
      />
    </>
  )
}

export default EnviarTecnicoModal