import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import moment from 'moment'
import { AxiosResponse } from 'axios'
import IosShare from "@mui/icons-material/IosShare";

import Header from '../../../components/header/header'
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb'
import { HomeContext } from '../../../contexts/homeContext'
import { IItemTabelaChecklist, IPergunta, IPostFiltroChecklist } from '../types'
import ConsultarChecklistTabela from './consultarChecklistTabela'
import { getPerguntasByGrupos, postFiltroChecklist, exportCSV } from '../services'
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading'
import Pagination from '../../../components/pagination/paginacao'
import { IPaginationList } from '../../../core/types'
import { CircularProgress, Typography } from "@mui/material";
import CustomTabPanel from '../../../components/tab/tabPanel'
import ChecklistPopoverFiltros from './checklistPopoverFiltros'
import { ReactComponent as FiltroIcon } from "../../../assets/icons/FiltroIcon.svg";
import ChecklistFiltrosAplicados from './checklistFiltrosAplicados'
import { ReactComponent as SortIcon } from "../../../assets/icons/SortIcon.svg";
import { ReactComponent as SortAscIcon } from "../../../assets/icons/SortAscIcon.svg";
import { ReactComponent as SortDescIcon } from "../../../assets/icons/SortDescIcon.svg";
import ChecklistPopoverOrdenar from './checklistPopoverOrdenar'
import { EPerguntaGrupo } from '../types/constants'

import "./consultaChecklist.scss";

const ConsultarChecklist = () => {
  const {
    setMenuLateral,
    temFiltroPopoverSelecionado,
    checklistFiltroTecnicosSelecionados,
    checklistFiltroPlacaMotoSelecionados,
    checklistFiltroTipoMotoSelecionados,
    checklistFiltroDataInicial,
    checklistFiltroDataFinal,
    checklistFiltroKMInicial,
    checklistFiltroKMFinal,
    checklistFiltroStatusItemMoto,
    checklistFiltroItemMoto,
    checklistFiltroStatusItemEPI,
    checklistFiltroItemEPI,

    checklistOrdem,
    setChecklistOrdem,

    checklistOrdenacaoPor,

    checklistTipo,
    checklistFotoCompleto,

  } = useContext(HomeContext);

  const [tableData, setTableData] = useState<IPaginationList<IItemTabelaChecklist[]>>({
    items: [] as IItemTabelaChecklist[],
    totalCount: 0
  } as IPaginationList<IItemTabelaChecklist[]>);
  const [perguntaAPI, setPerguntaAPI] = useState<IPergunta[]>([]);
  const [loading, setLoading] = useState(true);

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const [value, setValue] = React.useState(EPerguntaGrupo.VISAO_GERAL);

  useEffect(() => {
    setTableData({} as any);
    setLoading(false);
    requestTableData({ pageNumber: currentPage, pageSize: pageSize });
  }, [])

  useEffect(() => {
    requestPerguntaAPI(value);
  }, [value])

  const mountFormFiltroExportar = (): IPostFiltroChecklist => {
    return {
      dataRangeInicio: !_.isEmpty(checklistFiltroDataInicial)
        ? moment(checklistFiltroDataInicial, "YYYY-MM-DD HH:mm:ss").toDate()
        : undefined,
      dataRangeFim: !_.isEmpty(checklistFiltroDataFinal)
        ? moment(checklistFiltroDataFinal, "YYYY-MM-DD HH:mm:ss").toDate()
        : undefined,
      tecnico: !_.isEmpty(checklistFiltroTecnicosSelecionados)
        ? checklistFiltroTecnicosSelecionados?.map(m => Number(m.id))
        : undefined,
      placaMoto: !_.isEmpty(checklistFiltroPlacaMotoSelecionados)
        ? checklistFiltroPlacaMotoSelecionados?.map(m => m.name)
        : undefined,
      tipoMoto: !_.isEmpty(checklistFiltroTipoMotoSelecionados)
        ? checklistFiltroTipoMotoSelecionados?.map(m => Number(m.id))
        : undefined,
      quilometragemRangeInicio: !_.isNaN(Number(checklistFiltroKMInicial))
        ? Number(checklistFiltroKMInicial) : 0,
      quilometragemRangeFim: !_.isNaN(Number(checklistFiltroKMFinal))
        ? Number(checklistFiltroKMFinal) : 0,
      statusItemMoto: !_.isEmpty(checklistFiltroStatusItemMoto)
        ? checklistFiltroStatusItemMoto?.map(m => m.name)
        : undefined,
      itemMoto: !_.isEmpty(checklistFiltroItemMoto)
        ? checklistFiltroItemMoto?.map(m => m.name)
        : undefined,
      statusItemEPI: !_.isEmpty(checklistFiltroStatusItemEPI)
        ? checklistFiltroStatusItemEPI?.map(m => m.name)
        : undefined,
      itemEPI: !_.isEmpty(checklistFiltroItemEPI)
        ? checklistFiltroItemEPI?.map(m => m.name)
        : undefined,
      checklistTipo: !_.isEmpty(checklistTipo)
        ? checklistTipo?.map(m => Number(m.id))
        : undefined,
      checklistFotoCompleto: !_.isEmpty(checklistFotoCompleto)
        ? checklistFotoCompleto?.map(m => Boolean(m.id))
        : undefined,

      pageNumber: currentPage,
      pageSize: pageSize
    } as IPostFiltroChecklist;
  }

  const handleExport = async () => {
    setLoadingExport(true)
    try {
      const params = { ...mountFormFiltroExportar(), idGrupo: value }
      const { data } = await exportCSV(params) as AxiosResponse
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'gestaoChecklist.csv')
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      setOpenAlert(true)
      setTipoMsgAlert('error')
      setMsgAlert('Erro ao exportar Gestao Checklist')
    }
    setLoadingExport(false)
  };

  useEffect(() => {
    requestTableData(mountFormFiltroExportar())
  }, [
    checklistFiltroDataInicial,
    checklistFiltroDataFinal,
    checklistFiltroTecnicosSelecionados,
    checklistFiltroPlacaMotoSelecionados,
    checklistFiltroTipoMotoSelecionados,
    checklistFiltroKMInicial,
    checklistFiltroKMFinal,
    checklistFiltroStatusItemMoto,
    checklistFiltroItemMoto,
    checklistFiltroStatusItemEPI,
    checklistFiltroItemEPI,
    checklistTipo,
    checklistFotoCompleto,
    currentPage,
    pageSize
  ])

  const requestTableData = async (params: IPostFiltroChecklist) => {
    setLoading(true);
    setTableData([] as any);

    try {
      const { success, data } = await postFiltroChecklist(params);

      if (success) {
        setTableData(data);
      }

    } catch (e: any) {
      setMsgAlert("Nenhum modelo encontrado");
      setTipoMsgAlert('error');
      setOpenAlert(true);

    }

    setLoading(false);
  };

  const requestPerguntaAPI = async (idGruposByPipe: string) => {
    setLoading(true);
    setPerguntaAPI([] as any);

    try {
      let idGrupos = idGruposByPipe.split("|");
      const data = await getPerguntasByGrupos({
        idGrupos: idGrupos,
        ehExportar: true
      });

      if (data) {
        setPerguntaAPI(data);
      }
    } catch (e: any) {
      setPerguntaAPI([] as any);
    }

    setLoading(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const renderTable = () => {
    return !_.isEmpty(tableData) && !_.isEmpty(tableData.items) ? (
      <>
        <ConsultarChecklistTabela
          perguntas={perguntaAPI}
          itensTable={tableData?.items ?? []}
          ordem={checklistOrdem}
          ordenacaoPor={checklistOrdenacaoPor}
        />
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={tableData?.totalCount ?? 0}
          pageSize={pageSize}
          setPageSize={(value: string) => setPageSize(Number(value))}
          onPageChange={(page: number) => setCurrentPage(Number(page))}
          labelQtdeRight="Itens por página."
        />
      </>
    ) : <></>
  }

  //#region Filtro
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenFiltros = (currentTarget: any) => {
    setAnchorEl(currentTarget);
    setOpen(!open);
  };
  const handleCloseFiltros = () => {
    setOpen(false);
  };
  const [isFiltroGrid, setIsFiltroGrid] = useState(false);
  const [isFieldBuscaRapida, setIsFieldBuscaRapida] = useState(false);

  const resetFiltros = (value: boolean) => setIsFiltroGrid(value);
  const [loadingExport, setLoadingExport] = useState(false);
  //#endregion

  //#region Ordernar
  const [openSort, setOpenSort] = useState(false);
  const [anchorElSort, setAnchorElSort] = useState(null);

  const handleOpenOrdenar = (currentTarget: any) => {
    setAnchorElSort(currentTarget);
    setOpenSort(!open);
  };

  const handleCloseOrdenar = () => {
    setOpenSort(false);
  };
  //#endregion


  return (
    <section className='ConsultarChecklist'>
      <Header setMenuLateral={() => setMenuLateral(true)} title="Gestão de Checklist" />
      <Breadcrumb>
        <BreadcrumbItem>Gestão de Checklist</BreadcrumbItem>
      </Breadcrumb>

      {loading && <FullscreenLoading />}

      <>
        <div className="Checklist-BlocoBotoes">

          <div className="Checklist-BlocoBotoes-Right">
            <>
              <div className={"Checklist-BlocoBotoes-RightBtn BtnOrdenar"}>
                <div
                  id={"Checklist-BtnOrdenar"}
                  className={`Checklist-BlocoBotoes-RightBtnBtnOrdenarLeft ${loadingExport ? ' disabled' : ''}`}
                  onClick={(event) => !loadingExport && handleOpenOrdenar(event.currentTarget)}
                >
                  <SortIcon className={"IconeBtnFarol SortIcon"} />
                  ordenar
                </div>

                <div
                  id={"Checklist-BtnOrdenarAscDesc"}
                  className={"Checklist-BlocoBotoes-RightBtnBtnOrdenarRight"}
                  onClick={() => !loadingExport && setChecklistOrdem(checklistOrdem === 'asc' ? 'desc' : 'asc')}
                >
                  {checklistOrdem == 'desc' && (
                    <SortAscIcon className={"SortAscIcon"} />
                  )}

                  {checklistOrdem === 'asc' && (
                    <SortDescIcon className={"SortDescIcon"} />
                  )}
                </div>
              </div>

              <ChecklistPopoverOrdenar
                id={"Checklist-PopoverOrdenar"}
                open={openSort}
                onClose={handleCloseOrdenar}
                anchorEl={anchorElSort}
              />
            </>

            <>
              <div
                id={"Checklist-BtnFiltro"}
                className={
                  `Checklist-BlocoBotoes-RightBtn BtnFiltro ${temFiltroPopoverSelecionado ? " BtnSelecionado" : ""}`}
                onClick={(event) => !loadingExport && handleOpenFiltros(event.currentTarget)}
              >
                <FiltroIcon className={"IconeBtnFarol FiltroIcon"} />
                Filtro
              </div>

              <ChecklistPopoverFiltros
                id={"Checklist-PopoverFiltro"}
                open={open}
                onClose={handleCloseFiltros}
                anchorEl={anchorEl}
                setCurrentPage={setCurrentPage}
                isFilterFromGrid={isFiltroGrid}
                IsFieldBuscaRapida={isFieldBuscaRapida}
                resetFiltrosPopover={(e) => resetFiltros(e)}
              />
            </>

            <div
              id={"Checklist-BtnExportar"}
              className={`Checklist-BlocoBotoes-RightBtn BtnExportar ${temFiltroPopoverSelecionado ? " BtnSelecionado" : ""}`}
              onClick={(event) => !loadingExport && handleExport()}
            >
              {loadingExport
                ? <CircularProgress color="inherit" size={14} />
                : <>
                  <IosShare
                    id={"IosShare"}
                    fontSize="small"
                    sx={{
                      color: "white",
                    }}
                  />
                  <Typography fontSize="0.875rem"> Exportar</Typography>
                </>}
            </div>
          </div>

        </div>

        <ChecklistFiltrosAplicados />
      </>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Visão Geral" value={EPerguntaGrupo.VISAO_GERAL} />
            <Tab label="Itens da Moto" value={`${EPerguntaGrupo.ITENS_MOTO}|${EPerguntaGrupo.VISAO_GERAL_ITENS_MOTO_COMPLETO}`} />
            <Tab label="EPI's" value={`${EPerguntaGrupo.EPIS}|${EPerguntaGrupo.VISAO_GERAL_EPIS_COMPLETO}`} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={EPerguntaGrupo.VISAO_GERAL}>
          {renderTable()}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={`${EPerguntaGrupo.ITENS_MOTO}|${EPerguntaGrupo.VISAO_GERAL_ITENS_MOTO_COMPLETO}`}>
          {renderTable()}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={`${EPerguntaGrupo.EPIS}|${EPerguntaGrupo.VISAO_GERAL_EPIS_COMPLETO}`}>
          {renderTable()}
        </CustomTabPanel>
      </Box>

    </section>
  )
}

export default ConsultarChecklist