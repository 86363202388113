import React, { useState, useContext, ChangeEvent, useMemo } from 'react'
import './EnviarTecnico.scss'
import EnviarTecnicoModal from './EnviarTecnicoModal'
import Input from '../../../components/Input/Input';
import Header from '../../../components/header/header'
import { useEffect } from 'react';
import { corrigirEnvio, getPrestadores, sendToTechnician } from '../services/services';
import { HomeContext } from '../../../contexts/homeContext';
import { AxiosResponse } from 'axios';
import { AuthContext } from '../../../contexts/auth';
import { getListaTecnicosByLotePrestador } from '../../encaminharOS/services/services';
import { BreadcrumbItem, Breadcrumb } from '../../../components/breadcrumb/breadcrumb';
import { useNavigate } from 'react-router-dom';
import _ from "lodash";
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';
import { ICustodia, IGETStatusCustodiaResponse, IPostCustodia, IPostCustodiaResponse, IStatusCustodia } from '../../estoqueTecnico/types';
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { ITecnicos } from '../../../contexts/types/encaminharTypes';
import Button from '../../../components/Button/styles';
import { ReactComponent as Voltar } from "../../../assets/icons/Voltar.svg";
import AlertMessage from '../../../components/AlertMessage/alertMessage';
import { createErrorMsg, createSuccessMsg } from '../../../services/api';
import { getCustodias, getStatusCustodias } from '../../estoqueTecnico/services/services';
import CustodiaTableTec from './custodiasTableTec';
import { IAutocompleteValue } from '../../../components/autocompleteMultiple/types';
import YesOrNoModal from '../../../components/yesOrNoModal/yesOrNoModal';

const EnviarTecnico = () => {
  const navigate = useNavigate();

  const { setMenuLateral } = useContext(HomeContext);
  const { user, funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Enviar Equipamento", funcionalidade);
  const { idUsuario } = user!

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const [yesOrNoModalOpen, setYesOrNoModalOpen] = useState(false);
  const [custodiaToFix, setCustodiaToFix] = useState<any>(null);

  const [listaCustodia, setListaCustodia] = useState<ICustodia[]>([]);
  const [listaOrdenarValor] = useState<any>({
    "Contratante": "contratante",
    "StatusDeCustodia": "statusCustodia",
    "Prestador": "prestador",
    "NomeDoTecnico": "tecnico",
    "NumeroDeSerie": "numeroSerie",
    "ModeloDoEquipamento": "modelo",
    "ResponsavelPeloEnvioDoEquipamento": "responsavelEnvio",
    "DataEHoraDeEnvioParaOTecnico": "dataEnvio",
    "DataEHoraDeRecebimentoPeloTecnico": "dataRecebimento",
    "DataDeBaixaDaOSDoEquipamento": "dataBaixaOS",
    "DataDeEntregaAoPA": "dataEntregaPA",
    "NomeDoResponsavelPelaConfirmacaoDeRecebimentoNoPA": "nomeResponsavelRecebimentoPA",
  });
  const [ordenacao, setOrdenacao] = useState<string>("DataEHoraDeEnvioParaOTecnico");
  const [ordenacaoReverse, setOrdenacaoReverse] = useState(true);

  const [listaPrestadores, setListaPrestadores] = useState<any[]>([]);
  const [listaTecnicos, setListaTecnicos] = useState<any[]>([]);
  const [statusCustodia, setStatusCustodia] = useState<IStatusCustodia>({} as IStatusCustodia);

  const [selectedPrestadores, setSelectedPrestadores] = useState<IAutocompleteValue>([]);
  const [tecnicoSelecionado, setTecnicoSelecionado] = useState<IAutocompleteValue>([]);
  const [serial, setSerial] = useState<string>('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);


  const fetchCustodias = async () => {
    if (_.isEmpty(tecnicoSelecionado)) return;

    setLoading(true);

    try {
      const params: IPostCustodia = {
        prestadores: selectedPrestadores.map(p => Number(p.id)),
        tecnicos: tecnicoSelecionado.map(p => Number(p.id)),
        custodiaStatus: [statusCustodia.id],
      };

      const { data }: { data: IPostCustodiaResponse } = await getCustodias(params) as AxiosResponse;
      setLoading(false);
      setListaCustodia(data?.items ?? []);
      setOrdenacao("DataEHoraDeEnvioParaOTecnico");
      setOrdenacaoReverse(true);

    } catch (error: any) {
      setListaCustodia([]);
      setLoading(false);
      let msg = createErrorMsg(error);
      if (msg = "Não foi possível encontrar custódia.") msg = 'Nenhuma custódia encontrada para este técnico';
      setMsgAlert(msg ? msg : 'Nenhuma custódia encontrada para este técnico');
      setOpenAlert(true);
      setTipoMsgAlert('error');
    }
  };

  const dadosFormatados = useMemo(() => {
    let dados = [...listaCustodia];

    /** Formata campos para tabela */
    //@ts-ignore
    dados = dados.map((item) => {
      return {
        id: item.id,
        contratante: item.contratante ? item.contratante : 'N/A',
        statusCustodia: item.custodiaStatus ? item.custodiaStatus : 'N/A',
        prestador: item.prestador ? item.prestador : 'N/A',
        tecnico: item.tecnico ? item.tecnico : 'N/A',
        numeroSerie: item.numeroSerie ? item.numeroSerie : 'N/A',
        modelo: item.modelo ? item.modelo : 'N/A',
        responsavelEnvio: item.responsavelEntregaEquipamentoAoTecnico ? item.responsavelEntregaEquipamentoAoTecnico : 'N/A',
        dataEnvio: (!item.dataEnvioTecnico || item.dataEnvioTecnico === "0001-01-01T00:00:00") ? 'N/A' : item.dataEnvioTecnico,
        dataRecebimento: (!item.dataRecebimentoTecnico || item.dataRecebimentoTecnico === "0001-01-01T00:00:00") ? 'N/A' : item.dataRecebimentoTecnico,
        dataBaixaOS: (!item.dataBaixaOSEquipamento || item.dataBaixaOSEquipamento === "0001-01-01T00:00:00") ? 'N/A' : item.dataBaixaOSEquipamento,
        dataEntregaPA: (!item.dataEntregaPA || item.dataEntregaPA === "0001-01-01T00:00:00") ? 'N/A' : item.dataEntregaPA,
        nomeResponsavelRecebimentoPA: item.responsavelConfirmacaoRecebimentoPA ? item.responsavelConfirmacaoRecebimentoPA : 'N/A',
      };
    });

    if (ordenacao !== "") {
      //@ts-ignore
      dados = _.sortBy(dados, [
        listaOrdenarValor[ordenacao],
      ]);
    }

    if (!!ordenacaoReverse) dados = dados.reverse();

    return dados;
  }, [listaCustodia, ordenacao, ordenacaoReverse, listaOrdenarValor]);


  const handleClickOrdenar = (sort: string) => {
    if (sort === ordenacao) {

      if (!ordenacaoReverse) {
        setOrdenacaoReverse(true);
      } else {
        setOrdenacao("");
        setOrdenacaoReverse(false);
      }
    } else {
      setOrdenacao(sort);
      setOrdenacaoReverse(false);
    }
  };

  const activeArrowUP = (sort: string) => {
    if (sort === ordenacao) {
      return ordenacaoReverse;
    } else {
      return false;
    }
  };

  const activeArrowDown = (sort: string) => {
    if (sort === ordenacao) {
      return !ordenacaoReverse;
    } else {
      return false;
    }
  };

  const handleChangeTechnician = (technician: any[]) => {
    setTecnicoSelecionado(technician)
  };

  const getProvider = async () => {
    try {
      const { data } = await getPrestadores() as AxiosResponse;

      let prestadores = [];

      if (!!data && !!data.length) {
        prestadores = data.filter((p: any) => !!p.ativo).map((m: any) => ({ ...m, name: m.nomePrestador, id: m.id?.toString() }))
          .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      };

      setListaPrestadores(filtraPrestadoresDoUsuario(prestadores));

    } catch (e) {
      setListaPrestadores([]);
    }
  };

  const filtraPrestadoresDoUsuario = (prestadores: any) => {
    let options: any[] = [...prestadores];

    if (!!user?.prestadores && !!user.prestadores.length) {
      options = options.filter((o: any) => user.prestadores.includes(Number(o.id)));

      /**Auto preenche o filtro prestadores */
      if (user.prestadores.length === 1) {
        setSelectedPrestadores(options);
      }
    }

    return options;
  };

  const requestListaTecnicosByLotePrestador = async () => {
    let listAllPrestadores: number[] = [];

    if (!!selectedPrestadores.length) {
      listAllPrestadores = selectedPrestadores.map(p => parseInt(p.id));
    }

    const tecnicos = await requestTecnicosByLotePrestador(listAllPrestadores) as ITecnicos[];
    setListaTecnicos(tecnicos.map(p => ({ name: p?.nome ?? "", id: p?.idTecnico?.toString() ?? "" })));
  };

  const requestTecnicosByLotePrestador = async (ids: number[]) => {
    try {
      if (!!ids.length) {
        let retorno = await getListaTecnicosByLotePrestador(ids) as AxiosResponse;
        return retorno?.data ?? [];
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };

  const handleSubmit = async (e: React.KeyboardEvent<HTMLDivElement>, submit?: boolean) => {
    if (e.key === 'Enter' || submit) {
      await enviarParaTecnico()
    }
  };

  const enviarParaTecnico = async () => {
    if (!profileHasPermission("Inserir registro")) {
      setTipoMsgAlert('error');
      setMsgAlert("Usuário sem permissão para enviar equipamento!");
      setOpenAlert(true);
      return;
    }

    if (_.isEmpty(selectedPrestadores)) {
      setTipoMsgAlert('error');
      setMsgAlert("Selecione um prestador");
      setOpenAlert(true);
      return;
    }

    if (_.isEmpty(tecnicoSelecionado)) {
      setTipoMsgAlert('error');
      setMsgAlert("Selecione um técnico");
      setOpenAlert(true);
      return;
    }

    if (!serial) {
      setTipoMsgAlert('error');
      setMsgAlert("Digite um número de série válido");
      setOpenAlert(true);
      return;
    }

    setLoading(true);

    try {
      const { data } = await sendToTechnician({
        idPrestador: Number(selectedPrestadores[0]?.id),
        idTecnico: Number(tecnicoSelecionado[0]?.id),
        serialNumber: serial
      }) as AxiosResponse;

      setMsgAlert(createSuccessMsg(data, 'Equipamento alocado com sucesso!'));
      setTipoMsgAlert('success');
      setOpenAlert(true);

      focusSerieInput();
      setLoading(false);
      fetchCustodias();
      return data
    } catch (error: any) {
      setMsgAlert(createErrorMsg(error, 'Erro de integração, tente novamente mais tarde.'));
      setTipoMsgAlert('error');
      setOpenAlert(true);
      setLoading(false);
    }
  };

  const handleCorrigirEnvio = async () => {

    if (!custodiaToFix || !custodiaToFix?.id) {
      setTipoMsgAlert('error');
      setMsgAlert("Custódia invalida!");
      setOpenAlert(true);
      return;
    }

    setLoading(true);

    try {
      const { data } = await corrigirEnvio({
        idCustodia: Number(custodiaToFix.id),
      }) as AxiosResponse;

      setMsgAlert(createSuccessMsg(data, 'Correção realizada com sucesso!'));
      setTipoMsgAlert('success');
      setOpenAlert(true);
      setLoading(false);
      fetchCustodias();
      return data
    } catch (error: any) {
      setMsgAlert(createErrorMsg(error, 'Erro de integração, tente novamente mais tarde.'));
      setTipoMsgAlert('error');
      setOpenAlert(true);
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e: ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value;
    setSerial(value);
  };

  const handleChangeProvider = (prestadores: any[]) => {
    setSelectedPrestadores(prestadores);
  }

  const focusSerieInput = () => {
    const input = document.getElementById("inputSerie") as HTMLInputElement;

    if (input) {
      setSerial("");
      input.focus();
    }
  };

  const handleGetStatusCustodias = async () => {
    try {
      const { data: { custodiaStatus } }: { data: { custodiaStatus: IGETStatusCustodiaResponse } } = await getStatusCustodias(
        {
          Ativo: true,
          Nome: 'Aguardando Recebimento Tec'
        }
      ) as AxiosResponse;

      let listStatusCustodias: IStatusCustodia = {} as IStatusCustodia;

      if (!!custodiaStatus && !!custodiaStatus.length) {
        listStatusCustodias = custodiaStatus[0];
      };

      setStatusCustodia(listStatusCustodias);

    } catch (e) {
      setStatusCustodia({} as IStatusCustodia);
    }
  };

  useEffect(() => {
    getProvider();
    handleGetStatusCustodias();
    setIsModalOpen(false);
  }, []);

  useEffect(() => {
    requestListaTecnicosByLotePrestador();
    setTecnicoSelecionado([]);
  }, [selectedPrestadores]);

  useEffect(() => {
    fetchCustodias();
  }, [tecnicoSelecionado]);

  return (
    <section className="SendToTechnician">

      {loading && <FullscreenLoading />}

      <Header title='Enviar equipamento ao técnico' setMenuLateral={() => setMenuLateral(true)} />

      <div className="flexTopoAcoes">
        <Breadcrumb>
          <BreadcrumbItem onClick={() => navigate('/EstoqueTecnico')}>Consulta de custódias</BreadcrumbItem>
          <BreadcrumbItem>Enviar equipamento</BreadcrumbItem>
        </Breadcrumb>

        <Voltar onClick={() => navigate(-1)} className='botaoVoltar' />
      </div>

      {!!yesOrNoModalOpen && (
        <YesOrNoModal
          title={
            <div style={{ fontWeight: 400 }}>
              Deseja confirmar a correção do equipamento:<br />
              <br />
              {custodiaToFix?.modelo ?? ""}<br />
              <strong>{custodiaToFix?.numeroSerie ?? ""}</strong>
            </div>
          }
          isModalOpen={yesOrNoModalOpen}
          handleClose={() => {
            setCustodiaToFix(null);
            setYesOrNoModalOpen(false);
          }}
          handleClickYes={() => {
            setYesOrNoModalOpen(false);
            handleCorrigirEnvio();
          }}
        />
      )}

      <EnviarTecnicoModal
        serial={serial}
        handleCloseModal={handleCloseModal}
        isModalOpen={isModalOpen}
        idUsuario={idUsuario}
        idTecnico={tecnicoSelecionado[0]?.id || ""}
        serialNumber={serial}
        setLoading={setLoading}
        idPrestador={Number(selectedPrestadores[0]?.id)}
        handleSubmit={enviarParaTecnico}
      />

      <div className='grid1'>
        <AutocompleteMultiple
          label={"Prestador"}
          placeholder={""}
          noOptionsText={"Nenhum prestador encontrado"}
          id="multiple-checkbox-Prestadores-EntregaEquipamento"
          options={listaPrestadores}
          value={selectedPrestadores}
          onChange={handleChangeProvider}
          fontSize={12}
          disabled={(!!user?.prestadores && user.prestadores.length === 1)}
          multiple={false}
          dimension='sm'
          showCheckbox
        />
        <AutocompleteMultiple
          label={"Técnico"}
          placeholder={""}
          noOptionsText={"Nenhum técnico encontrado"}
          id="multiple-checkbox-tecnico-Estoque"
          options={listaTecnicos}
          value={tecnicoSelecionado}
          onChange={handleChangeTechnician}
          disabled={false}
          multiple={false}
          dimension='sm'
          limitTags={2}
          showCheckbox
        />
        <Input
          maxLength={100}
          id={"inputSerie"}
          label='Número de série'
          onKeyDown={handleSubmit}
          value={serial}
          onChange={handleInputChange}
          dimension='sm'
        />
        {!!profileHasPermission("Inserir registro") ?
          <Button
            id={"sendToTechnicianSendButton"}
            dimension='sm'
            variant="primary"
            className='sendButton'
            onClick={enviarParaTecnico}
            margin='0'
          >
            Enviar
          </Button>
          :
          <Button
            type="button"
            variant='primary'
            dimension='sm'
            style={{ cursor: 'not-allowed', opacity: 0.5 }}
            margin='0'
            disabled={true}>
            Enviar
          </Button>
        }
      </div>

      <AlertMessage
        isOpenAlert={openAlert}
        setOpenAlert={setOpenAlert}
        alertType={tipomsgAlert}
        msgAlert={msgAlert}
      />

      {!_.isEmpty(listaCustodia) && <>
        <CustodiaTableTec
          handleClickOrdenar={handleClickOrdenar}
          activeArrowUP={activeArrowUP}
          activeArrowDown={activeArrowDown}
          ordenacao={ordenacao ?? ''}
          dataList={dadosFormatados}
          handleCorrigirEnvio={(custodia: any) => {
            setCustodiaToFix(custodia);
            setYesOrNoModalOpen(true);
          }}
        />

        <div className="flexBottomAcoes">
          <div />

          <Button
            id={"sendToTechnicianBackButton"}
            dimension='sm'
            variant="grey"
            className='backButton'
            onClick={() => navigate(-1)}
            margin='0'
          >
            Voltar
          </Button>
        </div>
      </>
      }
    </section>
  );
};

export default EnviarTecnico;
