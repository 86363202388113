import React, { useState, useContext, ChangeEvent, useMemo } from 'react';
import './confirmarDevolucao.scss';
import Input from '../../../components/Input/Input';
import Header from '../../../components/header/header';
import { useEffect } from 'react';
import { getPrestadores, confirmarDevolucao } from '../services/services';
import { getListaTecnicosByLotePrestador } from '../../encaminharOS/services/services';
import { getCustodias, getDefeitos, getStatusCustodias } from '../../estoqueTecnico/services/services';
import { HomeContext } from '../../../contexts/homeContext';
import { AxiosResponse } from 'axios';
import { AuthContext } from '../../../contexts/auth';
import { BreadcrumbItem, Breadcrumb } from '../../../components/breadcrumb/breadcrumb';
import { useNavigate } from 'react-router-dom';
import _ from "lodash";
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';
import { IDefeitos, IGETStatusCustodiaResponse, IPostCustodia, IPostCustodiaResponse, IStatusCustodia, ICustodia } from '../../estoqueTecnico/types';
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { ITecnicos } from '../../../contexts/types/encaminharTypes';
import Button from '../../../components/Button/styles';
import { ReactComponent as Voltar } from "../../../assets/icons/Voltar.svg";
import AlertMessage from '../../../components/AlertMessage/alertMessage';
import { createErrorMsg, createSuccessMsg } from '../../../services/api';
import { IAutocompleteValue, IAutocompleteValueItem } from '../../../components/autocompleteMultiple/types';
import CheckboxMUI from '../../../components/checkboxMUI/checkboxMUI';
import DevolverEquipTable from './devolverEquipTable';

const ConfirmarDevolucao = () => {
    const navigate = useNavigate();

    const { setMenuLateral } = useContext(HomeContext);
    const { user, funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
    const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Confirmar Devolução", funcionalidade);

    const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
    const [msgAlert, setMsgAlert] = useState("");
    const [openAlert, setOpenAlert] = useState(false);

    const [listaCustodia, setListaCustodia] = useState<ICustodia[]>([]);
    const [listaOrdenarValor] = useState<any>({
        "ID": "id",
        "Contratante": "contratante",
        "ModeloDoEquipamento": "modelo",
        "StatusDeCustodia": "statusCustodia",
        "DataDeAbertura": "dataAbertura",
    });
    const [ordenacao, setOrdenacao] = useState<string>("DataDeAbertura");
    const [ordenacaoReverse, setOrdenacaoReverse] = useState(true);

    const [defeitoList, setDefeitoList] = useState<any[]>([]);
    const [listaPrestadores, setListaPrestadores] = useState<any[]>([]);
    const [listaTecnicos, setListaTecnicos] = useState<any[]>([]);
    const [listaStatusCustodia, setListaStatusCustodia] = useState<number[]>([]);

    const [selectedPrestadores, setSelectedPrestadores] = useState<IAutocompleteValue>([]);
    const [tecnicoSelecionado, setTecnicoSelecionado] = useState<IAutocompleteValue>([]);
    const [serial, setSerial] = useState<string>('');
    const [doa, setDoa] = useState<boolean>(false);
    const [defeito, setDefeito] = useState<any[]>([]);

    const [loading, setLoading] = useState(false);

    const fetchCustodias = async () => {
        if (_.isEmpty(tecnicoSelecionado)) return setListaCustodia([]);

        setLoading(true);

        try {
            const params: IPostCustodia = {
                prestadores: selectedPrestadores.map(p => Number(p.id)),
                tecnicos: tecnicoSelecionado.map(p => Number(p.id)),
                custodiaStatus: listaStatusCustodia,
            };

            const { data }: { data: IPostCustodiaResponse } = await getCustodias(params) as AxiosResponse;
            setLoading(false);
            setListaCustodia(data?.items ?? []);
            setOrdenacao("DataDeAbertura");
            setOrdenacaoReverse(true);

        } catch (error: any) {
            setListaCustodia([]);
            setLoading(false);
            const optionalMsg = 'Nenhuma devolução de custódia encontrada para este técnico';
            let msg = createErrorMsg(error, optionalMsg);
            if (msg === "Não foi possível encontrar custódia.") msg = optionalMsg;
            setMsgAlert(msg);
            setOpenAlert(true);
            setTipoMsgAlert('error');
        }
    };

    const dadosFormatados = useMemo(() => {
        let dados: any[] = [...listaCustodia];

        dados = dados.map((item) => {
            return {
                id: item.id,
                contratante: item.contratante ?? '',
                modelo: item.modelo ?? '',
                statusCustodia: item.custodiaStatus ?? '',
                dataAbertura: (!item.dataEntregaPA || item.dataEntregaPA === "0001-01-01T00:00:00") ? 'N/A' : item.dataEntregaPA,
            };
        });

        if (ordenacao !== "") {
            dados = _.sortBy(dados, [
                listaOrdenarValor[ordenacao],
            ]);
        }

        if (!!ordenacaoReverse) dados = dados.reverse();

        return dados;
    }, [listaCustodia, ordenacao, ordenacaoReverse, listaOrdenarValor]);

    const equipamentoBipado: ICustodia | undefined = useMemo(() => {
        if (_.isEmpty(listaCustodia) || !serial) return undefined;
        return listaCustodia.find(e => e.numeroSerie === serial);
    }, [listaCustodia, serial]);
    
    const disableDOA: boolean = useMemo(() => {
        if (!equipamentoBipado) return true;
        if (equipamentoBipado.custodiaStatus !== "Devolução em Progresso - Origem Avanço") return true;
        return false;
    }, [equipamentoBipado]);

    const handleClickOrdenar = (sort: string) => {
        if (sort === ordenacao) {
            if (!ordenacaoReverse) {
                setOrdenacaoReverse(true);
            } else {
                setOrdenacao("");
                setOrdenacaoReverse(false);
            }
        } else {
            setOrdenacao(sort);
            setOrdenacaoReverse(false);
        }
    };

    const activeArrowUP = (sort: string) => {
        if (sort === ordenacao) {
            return ordenacaoReverse;
        } else {
            return false;
        }
    };

    const activeArrowDown = (sort: string) => {
        if (sort === ordenacao) {
            return !ordenacaoReverse;
        } else {
            return false;
        }
    };

    const handleChangeTechnician = (technician: any[]) => {
        setTecnicoSelecionado(technician);
    };

    const getProvider = async () => {
        try {
            const { data } = await getPrestadores() as AxiosResponse;

            let prestadores = [];

            if (!!data && !!data.length) {
                prestadores = data.filter((p: any) => !!p.ativo).map((m: any) => ({ ...m, name: m.nomePrestador, id: m.id?.toString() }))
                    .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            };

            setListaPrestadores(filtraPrestadoresDoUsuario(prestadores));

        } catch (e) {
            setListaPrestadores([]);
        }
    };

    const filtraPrestadoresDoUsuario = (prestadores: any) => {
        let options: any[] = [...prestadores];

        if (!!user?.prestadores && !!user.prestadores.length) {
            options = options.filter((o: any) => user.prestadores.includes(Number(o.id)));

            if (user.prestadores.length === 1) {
                setSelectedPrestadores(options);
            }
        }

        return options;
    };

    const requestListaTecnicosByLotePrestador = async () => {
        let listAllPrestadores: number[] = [];

        if (!!selectedPrestadores.length) {
            listAllPrestadores = selectedPrestadores.map(p => parseInt(p.id));
        }

        const tecnicos = await requestTecnicosByLotePrestador(listAllPrestadores) as ITecnicos[];
        setListaTecnicos(tecnicos.map(p => ({ name: p?.nome ?? "", id: p?.idTecnico?.toString() ?? "" })));
    };

    const requestTecnicosByLotePrestador = async (ids: number[]) => {
        try {
            if (!!ids.length) {
                let retorno = await getListaTecnicosByLotePrestador(ids) as AxiosResponse;
                return retorno?.data ?? [];
            } else {
                return [];
            }
        } catch (e) {
            return [];
        }
    };

    const handleSubmit = async (e: React.KeyboardEvent<HTMLDivElement>, submit?: boolean) => {
        if (e.key === 'Enter' || submit) {
            await confirmarDevolucaoEquipamento();
        }
    };

    const confirmarDevolucaoEquipamento = async () => {
        if (!profileHasPermission("Devolver Equipamento")) {
            setTipoMsgAlert('error');
            setMsgAlert("Usuário sem permissão!");
            setOpenAlert(true);
            return;
        }

        if (_.isEmpty(selectedPrestadores)) {
            setTipoMsgAlert('error');
            setMsgAlert("Selecione um prestador!");
            setOpenAlert(true);
            return;
        }

        if (_.isEmpty(tecnicoSelecionado)) {
            setTipoMsgAlert('error');
            setMsgAlert("Selecione um técnico!");
            setOpenAlert(true);
            return;
        }

        if (!serial) {
            setTipoMsgAlert('error');
            setMsgAlert("Digite um número de série válido!");
            setOpenAlert(true);
            return;
        }
        
        if (!equipamentoBipado) {
            setTipoMsgAlert('error');
            setMsgAlert("Não existe solicitação de devolução para este equipamento!");
            setOpenAlert(true);
            return;
        }

        setLoading(true);

        try {
            const { data } = await confirmarDevolucao({
                idPrestador: Number(selectedPrestadores[0]?.id),
                idTecnico: Number(tecnicoSelecionado[0]?.id),
                numeroSerie: serial,
                doa,
                idDefeito: (!!defeito.length && !!defeito[0]?.id) ? Number(defeito[0]?.id) : null,
            }) as AxiosResponse;

            setMsgAlert(createSuccessMsg(data, 'Devolução confirmada com sucesso!'));
            setTipoMsgAlert('success');
            setOpenAlert(true);

            focusSerieInput();
            setLoading(false);
            fetchCustodias();
        } catch (error: any) {
            setMsgAlert(createErrorMsg(error, 'Erro ao confirmar devolução, tente novamente mais tarde.'));
            setTipoMsgAlert('error');
            setOpenAlert(true);
            setLoading(false);
        }
    };

    const handleInputChange = (e: ChangeEvent) => {
        const value = (e.target as HTMLInputElement).value;
        setSerial(value);
    };

    const handleChangeProvider = (prestadores: any[]) => {
        setSelectedPrestadores(prestadores);
    };

    const focusSerieInput = () => {
        const input = document.getElementById("inputSerie") as HTMLInputElement;

        if (input) {
            setSerial("");
            input.focus();
        }
    };

    const handleGetStatusCustodias = async () => {
        try {
            const { data: { custodiaStatus } }: { data: { custodiaStatus: IGETStatusCustodiaResponse } } = await getStatusCustodias(
                { Ativo: true }
            ) as AxiosResponse;

            let listStatusCustodias: number[] = [];

            if (!!custodiaStatus && !!custodiaStatus.length) {
                const statusExibidosNaTabela: string[] = [
                    "Devolução em Progresso - Origem Avanço",
                    "Devolução em Progresso - Origem Reversa"
                ];

                listStatusCustodias = custodiaStatus.filter((c) => statusExibidosNaTabela.includes(c.nome)).map((m) => (m.id));
            };

            setListaStatusCustodia(listStatusCustodias);

        } catch (e) {
            setListaStatusCustodia([]);
        }
    };

    const handleGetDefeitos = async () => {
        try {
            const { data } = await getDefeitos() as AxiosResponse;

            setDefeitoList(data.defeitos.map((item: IDefeitos) =>
                ({ name: item.codigoDefeito, id: item.id.toString() }))
                .sort((a: IAutocompleteValueItem, b: IAutocompleteValueItem) =>
                    (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
        } catch (e) {
            setDefeitoList([]);
        };
    };

    const handleSetDoa = () => {
        setDefeito([]);
        setDoa(!doa);
    };

    useEffect(() => {
        getProvider();
        handleGetDefeitos();
        handleGetStatusCustodias();
    }, []);

    useEffect(() => {
        requestListaTecnicosByLotePrestador();
        setTecnicoSelecionado([]);
    }, [selectedPrestadores]);

    useEffect(() => {
        fetchCustodias();
    }, [tecnicoSelecionado]);
    
    useEffect(() => {
        if (!!doa && !!disableDOA) {
            setDefeito([]);
            setDoa(false);
        }
    }, [disableDOA]);

    return (
        <section className="ConfirmarDevolucao">

            {loading && <FullscreenLoading />}

            <Header title='Confirmação de devolução' setMenuLateral={() => setMenuLateral(true)} />

            <div className="flexTopoAcoes">
                <Breadcrumb>
                    <BreadcrumbItem>Confirmação de devolução</BreadcrumbItem>
                </Breadcrumb>

                <Voltar onClick={() => navigate(-1)} className='botaoVoltar' />
            </div>

            <div className="greyBox">
                <div className='grid1'>
                    <AutocompleteMultiple
                        label={"Prestador"}
                        placeholder={""}
                        noOptionsText={"Nenhum prestador encontrado"}
                        id="multiple-checkbox-Prestadores-DevolucaoEquipamento"
                        options={listaPrestadores}
                        value={selectedPrestadores}
                        onChange={handleChangeProvider}
                        fontSize={12}
                        disabled={(!!user?.prestadores && user.prestadores.length === 1)}
                        multiple={false}
                        dimension='sm'
                        showCheckbox
                    />

                    <AutocompleteMultiple
                        label={"Técnico"}
                        placeholder={""}
                        noOptionsText={"Nenhum técnico encontrado"}
                        id="multiple-checkbox-tecnico-Estoque"
                        options={listaTecnicos}
                        value={tecnicoSelecionado}
                        onChange={handleChangeTechnician}
                        disabled={false}
                        multiple={false}
                        dimension='sm'
                        limitTags={2}
                        showCheckbox
                    />
                </div>

                <div className='grid2'>
                    <Input
                        maxLength={100}
                        id={"inputSerie"}
                        label='Número de série'
                        value={serial}
                        onChange={handleInputChange}
                        dimension='sm'
                    />

                    <div className="checkbox-container">
                        <CheckboxMUI
                            disabled={disableDOA}
                            label='DOA'
                            value={doa}
                            onChange={handleSetDoa}
                            checkboxSX={{ '& .MuiSvgIcon-root': { fontSize: 26 } }}
                            formControlLabelSX={{
                                marginLeft: '0',
                                marginRight: '0',
                                '& .MuiCheckbox-root': { padding: '0' },
                                '& .MuiTypography-root': { color: '#797d8c', fontSize: '12px', marginLeft: '5px' }
                            }} />
                    </div>

                    <AutocompleteMultiple
                        disabled={disableDOA}
                        label={"Defeito"}
                        placeholder={""}
                        noOptionsText={"Nenhum defeito encontrado"}
                        id="multiple-checkbox-defeito-Estoque-modalDevolucao"
                        options={defeitoList}
                        value={defeito}
                        onChange={(defeito: any) => setDefeito(defeito)}
                        readOnly={!doa}
                        multiple={false}
                        dimension='sm'
                        limitTags={2}
                        showCheckbox
                    />
                </div>

                <div className='grid3'>
                    <div />

                    {!!profileHasPermission("Devolver Equipamento") ?
                        <Button
                            id={"confirmarDevolucaoButton"}
                            dimension='sm'
                            variant="primary"
                            className='confirmButton'
                            onClick={confirmarDevolucaoEquipamento}
                            margin='0'
                        >
                            Confirmar
                        </Button>
                        :
                        <Button
                            type="button"
                            variant='primary'
                            dimension='sm'
                            style={{ cursor: 'not-allowed', opacity: 0.5 }}
                            disabled={true}>
                            Confirmar
                        </Button>
                    }
                </div>
            </div>

            <AlertMessage
                isOpenAlert={openAlert}
                setOpenAlert={setOpenAlert}
                alertType={tipomsgAlert}
                msgAlert={msgAlert}
            />

            <div className="listaTableTitle">
                Solicitações pendente de devolução
            </div>

            {!_.isEmpty(listaCustodia) ?
                <DevolverEquipTable
                    handleClickOrdenar={handleClickOrdenar}
                    activeArrowUP={activeArrowUP}
                    activeArrowDown={activeArrowDown}
                    ordenacao={ordenacao ?? ''}
                    dataList={dadosFormatados}
                />
                :
                <div className="nenhumaSolicitacao">
                    Nenhuma solicitação pendente.
                </div>
            }
        </section>
    );
};

export default ConfirmarDevolucao;
