import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import './consultaAjusteMassivoOS.scss';
import Pagination from "../../../components/pagination/paginacao";
import Button from '../../../components/Button/styles';
import Header from '../../../components/header/header';
import { Breadcrumb, BreadcrumbItem } from '../../../components/breadcrumb/breadcrumb';
import { AuthContext } from '../../../contexts/auth';
import { HomeContext } from '../../../contexts/homeContext';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { IAutocompleteValue } from '../../../components/autocompleteMultiple/types';
import DatePicker from "../../../components/datePicker/datePicker";
import { getContratantesNovo } from '../../farol/services';
import { ResponseApi } from '../../../contexts/types/responsesHttp';
import { IContratantesDTO } from '../../contratante/types';
import AlertMessage from '../../../components/AlertMessage/alertMessage';
import { createErrorMsg } from '../../../services/api';
import CustodiaTable from './consultaAjusteMassivoOSTable';
import Input from '../../../components/Input/Input';
import { consultarOSs } from '../../consultaOS/services';
import { IPostConsultarOSs, IPostConsultarOSsResponse, IPostConsultarOSsResponseOS } from '../../consultaOS/types';
import YesOrNoModal from '../../../components/yesOrNoModal/yesOrNoModal';

const ConsultaAjusteMassivoOS = () => {
  const navigate = useNavigate();
  const { setMenuLateral, listaStatus } = useContext(HomeContext);
  const { user, funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Consulta Ajuste Massivo OS", funcionalidade);

  const [yesOrNoModalOpen, setYesOrNoModalOpen] = useState(false);
  const [listaContratante, setListaContratante] = useState<IAutocompleteValue>([]);
  // const [listaStatus, setListaStatus] = useState<IAutocompleteValue>([]);
  const [listaTipoData] = useState<IAutocompleteValue>([
    { name: 'Data de envio para o técnico', id: 'dataEnvioTecnico' },
    { name: 'Data de correção de envio', id: 'dataCorrecaoEnvio' },
    { name: 'Data de recebimento pelo técnico', id: 'dataRecebimentoTecnico' },
    { name: 'Data de baixa da OS do equipamento', id: 'dataBaixaOSEquipamento' },
    { name: 'Data de entrega ao P.A.', id: 'dataEntregaPA' },
    { name: 'Data de criação da custódia', id: 'dataCriacao' },
    { name: 'Data da última modificação da custódia', id: 'dataModificacao' },
  ]);
  const [selectedPrestadores, setSelectedPrestadores] = useState<any[]>([]);
  const [tecnicoSelecionado, setTecnicoSelecionado] = useState<any[]>([]);
  const [filtroContratante, setFiltroContratante] = useState<any[]>([]);
  const [statusSelecionado, setStatusSelecionado] = useState<IAutocompleteValue>([]);
  const [numeroChamado, setNumeroChamado] = useState<string>("");
  const [numeroReferencia, setNumeroReferencia] = useState<string>("");
  const [tipoDataSelecionado, setTipoDataSelecionado] = useState<IAutocompleteValue>([]);
  const [dataAbertura, setDataAbertura] = useState<any | null>(null);
  const [dataAlteracaoMassivo, setDataAlteracaoMassivo] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [dadosTabela, setDadosTabela] = useState<IPostConsultarOSsResponseOS[]>([]);
  const [osSelected, setOsSelected] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [qtdeRegistros, setQtdeRegistros] = useState<number>(0);
  const [listaOrdenarValor] = useState<any>({
    "Status": "status",
    "Chamado": "chamado",
    "Referencia": "referencia",
    "Contratante": "contratante",
    "DataAbertura": "dataAbertura",
    "DataAlteracaoMassivo": "dataAlteracaoMassivo",
    "Editar": "editar",
  });
  const [ordenacao, setOrdenacao] = useState<string>("");
  const [ordenacaoReverse, setOrdenacaoReverse] = useState(false);

  const osList = useMemo(() => dadosTabela.filter((item) => osSelected.includes(item.chamado)), [dadosTabela, osSelected]);

  const handleClickEditarMassivo = () => {
    const osSemTecnico = osList.find(os => !os.idTecnico);
    if (!!osSemTecnico) {
      setYesOrNoModalOpen(true);
    } else {
      navigate("/AjusteMassivoOS", {
        state: {
          osList: osList,
          rota: {
            url: window.location.pathname,
            name: "Consultar Fluxo de Trabalho",
          },
        }
      });
    }
  };

  const handleClickEConfirmOsSemTecnico= () => {
    const oSsComTecnico = osList.filter(os => !!os.idTecnico);
    if (!!oSsComTecnico.length) {
      navigate("/AjusteMassivoOS", {
        state: {
          osList: oSsComTecnico,
          rota: {
            url: window.location.pathname,
            name: "Consultar Fluxo de Trabalho",
          },
        }
      });
    }
  };
  
  const handleSelectAll = () => {
    if (dadosTabela?.length > osSelected?.length) {
      setOsSelected(dadosTabela.map((item) => item.chamado));
    } else {
      setOsSelected([]);
    };
  };

  const handleSetOsSelected = (chamado: number) => {
    let numberList = [...osSelected];

    if (osSelected.includes(chamado)) numberList = numberList.filter(os => os !== chamado); 
    else numberList.push(chamado);

    setOsSelected(numberList);
  };

  const getItemByID = (ID: number, list: any[]) => {
    return [...list].find((item) => item.id === ID)!;
  };

  const handleChangeTechnician = (technician: any[]) => {
    setTecnicoSelecionado(technician)
  };

  const handleChangeProvider = (prestadores: any[]) => {
    setSelectedPrestadores(prestadores);
  };

  const handleButtonSearch = () => {
    currentPage !== 1 ? setCurrentPage(1) : handleSearch();
  };

  const handleSearch = async () => {
    setLoading(true);

    if (!!osSelected.length) setOsSelected([]);

    const arrayChamados = numeroChamado.split(" ");
    const arrayReferencias = numeroReferencia.split(" ");

    try {
      const params: IPostConsultarOSs = {
        ...(!!statusSelecionado.length ? { status: statusSelecionado.map(p => Number(p.id)) } : {}),
        ...(arrayChamados.length === 1 ? (!!arrayChamados[0].length ? { chamado: arrayChamados[0] } : {}) : { chamados: arrayChamados }),
        ...(arrayReferencias.length === 1 ? (!!arrayReferencias[0].length ? { referencia: arrayReferencias[0] } : {}) : { referencias: arrayReferencias }),
        ...(!!filtroContratante.length ? { contratante: filtroContratante.map(p => Number(p.id)) } : {}),
        ...(!!dataAbertura ? { inicialAbertura: dataAbertura.startOf('day').format("YYYY-MM-DDTHH:mm:ss") } : {}),
        ...(!!dataAbertura ? { finalAbertura: dataAbertura.endOf('day').format("YYYY-MM-DDTHH:mm:ss") } : {}),
        ...(!!dataAlteracaoMassivo ? { dataAlteracaoMassiva: dataAlteracaoMassivo.format("YYYY-MM-DDTHH:mm:ss") } : {}),
        "pageNumber": currentPage,
        "pageSize": pageSize,
      };

      const { data }: { data: IPostConsultarOSsResponse } = await consultarOSs(params) as AxiosResponse;
      setLoading(false);
      setDadosTabela(data?.items ?? []);
      setQtdeRegistros(data?.totalCount ?? 0);

    } catch (error: any) {
      setDadosTabela([]);
      setQtdeRegistros(0);
      setLoading(false);
      setMsgAlert(createErrorMsg(error, 'Nenhuma OS encontrada'));
      setOpenAlert(true);
      setTipoMsgAlert('error');
    }
  };

  const localGetContratantes = async () => {
    return await getContratantesNovo()
      .then((res: any) => {
        const respSuccess = res?.data as ResponseApi<IContratantesDTO[]> | null;

        if (respSuccess) {
          const listaContratante = respSuccess.data?.map((item: any) => { return { name: item.nome, id: item.id?.toString() ?? "" } }) || [];
          setListaContratante(filtraContratantesDoUsuario([...[], ...listaContratante]));
        };

      }, err => {
        const respErr = err?.response?.data as ResponseApi<IContratantesDTO[]>;
        if (respErr) setListaContratante([]);
      });
  };

  const filtraContratantesDoUsuario = (contratantes: IAutocompleteValue) => {
    let options = [...contratantes];

    if (!!user?.contratanteOrdemServico) {
      options = options.filter((o: any) => Number(o.id) === user.contratanteOrdemServico);

      setFiltroContratante(options);
    }

    return options;
  };

  const paginacaoLocal = (dados: any[]) => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return dados.slice(firstPageIndex, lastPageIndex);
  };

  const dadosFormatados = useMemo(() => {
    /** Formata campos para tabela */
    let dados: any[] = dadosTabela.map((item) => {
      return {
        status: item.status ?? '',
        chamado: item.chamado,
        referencia: item.referencia ?? '',
        contratante: item.contratante ?? '',
        dataAbertura: (!item.dataAbertura || item.dataAbertura === "0001-01-01T00:00:00") ? 'N/A' : item.dataAbertura,
        dataAlteracaoMassivo: (!item.dataAlteracaoMassiva || item.dataAlteracaoMassiva === "0001-01-01T00:00:00") ? 'N/A' : item.dataAlteracaoMassiva,
      };
    });

    if (ordenacao !== "") {
      dados = _.sortBy(dados, [
        listaOrdenarValor[ordenacao],
      ]);
    }

    if (!!ordenacaoReverse) dados = dados.reverse();

    return dados;
  }, [dadosTabela, ordenacao, ordenacaoReverse, listaOrdenarValor, currentPage, pageSize]);


  const handleClickOrdenar = (sort: string) => {
    if (sort === ordenacao) {

      if (!ordenacaoReverse) {
        setOrdenacaoReverse(true);
      } else {
        setOrdenacao("");
        setOrdenacaoReverse(false);
      }
    } else {
      setOrdenacao(sort);
      setOrdenacaoReverse(false);
    }
  };

  const activeArrowUP = (sort: string) => {
    if (sort === ordenacao) {
      return ordenacaoReverse;
    } else {
      return false;
    }
  };

  const activeArrowDown = (sort: string) => {
    if (sort === ordenacao) {
      return !ordenacaoReverse;
    } else {
      return false;
    }
  };

  useEffect(() => {
    localGetContratantes();
  }, []);

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    handleSearch();
  }, [currentPage, pageSize]);

  return (
    <section className="ConsultaAjusteMassivoOS">
      {loading && <FullscreenLoading />}

      <Header setMenuLateral={() => setMenuLateral(true)} title="Ajuste" />

      <Breadcrumb>
        <BreadcrumbItem>Ajuste</BreadcrumbItem>
      </Breadcrumb>

      {!!yesOrNoModalOpen && (
        <YesOrNoModal
          title={
            <div style={{ fontWeight: 400 }}>
              As seguintes OSs selecionadas não possuem um técnico associado e não poderão ser editadas.<br />
              Processeguir com as demais OSs?<br />
              <br />
              OSs sem técnico:<br />
              {osList.map((os, i) => {
                if (!!os.idTecnico) return <React.Fragment key={i} />;

                return (
                  <React.Fragment key={i}>
                    <strong>{os.chamado}</strong><br />
                  </React.Fragment>
                );
              })}
            </div>
          }
          isModalOpen={yesOrNoModalOpen}
          handleClose={() => setYesOrNoModalOpen(false)}
          handleClickYes={() => {
            setYesOrNoModalOpen(false);
            handleClickEConfirmOsSemTecnico();
          }}
        />
      )}

      <div className="boxGridSearchFields">
        <div className='grid1'>
          <AutocompleteMultiple
            label={"Status da OS"}
            placeholder={""}
            noOptionsText={"Nenhum status encontrado"}
            id="multiple-checkbox-Prestadores-Estoque"
            options={listaStatus}
            value={statusSelecionado}
            onChange={setStatusSelecionado}
            multiple={true}
            dimension='sm'
            limitTags={2}
            showCheckbox
            allSelected
          />

          <Input
            label={"Número do Chamado"}
            placeholder={""}
            id="input-numeroChamado"
            value={numeroChamado}
            onChange={(e: any) => setNumeroChamado(e.target.value)}
            dimension='sm'
            maxLength={255}
          />

          <Input
            label={"Número da Referência"}
            placeholder={""}
            id="input-numeroReferencia"
            value={numeroReferencia}
            onChange={(e: any) => setNumeroReferencia(e.target.value)}
            dimension='sm'
            maxLength={255}
          />
        </div>

        <div className='grid2'>
          <AutocompleteMultiple
            label={"Contratante"}
            placeholder={""}
            noOptionsText={"Nenhum contratante encontrado"}
            id="multiple-checkbox-contratante-Estoque"
            options={listaContratante}
            value={filtroContratante}
            onChange={setFiltroContratante}
            disabled={!!user?.contratanteOrdemServico}
            multiple={true}
            dimension='sm'
            limitTags={2}
            showCheckbox
            allSelected
          />

          <DatePicker
            label={"Data de Abertura"}
            id="DatePicker-dataAbertura"
            placeholder="Início"
            value={dataAbertura}
            onChange={setDataAbertura}
            dimension='sm'
          />

          <DatePicker
            label={"Data de Alteração Massiva"}
            id="DatePicker-dataAlteracaoMassivo"
            placeholder="Fim"
            value={dataAlteracaoMassivo}
            onChange={setDataAlteracaoMassivo}
            dimension='sm'
          />
        </div>

        <div className='grid3'>
          <div />

          <Button
            id={"technicianStockSearchButton"}
            dimension='sm'
            variant="primary"
            className='searchButton'
            onClick={handleButtonSearch}
          >
            Buscar
          </Button>
        </div>
      </div>

      <AlertMessage
        isOpenAlert={openAlert}
        setOpenAlert={setOpenAlert}
        alertType={tipomsgAlert}
        msgAlert={msgAlert}
      />

      {!_.isEmpty(dadosFormatados) &&
        <>
          <CustodiaTable
            handleClickOrdenar={handleClickOrdenar}
            activeArrowUP={activeArrowUP}
            activeArrowDown={activeArrowDown}
            ordenacao={ordenacao ?? ''}
            dataList={dadosFormatados}
            osSelected={osSelected}
            handleSetOsSelected={handleSetOsSelected}
            handleSelectAll={handleSelectAll}
          />

          <Pagination
            arrQtdPageSelector={[20, 50, 100]}
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={qtdeRegistros}
            pageSize={pageSize}
            setPageSize={(value: string) => setPageSize(Number(value))}
            onPageChange={(page: number) => setCurrentPage(Number(page))}
            showLabel={false}
          />

        <div className='grid3' style={{marginTop: '20px'}}>
          <div />

          <Button
            id={"technicianStockSearchButton"}
            dimension='sm'
            variant="primary"
            className='searchButton'
            onClick={handleClickEditarMassivo}
            disabled={!profileHasPermission("Editar registro") || !osSelected.length}
          >
            Editar
          </Button>
        </div>
        </>
      }
    </section>
  );
};

export default ConsultaAjusteMassivoOS;
