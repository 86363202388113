import { useState, useEffect } from "react";
import _ from "lodash";
import Modal from "../../../components/modal";
import {
  titleStyle,
  Container,
  CardOSBody,
  InfoWrapper,
  BlockTitle,
  BodyInfo,
  BodyInfoItem,
  SpanInfo,
  Img,
  ImageGrid,
  ImageContainer,
} from "./checklistModal_style";
import { useNavigate } from "react-router-dom";
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import { IItemTabelaChecklist, IPerguntaRespostaTecnico } from "../types";
import moment from "moment";
import { EPergunta, EPerguntaGrupo, ETipoOpcao, RESPOSTAS_NEGATIVAS } from "../types/constants";

interface IChecklistModal {
  isModalOpen: boolean,
  handleClose: () => void
  item: IItemTabelaChecklist | null
}

const ChecklistModal = ({ isModalOpen, handleClose, item }: IChecklistModal) => {
  const [isOpen, setIsOpen] = useState(isModalOpen);

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("error");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setIsOpen(isModalOpen);
  }, [isModalOpen]);


  //#region Respostas
  const isNegativeResponse = (response: string) => {
    if (!response) return false;
    const upperCaseResponse = response.toUpperCase();
    return RESPOSTAS_NEGATIVAS.some(negative => upperCaseResponse.includes(negative.toUpperCase()));
  };

  const itensMotoNOK = Array.from(new Set(
    item?.respostas
      .filter(f => f.identificadorPerguntaGrupo.toUpperCase() === EPerguntaGrupo.ITENS_MOTO.toUpperCase())
      .filter(f => {
        if (f.tipoOpcaoResposta === ETipoOpcao.BOOLEAN) {
          return isNegativeResponse(f.nomeResposta);
        } else {
          return isNegativeResponse(f.dados);
        }
      })
  ));

  const itensEPISNOK = Array.from(new Set(
    item?.respostas
      .filter(f => f.identificadorPerguntaGrupo.toUpperCase() === EPerguntaGrupo.EPIS.toUpperCase())
      .filter(f => {
        if (f.tipoOpcaoResposta === ETipoOpcao.BOOLEAN) {
          return isNegativeResponse(f.nomeResposta);
        } else {
          return isNegativeResponse(f.dados);
        }
      })
  ));
  //#endregion

  const perguntaObservacoesItemMoto = item?.respostas
    .find(f => f.identificadorPergunta.toUpperCase() == EPergunta.ITENS_MOTO_OBSERVACOES.toUpperCase());

  const perguntaFotosItemMoto = item?.respostas
    .filter(f => f.identificadorPergunta.toUpperCase() == EPergunta.ITENS_MOTO_FOTOS.toUpperCase());

  const perguntaObservacoesEPI = item?.respostas
    .find(f => f.identificadorPergunta.toUpperCase() == EPergunta.EPIS_OBSERVACOES.toUpperCase());

  const perguntaFotosEPI = item?.respostas
    .filter(f => f.identificadorPergunta.toUpperCase() == EPergunta.EPIS_FOTOS.toUpperCase());


  const RenderContainerFotosChecklistCompleto = () => {

    const checklistCompletoItemMoto = item?.respostas
      .find(f => f.identificadorPergunta.toUpperCase() == EPergunta.CHECKLIST_COMPLETO_ITEM_MOTO.toUpperCase());

    const fotoChecklistCompletoItemMoto = item?.respostas
      .filter(f => f.identificadorPergunta.toUpperCase() == EPergunta.FOTO_CHECKLIST_COMPLETO_ITEM_MOTO.toUpperCase());

    const checklistCompletoEpi = item?.respostas
      .find(f => f.identificadorPergunta.toUpperCase() == EPergunta.CHECKLIST_COMPLETO_EPI.toUpperCase());

    const fotoChecklistCompletoEpi = item?.respostas
      .filter(f => f.identificadorPergunta.toUpperCase() == EPergunta.FOTO_CHECKLIST_COMPLETO_EPI.toUpperCase());

    return fotoChecklistCompletoItemMoto && !_.isEmpty(fotoChecklistCompletoItemMoto) && (
      <CardOSBody>
        <InfoWrapper>
          <BlockTitle id={"ChecklistModalBlockTitle:Detalhes"}>Checklist Completo</BlockTitle>
          {checklistCompletoItemMoto && (
            <>
              <BodyInfo id={"ChecklistModalBody:Detalhes"}>
                <BodyInfoItem>
                  <><SpanInfo>Item da moto: </SpanInfo> {checklistCompletoItemMoto?.dados}<br /></>
                </BodyInfoItem>
              </BodyInfo>

              <ImageGrid>
                {fotoChecklistCompletoItemMoto && fotoChecklistCompletoItemMoto.map(m =>
                  <ImageContainer>
                    <Img src={m.dados} />
                  </ImageContainer>
                )}
              </ImageGrid>
            </>
          )}

          {checklistCompletoEpi && (
            <>
              <BodyInfo id={"ChecklistModalBody:Detalhes"}>
                <BodyInfoItem>
                  <><SpanInfo>EPI: </SpanInfo> {checklistCompletoEpi?.dados}<br /></>
                </BodyInfoItem>
              </BodyInfo>

              <ImageGrid>
                {fotoChecklistCompletoEpi && fotoChecklistCompletoEpi.map(m =>
                  <ImageContainer>
                    <Img src={m.dados} />
                  </ImageContainer>
                )}
              </ImageGrid>
            </>
          )}
        </InfoWrapper>

      </CardOSBody>
    );
  }

  return (
    <Modal
      handleCloseModal={handleClose}
      title={" "}
      isOpen={isOpen}
      titleStyle={titleStyle}
      sx={{ "& .MuiPaper-elevation": { maxWidth: "1000px", minWidth: "610px" } }}
    >
      <Container>
        <CardOSBody>
          <InfoWrapper>
            <BlockTitle id={"ChecklistModalBlockTitle:Detalhes"}>Detalhes</BlockTitle>
            <BodyInfo id={"ChecklistModalBody:Detalhes"}>
              <BodyInfoItem>
                <SpanInfo>Data/Horário do envio</SpanInfo> {moment(item?.dataEnvio)?.format('DD/MM/YYYY')}
              </BodyInfoItem>
              <BodyInfoItem>
                <SpanInfo>Técnico</SpanInfo> {item?.nomeTecnico}
              </BodyInfoItem>
              <BodyInfoItem>
                <SpanInfo>Placa da Moto</SpanInfo> {item?.placaMotoTecnico}
              </BodyInfoItem>
            </BodyInfo>
          </InfoWrapper>
          <AlertMessage
            isOpenAlert={openAlert}
            setOpenAlert={setOpenAlert}
            alertType={tipomsgAlert}
            msgAlert={msgAlert}
          />
        </CardOSBody>
        {itensMotoNOK && !_.isEmpty(itensMotoNOK) && (

          <CardOSBody>
            <InfoWrapper>
              <BlockTitle id={"ChecklistModalBlockTitle:Detalhes"}>Itens NOK</BlockTitle>
              <BodyInfo id={"ChecklistModalBody:Detalhes"}>
                {itensMotoNOK.map(m => (
                  <>
                    <BodyInfoItem>{m.nomePergunta}</BodyInfoItem>
                    {/* <BodyInfoItem>
                      {m.dados ? (<><SpanInfo>Resposta: </SpanInfo> {m.dados}</>) : <></>}
                    </BodyInfoItem> */}
                    <BodyInfoItem>
                      {m.observacoes ? (
                        <><SpanInfo>Observações: </SpanInfo> {m.observacoes}</>
                      ) : <></>}
                    </BodyInfoItem>
                    <br />
                  </>
                ))}
              </BodyInfo>

              {perguntaObservacoesItemMoto && !_.isEmpty(perguntaObservacoesItemMoto) && (
                <>
                  <BlockTitle id={"ChecklistModalBlockTitle:Detalhes"}>Descrição do problema</BlockTitle>
                  <BodyInfo id={"ChecklistModalBody:Detalhes"}>
                    <BodyInfoItem>{perguntaObservacoesItemMoto.dados}</BodyInfoItem>
                  </BodyInfo>
                </>
              )}

              {perguntaFotosItemMoto && !_.isEmpty(perguntaFotosItemMoto) && (
                <>
                  <ImageGrid>
                    {perguntaFotosItemMoto.map(m =>
                      <ImageContainer>
                        <Img src={m.dados} />
                      </ImageContainer>
                    )}
                  </ImageGrid>
                </>
              )}
            </InfoWrapper>
          </CardOSBody>
        )}

        {itensEPISNOK && !_.isEmpty(itensEPISNOK) && (
          <CardOSBody>
            <InfoWrapper>
              <BlockTitle id={"ChecklistModalBlockTitle:Detalhes"}>EPIs Não Utilizados</BlockTitle>
              <BodyInfo id={"ChecklistModalBody:Detalhes"}>
                {itensEPISNOK.map(m => (
                  <>
                    <BodyInfoItem>{m.nomePergunta}</BodyInfoItem>
                    <BodyInfoItem>
                      {m.dadosExtra && (
                        <><SpanInfo>Motivo: </SpanInfo> {m.dadosExtra}<br /></>
                      )}
                    </BodyInfoItem>

                    <BodyInfoItem>
                      {m.observacoesExtra ? (
                        <><SpanInfo>Observações: </SpanInfo> {m.observacoesExtra}</>
                      ) : <></>}
                    </BodyInfoItem>
                  </>
                ))}
              </BodyInfo>

              {/* {perguntaObservacoesEPI && !_.isEmpty(perguntaObservacoesEPI) && (
                <>
                  <BlockTitle id={"ChecklistModalBlockTitle:Detalhes"}>Descrição do problema</BlockTitle>
                  <BodyInfo id={"ChecklistModalody:Detalhes"}>
                    <BodyInfoItem>{ChecklistModalervacoesEPI.dados}</BodyInfoItem>
                  </BodyInfo>
                </>
              )} */}

              {perguntaFotosEPI && !_.isEmpty(perguntaFotosEPI) && (
                <>
                  <ImageGrid>
                    {perguntaFotosEPI.map(m =>
                      <ImageContainer>
                        <Img src={m.dados} />
                      </ImageContainer>
                    )}
                  </ImageGrid>
                </>
              )}
            </InfoWrapper>

          </CardOSBody>
        )}

        {RenderContainerFotosChecklistCompleto()}

      </Container>
    </Modal>
  );
};

export default ChecklistModal;
